import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingIndicatorService } from '../loading-indicator/loading-indicator.service';


@Injectable()
export class GlobalLoadingInterceptor implements HttpInterceptor {
    private countReq: number = 0;

    constructor(private loading: LoadingIndicatorService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.increseCountRequest()
        this.loading.start()
        return next.handle(req).pipe(finalize(() => this.decreaseCountRequest()));
    }

    private increseCountRequest() {
        this.countReq = this.countReq + 1;
    }

    private decreaseCountRequest() {
        this.countReq = this.countReq - 1;
        if (this.countReq === 0) {
            this.loading.end();
        }
    }
}
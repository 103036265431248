import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationService } from "dg/app/shared/notification/notification.service";
import { BASE_BACKEND_URL } from "dg/environments/constants";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "../auth.service";

const regex = new RegExp(`^${BASE_BACKEND_URL}`)

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private auth: AuthService, private router: Router) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    let newRequest = req;
    if (token && this.isOwnBackendUrl(req.url) && !req.url.includes('token') && !this.isGetTemasReq(req)) {
      newRequest = req.clone({
        setHeaders: {
          Authorization: `JWT ${token}`,
        },
      });
    }

    return next.handle(newRequest).pipe(
      catchError(this.treatUnauthorized)
    )

  }

  isOwnBackendUrl(url: string) {
    // @ts-ignore
    if (BASE_BACKEND_URL == '/') {
      return url.startsWith('/api');
    }
    return url.includes(BASE_BACKEND_URL)
  }

  treatUnauthorized = (error: HttpErrorResponse) => {
    const isOwnBackendUrl = error.url.includes(BASE_BACKEND_URL);
    if (isOwnBackendUrl && error.status === HttpStatusCode.Unauthorized) {
      if (error.url.includes('/api/temas/')) {
        return throwError(error)
      }
      const notifier = this.injector.get(NotificationService);
      if (error.url.includes('/api/token/')) {
        if (this.router.url === '/registry?tab=accesskeys') {
          const msg = 'Senha inserida está incorreta.';
          this.handleAccessKeys(notifier, msg);
        } else {

          notifier.error({ msg: error.error['detail'] });
        }
        return EMPTY
      }
      notifier.error({ msg: 'Sua sessão expirou, faça login novamente' });
      // auto logout if 401 response returned from api
      this.auth.logout();
      return EMPTY
    }
    else {
      return throwError(error);
    }
  }

  handleAccessKeys(notifier: NotificationService, msg: string) {
    notifier.error({ msg })
  }

  isGetTemasReq(req) {
    return req.method == 'GET' && req.url.includes('temas');
  }

}

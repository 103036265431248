import { Validators } from '@angular/forms';
import { format, parseISO } from 'date-fns';
import { AccessKeysService } from 'dg/app/shared/api/access-keys.service';
import { GaValidators } from 'ui-components/forms/validators';
import { Conta } from 'ui-components/models/conta.model';
import { Formable } from 'ui-components/models/Formable';
import { parseAddress, restructureAddress } from '../shared/address-form/util';
import { distribuitorAcesss } from './distribuitor-acess.model';

export interface ConsumerUnitForm {
    id: number;
    name: string;
    company: number;
    cnpj: string;
    address: any;
    installationCode: string;
    clientCode: string;
    tariffType: number;
    consumptionClass: number;
    distributor: number;
    credential: number;
    dateCollectInit: any;
    hideDownload: boolean;
}

export const consumerUnitFormGroup: (obj: { service: AccessKeysService }) => Formable<ConsumerUnitForm> = ({ service }) => ({
    id: [undefined],
    name: [undefined, [Validators.required]],
    company: [undefined, [Validators.required]],
    cnpj: [undefined, [GaValidators.cnpj]],
    address: [undefined, []],
    installationCode: [undefined, [Validators.required]],
    clientCode: [undefined, []],
    tariffType: [undefined, []],
    consumptionClass: [undefined, []],
    distributor: [undefined, [Validators.required], [distribuitorAcesss(service)]],
    credential: [undefined, []],
    dateCollectInit: [undefined, []],
    hideDownload: [undefined, []]
})

export namespace ConsumerFormAdapter {
    export function beforeAssign(obj: Conta): ConsumerUnitForm {
        const address = parseAddress(obj.endereco)
        const powerplant: ConsumerUnitForm = {
            id: obj.id,
            company: obj.empresa,
            name: obj.nome,
            cnpj: obj.cnpj,
            clientCode: obj.num_cliente,
            installationCode: obj.instalacao,
            distributor: obj.distribuidora,
            credential: obj.acesso,
            address,
            tariffType: obj.modalidade_tarifaria,
            consumptionClass: obj.classe,
            dateCollectInit: obj?.dt_coleta_fatura,
            hideDownload: obj.faturas_desabilitadas,
        }
        return powerplant;
    }

    export function beforeNewConsumer(): ConsumerUnitForm {
        const consumer: ConsumerUnitForm = {
            id: undefined,
            company: undefined,
            name: undefined,
            cnpj: undefined,
            clientCode: undefined,
            installationCode: undefined,
            distributor: undefined,
            credential: undefined,
            address: undefined,
            tariffType: undefined,
            consumptionClass: undefined,
            dateCollectInit: undefined,
            hideDownload: false,
        }
        return consumer;
    }

    export function beforeSubmit(obj: ConsumerUnitForm): Partial<Conta> {
        const address = restructureAddress(obj.address)
        const dateCollectInit = getDataColeta(obj?.dateCollectInit)
        const customer: Partial<Conta> = {
            id: obj.id,
            empresa: obj.company,
            nome: obj.name,
            instalacao: obj.installationCode,
            num_cliente: obj.clientCode,
            modalidade_tarifaria: obj.tariffType,
            cnpj: !!obj.cnpj ? obj.cnpj : null,
            endereco: address,
            distribuidora: obj.distributor,
            acesso: obj.credential,
            classe: obj.consumptionClass,
            //dados obrigatoriospowerplantId
            demanda_hp: null,
            demanda_hfp: null,
            desconto_tarifa: null,
            dt_coleta_fatura: dateCollectInit,
            faturas_desabilitadas: obj.hideDownload,
        }
        return customer;
    }

    export function getDataColeta(dateCollectInit) {
        if (!dateCollectInit) return undefined
        if (dateCollectInit instanceof Date) return format(dateCollectInit, 'yyyy-MM-dd')
        return format(parseISO(dateCollectInit), 'yyyy-MM-dd')
    }
}

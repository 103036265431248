import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { AutoCenterDirective } from './autocenter.directive';
import { MapsComponent } from './map.component';

@NgModule({
  declarations: [MapsComponent, AutoCenterDirective],
  imports: [
    CommonModule,
    GoogleMapsModule,
    HttpClientJsonpModule,
    MatDialogModule,
    MatButtonModule
  ]
})
export class MapsModule { }

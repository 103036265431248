interface ViaCepResponse {
    cep: string;
    logradouro: string;
    complemento: string;
    bairro: string;
    localidade: string;
    uf: string;
    unidade: string;
    ibge: string;
    gia: string;
}
/**
 * Get full address from postal code
 * @param code CEP
 */
export function getPostalCodeAddress(code: string): Promise<ViaCepResponse> {
    return fetch(`https://viacep.com.br/ws/${code}/json/`)
        .then(res => res.json())
        .then(data => {
            if (data.erro) {
                throw 'CEP não existente'
            }
            return data
        })
}
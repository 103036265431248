import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../shared/api/api.service';
import { TENANT_THEME } from './initUtil';
import { TenantTheme } from './models/tenant-theme.model';

@Injectable({ providedIn: 'root' })
export class TenantService {

    constructor(@Inject(WINDOW) private window: Window, private apiService: ApiService, @Inject(TENANT_THEME) private theme: BehaviorSubject<TenantTheme>) { }
    public $previewLogo = new BehaviorSubject<string>(undefined);
    theme$ = this.theme.asObservable();

    getClientLocation(): string {
        const client: string = this.window.location.hostname.split('.').shift();
        return client;
    }

    getCurrentTheme() {
        return this.apiService.getTheme().pipe(
            map(tenants => {
                if (tenants[0] && this.checkSchemaType(tenants[0])) {
                    this.setTheme(tenants[0].esquema);
                    return tenants[0];
                }
                return { ...tenants[0], esquema: null };
            }));
    }

    setTheme(theme: TenantTheme) {
        this.theme.next(theme);
    }

    checkSchemaType(tema) {
        return 'logo' in tema.esquema && 'favicon' in tema.esquema && 'colors' in tema.esquema && 'chart' in tema.esquema;
    }
}
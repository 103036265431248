import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppShellComponent } from './app-shell/app-shell.component';
import { AuthGuard } from './auth/middleware/auth.guard';
import { CanLoadGuard } from './auth/middleware/canload.guard';
import { Group } from './auth/models/groups';


const APP_ROUTES: Routes = [
    {
        path: 'dashboard',
        component: AppShellComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
            groups: []
        }
    },
    {
        path: 'registry',
        component: AppShellComponent,
        canActivate: [AuthGuard],
        canLoad: [CanLoadGuard],
        loadChildren: () => import('./registry/registry.module').then(m => m.RegistryModule),
        data: {
            groups: [Group['admin']]
        }
    },
    {
        path: 'contracts-management',
        component: AppShellComponent,
        canActivate: [AuthGuard],
        canLoad: [CanLoadGuard],
        loadChildren: () => import('./contract-management/contract-management.module').then(m => m.ContractManagementModule),
        data: {
            groups: [Group['admin']]
        }
    },
    {
        path: 'contact',
        component: AppShellComponent,
        canActivate: [AuthGuard],
        canLoad: [CanLoadGuard],
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
        data: {
            groups: []
        }
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/dashboard'
    }
];

const config: ExtraOptions = {
}

@NgModule({
    imports: [RouterModule.forRoot(APP_ROUTES, config)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

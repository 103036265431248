import { Platform } from "@angular/cdk/platform";
import { Inject, Injectable, Optional } from "@angular/core";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { addDays, addMonths, addYears, format, getDate, getDay, getDaysInMonth, getMonth, getYear, Locale, parse, parseISO, toDate } from "date-fns";
import { ptBR } from 'date-fns/locale';

const locales = { ptBR }

function mapDatepickerStyleToDateFns(style: "long" | "short" | "narrow") {
    if (style == 'long') return 'wide';
    if (style == 'short') return 'short';
    if (style == 'narrow') return 'abbreviated';
}

function range(start: number, end: number): number[] {
    const arr: number[] = [];
    for (let i = start; i <= end; i++) {
        arr.push(i);
    }

    return arr;
}

@Injectable()
export class DateFnsAdapter extends DateAdapter<Date>{
    constructor(@Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string, platform: Platform) {
        super();
        super.setLocale(matDateLocale);
    }
    get lc(): Locale {
        const str = String(this.locale).replace(/-/, '')
        return locales[str]
    }
    getYear(date: Date): number {
        return getYear(date)
    }
    getMonth(date: Date): number {
        return getMonth(date)
    }
    getDate(date: Date): number {
        return getDate(date)
    }
    getDayOfWeek(date: Date): number {
        return getDay(date)
    }
    getMonthNames(style: "long" | "short" | "narrow"): string[] {
        const arr = range(0, 11)
        const strMonths = arr.map(i => this.lc.localize.month(i, mapDatepickerStyleToDateFns(style)))
        return strMonths
    }
    getDateNames(): string[] {
        const arr = range(1, 31)
        const strDateName = arr.map(i => String(i))
        return strDateName
    }
    getDayOfWeekNames(style: "long" | "short" | "narrow"): string[] {
        const arr = range(0, 6)
        const strDateName = arr.map(i => this.lc.localize.day(i, mapDatepickerStyleToDateFns(style)))
        return strDateName
    }
    getYearName(date: Date): string {
        return String(getYear(date))
    }
    getFirstDayOfWeek(): number {
        return 0;
    }
    getNumDaysInMonth(date: Date): number {
        return getDaysInMonth(date)
    }
    clone(date: Date): Date {
        return toDate(date)
    }
    createDate(year: number, month: number, date: number): Date {
        return new Date(year, month, date)
    }
    today(): Date {
        return new Date()
    }
    parse(value: any, parseFormat: string): Date {
        return parse(value, parseFormat, new Date(), { locale: this.lc })
    }

    deserialize(value: any) {
        if (this.isDateInstance(value) || !value) return value
        const result = parseISO(value)
        if (!this.isValid(result)) throw new Error('Invalid date')
        return result
    }

    format(date: Date, displayFormat: any): string {
        return format(date, displayFormat, { locale: this.lc })
    }
    addCalendarYears(date: Date, years: number): Date {
        return addYears(date, years)
    }
    addCalendarMonths(date: Date, months: number): Date {
        return addMonths(date, months)
    }
    addCalendarDays(date: Date, days: number): Date {
        return addDays(date, days)
    }
    //adequar ao nosso backend
    toIso8601(date: Date): string {
        return date.toISOString()
    }
    isDateInstance(obj: any): boolean {
        return obj instanceof Date;
    }
    isValid(date: Date): boolean {
        return !isNaN(date?.getTime());
    }
    invalid(): Date {
        return new Date(NaN);
    }
}
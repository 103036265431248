import Endereco from "ui-components/models/endereco.model";

/**
 * Parse address object to form model
 * @param endereco 
 */

/*
{
        "pais_id": 1,
        "logradouro": "Praça Alexandre Lanza, 141 - Centro",
        "cidade": "Sete Lagoas ",
        "cep": "35700-040",
        "estado_id": 21,
        "bairro": "Bairro",
        "complemento": "Complemento",
        "numero": null
    }
*/
export function parseAddress(endereco: Endereco) {
    if (!endereco) return null;
    return {
        postalCode: (endereco?.cep as string)?.replace('-', ''),
        state: endereco?.estado_id,
        city: endereco?.cidade,
        neighborhood: endereco?.bairro,
        street: endereco?.logradouro,
        number: endereco?.numero,
        complement: endereco?.complemento,
        country: endereco?.pais_id,
    }
}

export function restructureAddress(control): Endereco {
    if (!control) return null;
    const address = {
        pais_id: control.country,
        logradouro: control.street,
        cep: control.postalCode,
        estado_id: control.state,
        cidade: control.city,
        bairro: control.neighborhood,
        complemento: control.complement,
        numero: control.number
    }
    return address
}
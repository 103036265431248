import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'ga-dialog-alert-alteration',
    template: `
            <div mat-dialog-content class="content">
                <h1 mat-dialog-title class="title">Atenção!</h1>
                <p>O número de instalação foi alterado e as faturas coletadas para a instalação antiga serão perdidas.</p>
                <p>Tem certeza que deseja continuar?</p>
            </div>
            <div mat-dialog-actions class="actions">
                <button mat-button class="cancelButton" [mat-dialog-close]="false">Cancelar</button>
                <button mat-button class="saveButton" [mat-dialog-close]="true">Salvar</button>
            </div>
    `,
    styles: [`
        .actions{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-around;

            padding-top: 0;
        }

        .actions button {
            border-radius: 5px;
            width: 7.5rem;
            height: 2.6rem;

            font-size: 1rem;
            
            padding-top: 1px;

            font-weight: 500;
        }

        .actions  .saveButton {
            color: #ffffff;
            background-color: var(--success);
        }

        .actions .cancelButton {
            color: var(--cancel-color);
            border: 1px solid var(--cancel-color);
        }
    `],
})

export class AlertAlterationComponent {
    constructor(
        public dialogRef: MatDialogRef<AlertAlterationComponent>,
    ) { }

    ngOnInit() { }

}


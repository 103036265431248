import { ChangeDetectorRef, Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { TENANT_THEME } from 'dg/app/tenant/initUtil';
import { Subscription } from 'rxjs';
import { TenantTheme } from '../models/tenant-theme.model';
import { TenantService } from '../tenant.service';

@Directive({
    selector: '[tenantAttr]',
    exportAs: 'tenantAttr'
})
export class TenantAttributeDirective implements OnInit {
    @Input('tenantAttr') input: string = "src:logo";
    $logoSubs: Subscription = new Subscription();
    constructor(
        @Inject(TENANT_THEME) private theme: TenantTheme,
        private el: ElementRef<HTMLElement>,
        private tenantService: TenantService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        const [attr, rawPath] = this.input.replace("'", '').split(':')
        this.$logoSubs = this.tenantService.$previewLogo.subscribe((logo: any) => {
            const path = rawPath.split('#')
            let value = this.theme?.logo ?? ''
            if (logo) {
                value = logo
            }
            this.el.nativeElement[attr] = value;
            this.cdr.detectChanges();
        })

    }

    ngOnDestroy() {
        this.$logoSubs.unsubscribe();
    }
}
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorService {
  private tracker: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  getObservable(): Observable<boolean> {
    return this.tracker.asObservable().pipe(share());
  }

  start() {
    this.tracker.next(true);
  }

  end() {
    this.tracker.next(false);
  }
}

import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { defaultTheme } from ".//themes/demo";
import { defaultColors, TenantTheme } from './models/tenant-theme.model';
import { TenantService } from "./tenant.service";
import { setChartColors, setDefaultColors, setTenantColors, setTenantIco, setTenantLogo } from './themeSetup';

export const TENANT_THEME = new InjectionToken<BehaviorSubject<TenantTheme>>('Provides tenant color information')

export function initApp(service: TenantService) {
    return (): void => { }
}

export function loadSkin(service: TenantService) {
    const favIcon: HTMLLinkElement = document.querySelector('#appIcon');

    const setDefault = () => {
        service.setTheme(defaultTheme);
        setTenantColors(defaultTheme.colors)
        setChartColors(defaultTheme.chart)
        setTenantLogo(defaultTheme.logo, service)
        setTenantIco(defaultTheme.favicon, favIcon)
        setDefaultColors(defaultColors)
    }
    return async () => {
        const tenantName = service.getClientLocation();
        service.getCurrentTheme().subscribe(
            (tema) => {
                const theme: TenantTheme = tema?.esquema ?? defaultTheme
                setTenantColors(theme.colors)
                setChartColors(theme.chart)
                setTenantLogo(theme.logo, service)
                setTenantIco(theme.favicon, favIcon)
                setDefaultColors(defaultColors)
            },
            (err) => {
                setDefault();
            })

    }

}
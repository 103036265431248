import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CurrentUserService } from 'dg/app/app-shell/currentUser.service';
import { AuthApi } from 'dg/app/auth/api';
import { JWT } from 'dg/app/auth/models/api/jwt.model';
import { Credential } from 'dg/app/auth/models/credential.model';

@Component({
    selector: 'ga-dialog-confirm-identity',
    template: `
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div mat-dialog-content class="content">
                <h1 mat-dialog-title class="title">Confirme sua identidade</h1>
                <p>Digite sua senha de login no Portal GD para exibir a informação.</p>
                <mat-form-field class="input">
                    <input matInput type="password" placeholder="Senha" formControlName="password">
                </mat-form-field>
            </div>
            <div mat-dialog-actions class="actions">
                <button mat-button (click)="onCancel()" class="cancelButton">Cancelar</button>
                <button mat-button class="saveButton">OK</button>
            </div>
        </form>
    `,
    styles: [`
        .content .input {
            width: 100%;
        }

        .actions{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-around;

            padding-top: 0;
        }

        .actions button {
            border-radius: 5px;
            width: 7.5rem;
            height: 2.6rem;

            font-size: 1rem;
            
            padding-top: 1px;
        }

        .actions  .saveButton {
            color: #ffffff;
            background-color: var(--success);

            font-weight: 600;
        }

        .actions .cancelButton {
            color: var(--cancel-color);
            border: 1px solid var(--cancel-color);

            font-weight: 500;
        }
    `],
})

export class ConfirmIdentityComponent {
    status: boolean
    form: FormGroup

    constructor(
        public dialogRef: MatDialogRef<ConfirmIdentityComponent>,
        private user: CurrentUserService,
        private http: HttpClient,
        private fb: FormBuilder
    ) { }

    ngOnInit() {
        const form: Credential = {
            username: [this.user.getUserValues().email, [Validators.required]],
            password: ['', [Validators.required]]
        }
        this.form = this.fb.group(form);
    }


    onCancel(): void {
        this.status = false
        this.dialogRef.close(this.status);
    }

    onSubmit() {
        if (this.form.valid) {
            this.http.post<JWT>(AuthApi.login, this.form.value as Credential).subscribe(
                res => {
                    this.status = true
                    this.dialogRef.close(this.status);
                }
            )
        }

    }

}

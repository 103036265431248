import {
  ChangeDetectionStrategy,
  Component, Input,
  OnChanges, OnInit,
  SimpleChanges
} from "@angular/core";

export type BreadcrumbSteps = { label: any, url: string | string[], value: any };

@Component({
  selector: "breadcrumb-dropdown",
  template: `
    <span class="label">{{label}}</span>
    <div class="field">
      <a class="breadcrumb-link" [routerLink]="_selected.url">
        {{ _selected.label }}
      </a>
      <button class="trigger" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="after">
        <a mat-menu-item *ngFor="let item of list" [routerLink]="item.url" >
          {{item.label}}
        </a>
      </mat-menu>
    </div>
  `,
  host: { class: "breadcumb-menu-head" },
  styleUrls: ["breadcrumb-dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadbrumDropdownComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() value: any;
  @Input() list: BreadcrumbSteps[] = [];
  _selected: BreadcrumbSteps = { url: '#' } as BreadcrumbSteps

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    const { value: valueChanges } = changes
    if (valueChanges && valueChanges.currentValue) {
      const found = this.list.find(item => item.value == valueChanges.currentValue)
      this._selected = found ?? { url: '#' } as BreadcrumbSteps
    }

  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ConfirmDialogData {
    msg: string;
    isReversable?: boolean;
    saveBtn: { label: string, class: string };
}

@Component({
    selector: 'ga-dialog-confirm',
    template: `
        <div mat-dialog-content>
            <div class="title">{{isDelete ? ('Tem certeza que deseja excluir ' + data.msg + '?') : data.msg}}</div>
            <div class="subtitle" *ngIf="!data.isReversable">
                Você não poderá desfazer essa ação.
            </div>
        </div>
        <div mat-dialog-actions>
            <button class="cancel-btn confirm-dialog" mat-stroked-button mat-dialog-close cdkFocusInitial>Cancelar</button>
            <button class="confirm-dialog" ngClass="{{data.saveBtn.class}}" color="accent" mat-flat-button [mat-dialog-close]="true">{{data.saveBtn.label}}</button>
        </div>
    `,
    styles: [`
        .title{
            margin-block-start: 10px;
            margin-block-end: 10px;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 19px;
            color: var(--title);
        }
        .subtitle{
            color: var(--subtext);
            line-height: 19px;
        }
    `],
})
export class ConfirmComponent implements OnInit {
    isDelete: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<ConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
    ) {
        if (this.data.saveBtn.label == 'Excluir') {
            this.isDelete = true;
        }

    }

    ngOnInit() { }
}

export function openConfirmDeleteDialog(dialogData, dialogRef) {
    openConfirmDialog({ ...dialogData, saveBtn: { label: 'Excluir', class: 'delete-btn' } }, dialogRef)
}

export function openConfirmDialog({ msg, action, isReversable, saveBtn }: { action: Function } & ConfirmDialogData, dialog: MatDialog) {
    const dialogRef = dialog.open(ConfirmComponent, {
        width: '450px',
        data: { msg, isReversable, saveBtn },
        autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
        if (result) {
            action()
        }
    });
}
import {
    HttpErrorResponse, HttpEvent, HttpHandler,
    HttpInterceptor, HttpRequest, HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
    constructor() { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            //retry(1),
            catchError((error: HttpErrorResponse) => {
                if (error.status === HttpStatusCode.Forbidden) {
                    // refresh token
                } else {
                    return throwError(error);
                }
            })
        );
    }

    unauthorized(err: HttpErrorResponse) {
        if (err.status === HttpStatusCode.Unauthorized) {
            // auto logout if 401 response returned from api
            //location.reload(true);
        }
        const error = err.error.message || err.statusText;
        return error;
    }
}
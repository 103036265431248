import { Injectable } from '@angular/core';
import { FeatureLabel, FeatureSet } from '../models/feature-toogle.model';

@Injectable({ providedIn: 'root' })
export class FeatureToogleService {
    value: FeatureSet = null

    constructor() { }

    setValue(value: FeatureSet) {
        this.value = value
    }

    check(label: FeatureLabel): boolean {
        return this.value[label]
    }
}

<nav>
  <div fxHide.gt-sm>
    <button mat-icon-button (click)="onToggleSideNav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <img
    alt="company-logo"
    routerLink=""
    class="logo"
    [tenantAttr]="'src:logo'"
  />
  <div class="links">
    <div fxFlex fxLayout fxHide.lt-md mat-tab-nav-bar>
      <a
        *ngFor="let link of links"
        mat-tab-link
        [routerLink]="link.url"
        routerLinkActive
        [disableRipple]="true"
        #rla="routerLinkActive"
        [active]="rla.isActive"
      >
        {{ link.label }}
      </a>
    </div>
  </div>
  <div>
    <button
      mat-mini-fab
      color="accent"
      class="user-button"
      [matMenuTriggerFor]="configs"
    >
      {{ shortUsername }}
    </button>
    <mat-menu #configs="matMenu">
      <button mat-menu-item (click)="editUser()">Editar Usuário</button>
      <button mat-menu-item (click)="logout()">Logout</button>
      <hr />
      <span mat-menu-item [disabled]="true" class="version">
        v{{ version }}
      </span>
    </mat-menu>
  </div>
</nav>

import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureLabel } from '../models/feature-toogle.model';
import { FeatureToogleService } from '../services/feature-toogle.service';


@Directive({ selector: '[gaFeature]', exportAs: '[gaFeature]' })
export class FeatureToogleRenderDirective implements OnInit {
    @Input('gaFeature') feature: FeatureLabel;

    constructor(private vcr: ViewContainerRef, private tmp: TemplateRef<any>, private service: FeatureToogleService) { }

    ngOnInit() {
        if (!this.feature) {
            throw Error('There is no supplied feature label')
        }
        if (this.service.check(this.feature)) {
            this.vcr.createEmbeddedView(this.tmp)
        }
    }

}
/// <reference types="@types/googlemaps" />

import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TENANT_THEME } from 'dg/app/tenant/initUtil';
import { TenantTheme } from 'dg/app/tenant/models/tenant-theme.model';
import { environment } from 'dg/environments/environment';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Powerplant } from 'ui-components/models/powerplant.model';
import { Projeto } from 'ui-components/models/project.model';
import { GOOGLE_MAPS_KEY_TOKEN } from "./GOOGLE_MAPS_KEY_TOKEN";
import markerPath from './maker-path';

export interface ModalMapData {
  markers: ModalMapMaker[],
  project: Projeto;
  powerplant: Powerplant;
}

export interface ModalMapMaker {
  lat: number, lng: number, name: string
}

@Component({
  selector: 'dg-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  providers: [
    { provide: GOOGLE_MAPS_KEY_TOKEN, useValue: environment.keys.GOOGLE_MAPS_KEY }
  ]
})
export class MapsComponent implements OnInit {

  options = null

  apiLoaded$ = of(false)

  markers: { pos: google.maps.LatLngLiteral, options: google.maps.MarkerOptions }[] = []

  get subtitle() {
    if (!this.data) return '';
    const { project, powerplant } = this.data
    if (project) {
      if (powerplant) {
        return `${project.nome} | ${powerplant.nome}`
      }
      return `${project.nome}`
    }
  }

  constructor(
    private httpClient: HttpClient,
    @Inject(GOOGLE_MAPS_KEY_TOKEN) private key: string,
    @Inject(TENANT_THEME) private theme: BehaviorSubject<TenantTheme>,
    @Inject(MAT_DIALOG_DATA) private data: ModalMapData
  ) {
  }

  ngOnInit(): void {
    if (window.google && window.google.maps) {
      this.apiLoaded$ = of(true)
    } else {
      this.apiLoaded$ = this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${this.key}&language=pt-BR&region=BR`, 'callback')
        .pipe(
          map(() => true),
          catchError(() => of(false)),
          shareReplay()
        );
    }

    this.apiLoaded$.subscribe({
      next: (loaded) => {
        if (loaded) {
          this.theme.subscribe((theme: TenantTheme) => {
            this.markers = this.data.markers.map(item => ({
              pos: { lat: item.lat, lng: item.lng },
              options: this.getMarkersOptions(item.name, theme)
            }))
            this.options = this.getMapOptions();
          })
        }
      }
    })
  }

  getMarkersOptions(label: string, theme: TenantTheme): google.maps.MarkerOptions {
    return {
      draggable: false,
      clickable: false,
      icon: this.markerIconTemplate(theme),
      label: this.markerLabelTemplate(label),
      title: label
    }
  }

  markerIconTemplate(theme: TenantTheme): google.maps.Symbol {
    return {
      fillColor: theme.colors['primary'],
      strokeColor: theme.colors['primary'],
      scale: 1.8,
      fillOpacity: 1,
      strokeWeight: 0,
      path: markerPath
    }
  }

  markerLabelTemplate(label: string): google.maps.MarkerLabel {
    return {
      text: label,
      className: 'marker-label',
      color: "#FFF",
      fontFamily: 'Roboto',
      fontWeight: '700'
    }
  }

  getMapOptions(): google.maps.MapOptions {
    return {
      center: { lat: -14.397, lng: -45.644 },
      zoom: 1,
      maxZoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,

      clickableIcons: false,
      streetViewControl: false,
      mapTypeControl: false
    }
  }

}

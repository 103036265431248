import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ExplanationDialogData {
    msg: string;
    title: string;
    /**
     * Material design icon name
     */
    icon?: string;
}

@Component({
    template: `
        <div mat-dialog-title>
            <mat-icon *ngIf="data.icon">{{data.icon}}</mat-icon>
            <header class="ga-dialog-title">{{data.title}}</header>
        </div>
        <div mat-dialog-content>
            <p style="white-space: pre-line">{{data.msg}}</p>
        </div>
        <mat-dialog-actions>
            <button mat-stroked-button [mat-dialog-close]="false" class="button close">
                Fechar
            </button>
        </mat-dialog-actions>
    `
})
export class ExplanationComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ExplanationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ExplanationDialogData
    ) { }

    ngOnInit() { }
}

export function openExplanationDialog({ msg, title, icon }: ExplanationDialogData, dialog: MatDialog) {
    const dialogRef = dialog.open(ExplanationComponent, {
        width: '450px',
        data: { msg, title, icon },
        disableClose: true,
        autoFocus: false
    });
}
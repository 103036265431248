import { aesTheme } from "./themes/aes";
import { defaultTheme } from "./themes/demo";
import { fotossinteseTheme } from './themes/fotossintese';
import plainTheme from './themes/plain';
import { pranaTheme } from "./themes/prana";
import primasolTheme from './themes/primasol';
import { raizenTheme } from "./themes/raizen";


export const tenantTable = {
    'raizen': raizenTheme,
    'fotossintese': fotossinteseTheme,
    'aes': aesTheme,
    'demo': defaultTheme,
    'prana': pranaTheme,
    'primasol': primasolTheme,
    'plain': plainTheme
};

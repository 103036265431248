export default [
    ['sunny', '/assets/icons/sunny.svg'],
    ['rainy', '/assets/icons/rainy.svg'],
    ['stormy', '/assets/icons/stormy.svg'],
    ['partly-sunny', '/assets/icons/partly-sunny.svg'],
    ['excel', '/assets/icons/excel.svg'],
    ['pdf', '/assets/icons/pdf.svg'],
    ['zip', '/assets/icons/zip.svg'],
    ['png', '/assets/icons/png.svg'],
    ['panel', '/assets/icons/panel.svg'],
    ['invoice', '/assets/icons/invoice.svg'],
    ['trash', '/assets/icons/trash.svg']
] as [string, string][]
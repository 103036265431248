import { ChartColors, defaultColors, TenantColors } from "../models/tenant-theme.model";

const tenantColors: TenantColors = {
   'primary': '#00415f',
   'secondary': '#0075af',
   'btn-primary': '#96c01f',
   'btn-secondary': '#5a740f',
};

const chartColors: ChartColors = {
   'chart-color-1': '#00415f',
   'chart-color-2': '#96c01f',
   'chart-color-3': '#0075af',
   'chart-color-4': '#5a740f'
};

const svgAes = `
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   viewBox="0 0 782.219 136.44592"
   width="300.93164"
   height="107.15648"
   version="1.1"
   id="svg26"
   sodipodi:docname="test.svg"
   style="fill:none"
   inkscape:version="0.92.3 (2405546, 2018-03-11)">
  <metadata
     id="metadata32">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <defs
     id="defs30" />
  <sodipodi:namedview
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1"
     objecttolerance="10"
     gridtolerance="10"
     guidetolerance="10"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:window-width="1920"
     inkscape:window-height="1017"
     id="namedview28"
     showgrid="false"
     inkscape:zoom="1.415"
     inkscape:cx="100"
     inkscape:cy="53.534921"
     inkscape:window-x="0"
     inkscape:window-y="30"
     inkscape:window-maximized="1"
     inkscape:current-layer="svg26" />
  <g
     id="g851"
     transform="translate(0,-27.219844)">
    <path
       id="path2"
       d="M 564.66,90.8699 H 534.39 V 186.54 H 522.57 V 90.8699 h -30.59 v -11.01 h 76.89 z"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
    <path
       id="path4"
       d="m 584.86,93.9399 c -4.53,0 -8.26,-3.72 -8.26,-8.25 0,-4.53 3.72,-8.25 8.26,-8.25 4.53,0 8.25,3.72 8.25,8.25 0.01,4.53 -3.71,8.25 -8.25,8.25 z m -5.82,92.6001 v -76.57 h 11.81 v 76.57 z"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
    <path
       id="path6"
       d="m 634.53,176.92 c 2.2,0.57 4.6,0.87 7.21,0.87 6.47,0 13.6,-2.59 18.13,-5.5 l 3.56,8.09 c -5.51,4.53 -15.22,7.77 -24.12,7.77 -1.65,0 -3.24,-0.1 -4.77,-0.28 v -10.95 z m 0,-68.89 c 0.08,0 0.16,0 0.24,0 19.75,0 30.59,15.7 30.59,39.49 0,0.81 0,1.78 0,2.75 h -30.83 v -10.03 h 18.05 c 0,-10.68 -5.99,-22.18 -17.64,-22.18 -0.14,0 -0.27,0 -0.41,0.01 z m -18.22,42.25 c 0,12.34 6.04,23.51 18.21,26.65 v 10.96 c -20.73,-2.44 -30.84,-20.81 -30.84,-41.16 0,-22.08 12.51,-38.54 30.84,-38.68 v 10.04 c -11.1,0.24 -18.05,10.17 -18.05,22.17 h 18.05 v 10.03 h -18.21 z"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
    <path
       id="path8"
       d="m 698.08,188.16 c -11.01,0 -16.35,-6.47 -16.35,-16.35 v -51.8 h -12.46 v -10.04 h 12.46 V 93.1299 l 11.81,-4.0401 V 109.97 h 23.47 l -4.211,10.04 H 693.53 v 48.24 c 0,6.47 2.43,9.39 7.45,9.39 5.51,0 10.52,-3.07 13.6,-5.51 l 3.719,8.42 c -5.65,4.21 -13.089,7.61 -20.219,7.61 z"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
    <path
       id="path10"
       d="m 751.379,176.93 c 2.2,0.57 4.6,0.87 7.2,0.87 6.48,0 13.6,-2.59 18.14,-5.5 l 3.56,8.09 c -5.51,4.53 -15.22,7.77 -24.12,7.77 -1.65,0 -3.24,-0.1 -4.77,-0.28 v -10.95 z m 0,-68.9 c 0.08,0 0.16,0 0.24,0 19.75,0 30.6,15.7 30.6,39.49 0,0.81 0,1.78 0,2.75 h -30.84 v -10.03 h 18.05 c 0,-10.68 -5.99,-22.18 -17.65,-22.18 -0.13,0 -0.27,0 -0.4,0.01 z m 0,-21.4902 v -9.76 h 6.88 l 17.16,23.8002 h -11.99 l -10.85,-15.8602 z m -18.22,63.7402 c 0,12.34 6.04,23.52 18.21,26.65 v 10.95 c -20.73,-2.44 -30.84,-20.81 -30.84,-41.16 0,-22.08 12.52,-38.54 30.84,-38.68 v 10.04 c -11.09,0.23 -18.05,10.17 -18.05,22.17 h 18.05 v 10.03 z m 18.22,-73.5002 v 9.76 l -9.31,14.0402 h -11.81 l 16.83,-23.8002 z"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
    <path
       id="path12"
       d="M 288.69,186.59 278.14,159.8 h -19.17 v -16.09 h 12.99 l -8.61,-24.52 c -1.94,-5.36 -3.24,-9.74 -4.06,-13.31 h -0.17 c -0.05,0.28 -0.1,0.57 -0.16,0.86 V 79.0798 h 7.63 L 309.64,186.6 H 288.69 Z M 258.97,159.8 h -18.99 l -9.91,26.79 H 210.73 L 252.31,79.0698 h 6.65 V 106.73 c -0.71,3.52 -2.09,7.48 -3.74,12.14 l -9.25,24.84 h 12.99 v 16.09 z"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
    <path
       id="path14"
       d="M 316.949,186.59 V 79.5698 h 59.61 l -5.36,16.39 h -34.61 V 123.74 h 34.61 v 16.4 h -34.61 v 30.07 h 39.97 v 16.38 z"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
    <path
       id="path16"
       d="m 418.45,188.21 c -15.1,0 -25.82,-4.86 -32.97,-9.73 l 7.97,-14.47 c 5.83,4.06 14.12,8.13 24.2,8.13 8.27,0 17.7,-3.4 17.7,-12.35 0,-15.27 -25.99,-18.03 -38.98,-32.64 -4.23,-4.71 -6.99,-10.9 -6.99,-19.16 0,-15.9302 12.5,-29.4202 34.92,-29.4202 11.36,0 20.45,2.77 26.31,6.01 l -0.32,17.5402 c -8.29,-4.5402 -16.24,-7.4602 -25.02,-7.4602 -8.62,0 -15.42,3.73 -15.42,10.8602 0,19.83 46.29,18.69 46.29,51.98 0,18.86 -16.58,30.71 -37.69,30.71 z"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
    <path
       id="path18"
       d="M 15.5,150.44 C 5.7,135.29 0,117.18 0,97.7698 0,44.0998 43.52,0.589844 97.19,0.589844 c 20.72,0 29.54,5.709996 32.55,8.249996 5.33,4.49996 8.91,10.71996 8.5,17.13996 -1.69,25.21 -37.87,20.0201 -73.75,28.4301 C 12.13,66.6799 -4.12,112.03 15.5,150.44 Z"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
    <path
       id="path20"
       d="m 137.741,20.8696 c 1.99,7.51 1.06,13.1 -1.56,18.3 -18.68,36.87 -90.5903,11.01 -107.7703,71.2404 -6.98,28.05 7.56,61.43 37.18,77.71 2.8,1.54 2.49,2.46 -0.56,1.39 -20.53,-7.2 -37.92,-21.07 -49.54,-39.08 -19.61002,-38.4 -3.37,-83.7704 48.99,-96.0304 35.8803,-8.41 72.0603,-3.23 73.7503,-28.44 0.12,-1.71 -0.05,-3.42 -0.49,-5.09 z"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
    <path
       id="path22"
       d="m 132.56,7.21986 c 27.18,10.62004 48.39,33.12994 57.28,61.10994 0,0 6.4,23.6 -8.5,44.1602 -26.94,37.14 -74.75,23.74 -79.47,-1.9 -7.6696,-41.5201 49.24,-37.3801 44.83,-78.2801 -1.34,-9.99 -7.53,-18.5501 -15.11,-23.86006 -2.92,-2.03 -2.3,-2.50998 0.97,-1.22998 z"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
    <path
       id="path24"
       d="m 128.62,189.76 c -2.81,0.97 -3.39,0.54 -0.73,-1.47 5,-3.8 9.26,-10.01 8.16,-18.44 -3.1,-23.85 -37.3399,-20.37 -42.7299,-49.22 -2.58,-13.85 3.06,-30.1399 24.1999,-45.2799 -10.47,8.14 -18.9299,17.53 -15.66,35.2399 4.72,25.64 52.53,39.03 79.47,1.9 14.89,-20.5599 8.49,-44.1599 8.49,-44.1599 2.96,9.29 4.55,19.18 4.55,29.44 0.01,42.6799 -27.49,78.9299 -65.75,91.9899 z"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
  </g>
</svg>
`;

export const aesTheme = {
   logo: svgAes,
   colors: tenantColors,
   default: defaultColors,
   chart: {
      colors: chartColors
   }
};

import { ChartColors, defaultColors, TenantColors } from "../models/tenant-theme.model";


export const logo = `
<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
viewBox="0 0 16000 8000"
 width="160px" 
 height="80px"
 xmlns:xlink="http://www.w3.org/1999/xlink">
 <defs>
  <style type="text/css">
   <![CDATA[
    .fil0 {fill:#FFFFFF}
   ]]>
  </style>
 </defs>
 <g id="Camada_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <path class="fil0" d="M6654 820l-1072 488 0 539 1072 -334 0 -693zm7810 1236c-334,0 -604,115 -861,359l0 -289 -751 0 0 3063 771 0 0 -1624c0,-424 186,-777 706,-777 482,0 617,353 617,777l0 1624 764 0 0 -1862c0,-700 -417,-1271 -1246,-1271zm-4239 1233c109,-379 398,-559 751,-559 366,0 636,160 732,559l-1483 0zm745 -1233c-912,0 -1535,712 -1535,1586 0,880 629,1586 1535,1586 732,0 1233,-405 1426,-995l-797 0c-109,186 -282,289 -584,289 -456,0 -719,-232 -796,-623l2260 0c7,-77 13,-135 13,-212 0,-1060 -681,-1631 -1522,-1631zm-2967 4411c-411,0 -687,-301 -687,-700 0,-398 276,-700 687,-700 405,0 687,302 687,700 0,399 -282,700 -687,700zm250 -2716l1086 -963 0 -662 -2608 0 0 713 1503 0 -963 841 642 745c-835,52 -1349,642 -1349,1349 0,796 649,1406 1445,1406 790,0 1452,-610 1452,-1406 0,-424 -116,-739 -379,-1047l-829 -976zm-7212 -1323l0 -302 -751 0 0 3063 771 0 0 -1618c0,-424 186,-751 751,-751l231 0 0 -732 -167 0c-334,0 -578,96 -835,340zm4560 2761l777 0 0 -3172 -777 193 0 2979zm-1959 -706c-468,0 -796,-340 -796,-841 0,-501 328,-848 796,-848 469,0 797,347 797,848 0,501 -328,841 -797,841zm13 -2427c-892,0 -1580,706 -1580,1586 0,918 604,1586 1497,1586 302,0 642,-122 886,-379l0 340 751 0 0 -1618c0,-841 -655,-1515 -1554,-1515z"/>
 </g>
</svg>
`;
const tenantColors: TenantColors = {
    'primary': '#781E76',
    'secondary': '#E21683',
    'btn-primary': '#421142',
    'btn-secondary': '#FC8C15'
};

const chartColors: ChartColors = {
    'chart-color-1': '#781E76',
    'chart-color-2': '#421142',
    'chart-color-3': '#E21683',
    'chart-color-4': '#FC8C15'
};

export const raizenTheme = {
    logo: logo,
    colors: tenantColors,
    default: defaultColors,
    chart: {
        colors: chartColors
    }
};

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import Empresa from "ui-components/models/empresa.model";
import { ApiService } from "../api/api.service";

@Injectable()
export class ClientService {
    private _state = new BehaviorSubject<Empresa[]>([]);
    private _obs = this._state.asObservable()
    get updatedClients() {
        return this._obs
    }

    constructor(private apiService:ApiService) { }

    getClients() {
        return this._state.value.slice();
    }

    setClients(empresas: Empresa[]) {
        this._state.next(empresas);
    }

    getClient(id: number) {
        let cli = null;
        this._state.value.forEach(e => {
            if (e.id == id) {
                cli = e;
            }
        });
        return cli;
    }

    getGroups():Promise<any> {
        return this.apiService.getGroups().toPromise().then(resp=>{
            return resp;
        })
    }

}

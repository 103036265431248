import { Component, OnInit } from '@angular/core';
import { version } from 'dg/environments/version';


@Component({
  selector: 'app-auth-wrapper',
  host: {
    'class': 'login-wrapper'
  },
  template: `
  <router-outlet></router-outlet>
  `,
  styleUrls: ['./auth-wrapper.component.scss']
})
export class AuthWrapperComponent implements OnInit {
  readonly version = version

  constructor() { }

  ngOnInit() { }

}

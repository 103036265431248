import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { ChipsSelectorComponent } from "./chips-selector.component";

@NgModule({
    declarations: [ChipsSelectorComponent],
    exports: [ChipsSelectorComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatIconModule
    ]
})
export class ChipsSelectorModule { }
import { ChartColors, defaultColors, TenantColors } from "../models/tenant-theme.model";

const tenantColors: TenantColors = {
   'primary': '#071C2C',
   'secondary': '#BD4700',
   'btn-primary': '#BD4700',
   'btn-secondary': '#071C2C',
};

const chartColors: ChartColors = {
   'chart-color-1': '#33CC99',
   'chart-color-2': '#E4E845',
   'chart-color-3': '#79B082',
   'chart-color-4': '#E5811C'
};

const svgAes = `
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:xlink="http://www.w3.org/1999/xlink"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   version="1.1"
   id="svg2"
   width="400"
   height="120"
   viewBox="0 0 712 158.39999"
   sodipodi:docname="logo.svg"
   inkscape:version="0.92.3 (2405546, 2018-03-11)">
  <metadata
     id="metadata8">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title></dc:title>
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <defs
     id="defs6" />
  <sodipodi:namedview
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1"
     objecttolerance="10"
     gridtolerance="10"
     guidetolerance="10"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:window-width="1920"
     inkscape:window-height="1017"
     id="namedview4"
     showgrid="false"
     inkscape:zoom="0.39747191"
     inkscape:cx="356"
     inkscape:cy="79.199997"
     inkscape:window-x="0"
     inkscape:window-y="30"
     inkscape:window-maximized="1"
     inkscape:current-layer="svg2" />
  <image
     width="712"
     height="158.39999"
     preserveAspectRatio="none"
     xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAACLEAAAHvCAYAAAChGd+zAAAACXBIWXMAAC4jAAAuIwF4pT92AAAg
AElEQVR42uzd7XVbN7o24HtmzX/pVCBOBdJUYKYCayowU4GVCsxUEKWC0BVEriB0BSNXMFQFr1SB
3x977xEtS7I++LEBXNdaXE4yJzkksIENPHgA/O3r169hK06SHK79/WWSa8UCAAAAAAAAAPC9fyiC
ZzlMl5wyufNJ/88PHvl3f0qyVIQwWtP+05JFkpWqBwCAV5v7fsCWTCNeAQAANEQSy8Mm/QRx+PNH
SSpA2aZJPjT2m5cRFAIAgNeajXwu8VkVQdGmEa8AAAAaIonl1kludzZMI2EFAAAA+LG57wcAAACw
Ga0nsZz2n2mSI48DAAAA8AyzjDue8DmuNgYAAAAK0mISy2m6INNb1Q8AAAC8wtz3AwAAANicVpJY
TpKcpUtgcU0QAAAA8FqzOIUFAAAAYKNqTmI5TJe0Mo+rggAAAIDNmvt+AAAAAJtVYxLLJN2pK7M4
dQUAAADYvFmcwgIAAACwcTUlsUzS7TJ6p1oBAACALZr7fgAAAACbV0MSyySSVwAAAIDdmMUpLAAA
AABbUXISyySSVwAAAIDdmvt+AAAAANtRYhLLYZKzJB9UHwAAALBDsziFBQAAAGBrSktimSU5T3Kg
6gAAAIAdm/t+AAAAANtTShLLSbrklTeqDAAAANiDWZzCAgAAALBVfy/gO86T/CcSWAAAAID9mft+
AAAAANs15pNYTpIskhyrJgAAAGCPZnEKCwAAAMDWjfUklnm601cksAAAAAD7Nvf9AAAAALZvbCex
HCa5iKuDAAAAgHGYxSksAAAAADsxppNYpklWkcACAAAAjMfc9wMAAADYjbEksZwl+SvJgSoBAAAA
RmIWp7AAAAAA7My+rxM6THKe5J2qAAAAAEZm7vsBAAAA7M4+k1gO0+0WOlYNAAAAwMjM4hQWAAAA
gJ3a13VCJ5HAAgAAAIzX3PcDAAAA2K19nMQyJLAcKH4AAABghGZxCgsAAADAzu36JBYJLAAAAMDY
zX0/AAAAgN3bZRKLBBYAAABg7GZxCgsAAADAXuwqiUUCCwAAAFCCue8HAAAAsB+7SGKRwAIAAACU
YBansAAAAADszbaTWCSwAAAAAKWY+34AAAAA+7PNJJZJJLAAAAAAZZjFKSwAAAAAe7WtJJbDJBeR
wAIAAACUYe77AQAAAOzXtpJYlkmOFS8AAABQgFmcwgIAAACwd9tIYllEAgsAAABQjrnvBwAAALB/
m05imSV5p1gBAACAQszjFBYAAACAUdhkEstJkj8UKQAAAFCIwyRnI/+Oc9UEAAAAtGJTSSyHSS4U
JwAAAFCQsyQHI/5+TmEBAAAAmrKpJJZFxn30LgAAAMA6p7AAAAAAjMwmkljOkrxVlAAAAEBBnMIC
AAAAMDKvTWKZxK4gAAAAoCxOYQEAAAAYoX+88t9fZNy7lvbtS5Lr/q+vFQcAAACMwthPYfkYp7AA
AAAADXpNEstZkjeNl99Nkst0gaXVnQ9QluWG/3uTJO8UKwAAjNIqya8j/n4LVQT0llv4b35QrAAA
wFj97evXry/59ybpkjdaPIXlUz95XPZlAHCfaZK/Rv4df4rdnQAAANCaryP/fuIVAADQsJeexHKe
thJYPqXbBXXhkQEAAAAAAAAA2LyXJLFMk7xtoGyu0iXrLJJce1QAAAAAAAAAALbnJUksi8rL5CrJ
PO6fBgAAAAAAAADYmecmsZwlOaq0LK763+fKIAAAAAAAAACAHXtOEsthuhNKanOTLnll4XEAAAAA
AAAAANiPvz/j//YsyUFlv//3JJNIYAEAAAAAAAAA2KunnsRymC6JpRZXSWZJlh4BAAAAAAAAAID9
e+pJLDWdwvIxyUkksAAAAAAAAAAAjMZTTmKp5RSWm/53LFQ7AAAAAAAAAMC4PCWJpYZTWK6SnCa5
VOUAAAAAAAAAAOPz1CSWkn1JMk1yrboBAAAAAAAAAMbp7z/432cp+xQWCSwAAAAAAAAAAAX4URLL
vODf9jHJSSSwAAAAAAAAAACM3mNJLNMkR4X+ri/pTpEBAAAAAAAAAKAAjyWxzAr9TcMVQgAAAAAA
AAAAFOKhJJbDJO8K/D1X6RJYXCEEAAAAAAAAAFCQh5JYZgX+lpskp5HAAgAAAAAAAABQnJqSWGZJ
LlUpAAAAAAAAAEB57ktimSQ5Lux3/J7kQnUCAAAAAAAAAJTpviSW08J+w5ckZ6oSAAAAAAAAAKBc
9yWxzAr7DTPVCAAAAAAAAABQtrtJLJOUdZXQ70kuVSMAAAAAAAAAQNnuJrFMC/ruV0nmqhAAAAAA
AAAAoHx3k1hOC/ru8yTXqhAAAAAAAAAAoHylnsRylWSh+gAAAAAAAAAA6rCexDJNclDI956pOgAA
AAAAAACAetxNYinBVZKlqgMAAAAAAAAAqMd6EstJId95rtoAAAAAAAAAAOpS2kksV0kWqg0AAAAA
AAAAoC5DEsskyUEB33ehygAAAAAAAAAA6jMksZRyldBClQEAAAAAAAAA1KekJJZPSVaqDAAAAAAA
AACgPiUlsVyoLgAAAAAAAACAOg1JLJORf8+buEoIAAAAAAAAAKBa/+j/PB7593QKC/s0yW2i1/TO
/3aS5PCRf/c6yeUDf3/Z/z0AT3eY2xPk7vbB6/31Q5YP/P0qri0EAAAAAADYq39k/KewJJJY2L5h
UXSa20XQSZKjDfy33/7gf/+SLpllmdtF1KUqARo37fvlSW6TVTaRdPvmzt9/uPP3V30/fLn2p6RD
AAAAAACAHSghieUmkljYvOna5yTJwR6/y7Aoe3dh9Uu6hdNlbhNcAGp0cqdPPtrjdznqP3f75Ks7
ffKlagMAAAAAANisEpJYJLCwCdO1z5tCvvNx/3nX//1VbhdPL+JUAKBcJ3f65YMCvvOQ3DKcrnVz
p09eqVYAAAAAAIDXKSGJZamaeIHDdAujp/3noILfdJQuoeVdkj/SndSyiMVToAzrffJRBb/nIF1C
y9skv6VLNLzo+2WntLDNdsT3VsZC2kDjWrn2brheUL1qb9tyXcE4bup9CTBKw1X2D1kqIozfm2FM
xjbbVg1zGkiS/O3r16/zJB9G/B2/pI6A3JmOYyeGBdJ3jf3uIaFlESe0jMU0yV8FPDe1PC/62PEO
qGf956Ch3z0ktJyblPIMQ1BzuvbXh7m9dpCH/ZpkrhiKN+k/07W/nqSOxMdt+yltLHwsU86pmjXU
69cG29LnjDN5Z31skLXv+No+8mZtDrV64EO9xt7Ga4pXLPoPddpWHz20gfXFyKX+mREZnvuT/nkX
w3g6MQwea1OTO5/0//w1sfXPD4z5W9kQQ6H+kcczgMeglpeejNPtmaRbwJ6lrUXSu+3kt/7zKbcn
tEAL/as+dnx1Mev75VYXHo+SvO8/X9Ils7gGjvvGL9O1j4V6WmwDpynrajmAbfeLw2LQNNtP5DvI
bWLYQwlin3Mb4L6MkwLYnZriFdpNHdY3HQx99dGO2sBwrfH6RuQva32z/pldtYH1+ZsYBrxu3L/+
PnltksqPPDbmv7nnfbJSRYzBP2Lhj3LN+s8bRfGN4XqLq3QLp4tYOAW2b9r3ye8UxTeO010BN/TH
TmcxST3t24rdSbRoOKGrlqvlAF47Lphm3ItBb/rP+hj/c24D3DbPALVaX7A/GeH87bj/3O2fl2sf
2MRYRQwDXu/kzrh/TJt4DtbG/IObO+8TJ+CzF/9QBBQ4gZil7R3+T3WU7mSWeSycAtszi4TCp04I
htNZPvb98lKxNDN2Oe3HLoI+GL8DtG1YEC05mW8Icr/v//5zumSWi4g5AGU7ye1V9SXO3Yb+eTix
5VPfNy/1zzzTLGJ9sIlx/zD2L23cf5DbzfJJt2F+uTbmh52QxEIpDtMFvs/iqPGXvHDWF07nJi7A
hia081iQfIl3/edzX4ZLRVKlSVx3iDYwjxO6AE7XPjWOCYZF09/SBbgv0iVs27EJlKDmkwLXFyC/
9H3zIk7s5n7WX+D1hvfJ28p+11Fu49k3uU1mkdDCVv1dEVDA4GmeLunigwHUq71L8t9+wjJRHMAL
B+OrdFfkSGB5nTdJ/kqXxDJVHNWY9O/Z/6ZLIDV2oeU2IIEFaNU0twuFf/b9YQtjgqN+/POffs5w
FrEHYJzj1XnfT/2n77dqj28cp0s2/H/pFh1PPQb0DvsxyyrWX+C14/4/Ul8Cy10H/dzmz/43L9Il
hMLG/T2OBGO85gZPWzMks5z3A1WAHzmN5JVtGZJZLiLIX7LD/r1q4Z5WTSJ5BTAWmPdj5r/STuLK
Q4Yrjv8bC6bAOJz2/dF/08WbW41tvM3t4uM84hDGLcYs8JL2c2bc/7+Elv+kO4Vx5tFgk5zEwlgn
FKtIXtmF97ndHQVwn5N0J4X8Gckr2/Y2EgxLNUxc3ysKGjQEPyWvAC2Plxfpdre3vCj6o3Hun7mN
PxjrArscq876/ufP1L9D/jkO+vfWcGq3nfTtGNqE9Rd4nkm6uO0qXbK2cf+t43SbX4cESeN9Xk0S
C2NioXR/E5bf+hfvVHEAveE40f/EqW27JsGwrLHLZf8eFfihRdO+DXxQFECjfeCyHy9L4nua4XSW
VQS3ge1aP2XCibI/NuykX0Z8uGaTvo7/iBgGPLftLOLq8KcYEiRX6RJ+JoqEl5LEwpgmFRZK9+so
t9dZCCRB22a5PU6U/Q34f0u3OGw31DgNY5djRUGj4/fzfuxoQQBozTTdAtBfEcN4zVh3CG4vIrgN
bHacOo9TJl5quO54GckstTlLF2MydoGnm8S1ya8Z77/P7Wlfxvs8myQW9m0auzfH5m0/0XNfNbQ5
MF/GjowxOU6XKDFXFKNqJ8YutGw4PdH1WUBrppG8smkH6RYELuNkFuB1JK9s1noyi4015beNZZwg
C88xieSVTTLe50UksbDPwZPdm+N1kO5aJ6eyQDvsyBi3D3EqyxhM+3pw+gqtmqULgGoDQEsmkbyy
besns8wVB/CCMeqw0cAi/Wa9SbexZhEx4hKd9O9W4xd4mmHdUvLKdsf7Z4qDp5DEwr4GT5exe7ME
TmWBNgbny9iRUQKnsuzXWbrFK+2EVi3ipC6gvXHyEMS2+LMb68FtcQjgR6a5PU3WJsntehcLj6WZ
pYshmb/B05z1/Zx1y+2P939Lt0Y8VRw8RhILuzbvB08mFmW9VP5MF7yTcQ91OY0dGSX6kC5QN1EU
O7PoJ1jQosN0p/PZhQS0OE4WxN6Po3RxCGNe4KHx6XDCt3jG7lh4LMd5uuQu4MeGTfc2eO7Wcf8e
X8S6Iw+QxMIuJxfLdAtvlOl93IMKtU1o/zQ4L9abfoJlh+r2LWLxHmP4t4oCaMSk7/eMk8cz5v1v
nEQI3JJkuH/DwqMNj+O00D7gSQ5zu+nelcn7M5z0JcbNdySxsAtDJqPM+DomKUsvFCh+gL40oa3C
cFLWXFFsra04fQLvC8EcoB1iF+P1oa8bm2rA/EyS4Xi8j1NZxmYRMQx4zrjfpvtxGGLcF5EcyRpJ
LGzbLF3w2/VB9b1Q5ooCih2gC8zX5YNB/lYs4vQJ2iWBBWh1rmthdLyO0+2UnSsKaM403S5t87Px
OUp3Kou+ef8WkcACTzHvx5TWLMfnbf++nyoKEkksbP9l8EcEgWr1Ie6rg5KcRlJh7YP8Zboj8Hm9
RQRIaZcEFgDG7INxLzTlPF2ShPjy+PvmS33z3iwigQV+ZBKnr5TgILdX1tE4SSxsc+DkZVC/d+mC
RxJZYNxmceRuC47jmPVNjWEEf2jZRSSwADBub/pxr6uOoV6HfTt3FXI5jvXNezGPGAb8yGnfP4l1
lGO4ss7aY8MksbCNCcbSwKm5CcoyFk1hrM7TnYpFGw76PlnQ6GVmxjA0bhFXzgFQzrj3z9ilCTU6
SXedgMXGcvvmuaLYiVlsJIYfmcfmzlId9+MBa4+NksTCJg0JLALfbb5Mll4mMDqL2LXUoiFoNFMU
zzKNhC/aNoskLgDK8z5OiIXaxqT/icXG0rmGfvtOIpETHnMYN0bU4KAfF8wURXsksbDJF8IyMuRb
f5ksI5EFxmIRi5Gt+8MA/1njmAvFQMNOIokLgHIN1wuJR0DZnCRbF9fQb88Qw5DsBQ+3kWXExmvy
RyTuNUcSC5t8IUhgQSILjMPCIJ21Af5MMfyQ4A+tj+UlcQFQuqN08YipooAiLeIk2RodR5LhttrL
kWKAe530/Y71yvq87/s/GiGJhdeSwMJdEllg/xNZCSysk8jyuLO4CpG2zSMACkAdDpL8ZewLRTlM
t9gojlGvIclQrHgzTpO8VQxwr5O+vxHjqNe7ftzglK8GSGLhtZOMZSSw8D2JLLAfiwj8cD+JLPeb
pFvAh1ZNY8crAMa+wH6ILbdDrHhzbWahGOBeQwKLk5brdxzX1TVBEgsmGZicQB0WkcDC4wTz7283
Jre0PJ5fKAYAKh77nisGGPVYdBmx5ZaIFb/eImIYcJ/TSGBpjUSWBkhi4aXOTTJ4xuRkoihgq2aR
wMLT/NFP7OjajWuEaNlZHLELQN3eR8ImjJEElnZJZHm5aVwjBA+1jT8jgaVFElkqJ4mFl1jEYinP
m5xceJHA1szSJSbAc97jrQeMDuMaIdo2SZfEAgC1exeJLDC2udgyElhaJpHlZbzL4Hsn6daeaJdE
lopJYuG5ZpHAwstfJMDmB+qOyOa5nJLlBAqYxy4lANrxzrwJRkECCwOJLM8zixgG3HUSVwjRkchS
KUksPMc0dvvzuhfJQjHAxhwaqPMKLZ+SdRgnUNC2SSSlA9Ce9+kWAYH9WUQCC7cksjzdXBHANySw
cNdxJK1XRxILTzWJY7l4vXexcAibYqDOJgb3iwZ/95m2Q+PmigCARv0RiSywL4skbxUDdxz0z4bd
8w+bxSkssO6w7zfE9rjLNaKVkcTCU18KF14KbMhv6U71AV5uEbuX2Iy3aWtB2ykstG4Sp7AA0LY/
IiYBu3ZmDMojXEP/uLkigG8sIy7Ow2ykr4gkFp7i3EuBDbtIt4gCPN8sgj9s1oe0E8ifRVIubZsr
AgDIRVxfAbtymm5DGzzGNfT3m8UpLLBuEWuV/Nhv/fiDwkli4SkDJYulbNpBXE8FL3ESdzuyHa0k
F8rEp2WHJvEAkOQ2JuH6Ctiuk0hM4Onsnv/eTBHA/zjVi+dYRNJ68SSx8JhJLJayPcexGxheMvhy
igTbMNxDXbPT2MFE22beIQDwP0exuQa26TBiGDyfa+hvnSR5oxgg6fsFp3rxHEOsW9J6wSSx8JgL
Ew22rKUrLOC1XO3Gtr1J3cmFTqCgdTNFAADfjX9t3oLtEMPgpZyU1XEqDXQOI/GYlzk21i+bJBYe
MjfRYEcWJibwQ9Mk7xUDO/AhdR61eBhHjtK2E2N7ALjX+0h2hk2bmX/xCq6h73g3Qcdme17jXWzq
KtY/FAH3OEm3iMXDrpKs+r9erf313XIckjMmcYXBQ47SJU3JLof7DUfw8rCbJJf9X1+v/fW6Sf8Z
ytRi7sMWqS+RRfCH1pmwA8CPx78rRQGvNoldz7zem3Sx4lafpdNYtIf0/YBrtXit83TrBZeKoiyS
WHho8k7naq1zW+bhhJWnOukncyf9Z2pAmqTb+XTRlzHwrXkkwQ1u1vrjy74/fs3gc9J/pmt/Kusu
wWeeuq4WksRC67QBAHjYsOv/RFHAq9kxz6b8ltv4j/kbtOckdV97zm7H+gtj/fJIYuGus7S9O/2m
HxwPCRWrDf/3h4SYizsv42n/edtw2Q8vkWvNEL7pH1q/RujzWp+86cDFqv8s1/7ZZK1Pbnnny4e+
3GsJFr0NtGsSCXrUYdPvpJNYZANu1ZjIDbs2j1NPH7N+srcxydMs0uai41TVQxb6xkd9yfdraU4f
N9avyt++fv36deTf8adKyvoy41+cn/Tfs8UXw8d0i3X7vm/zMN2i6WnaXHD7taKXyDTJXyP/jr+k
ngXqEvrYl/6uFgeen/uJysUI6nXok981Wg9T/fGo3MTRl/dZxEmCjzlLt4tRG6i7jpXL8y0z7qOp
f8p+T6r86hGhUf9qpE8dexuvKV6xShtXVU2S/Lfx/mP99NjVnc9TDdfTr5/m3fpVGjXFi5/6DPyn
ot/z2dDiO4uIYfzIPN0Gu5Z9ybcnkV+/YH44XftzeKe0nuTSyli/CiUksfxNNe3MRdpKnLjqX4Zj
WCR9aPI3SxeUbimx6J+VTO6nGf+i6b4D4zyupkXHp7hJdz/lYqR9wJBkOE9bJxr8XMHEuvSJ76ds
74Q4jPFL8HGtDTixj01aRhLLj+Yzm/SXR27jY+chqL3q+8enBGOHxdFJbgPZdrh+60va2PVfwqbG
pcfRe7WAvngYp257vtb6Sd4tLTqWHg8cNqZtu01Qr0na3Gz/Ze19ssx24x/D++Q07SW1tDLWr4Ik
FtY7rVaCSp/TLWiVMhlubeHUzv/dERQad7tfNTJYHxIKFwV959M+qNBCgO6mnzyWvHC8LLSuPvXP
2UqXyCtdF/o++di/H7QBWn0/1DZWd7LL66wHtS833Dce9vPXYYH0jeJuYte/JBY2aZbkj0Z+601u
T1HYZ1JFa6fG1hIvfopSNyF8jhMiaWOetukx/qJv96s9fYdJ/z6ZpZ2Ell/SbaZl5CSxMGjhyorS
klcemhTOU38yy7+z/6udXmsaSSy83HmS95X/xhKTV+5r5/MGJla/pwtElKq0RbOb/n1/EXi9Sco7
1v0mXQDFGIVtW0YSi/fxuA2nse369NghqWVYIG31pJbad/1LYmGTfcaqgb5iOF1iMcLyn/Vz9trj
xTWcFPsUqwLr0oIwm3Ka5M8GfufHvs2Mbaw56d8ns8rf6zVs2mzC3xUBqT/D7qqf/E4rmAAv0u2O
+rXvaGtl0EvLJqk7geWmn9xOUn7wYdm/W37u3zW1et/XV4lOCmwf00hgod028KXvb5aqDmjUVT9W
/r90QfxFdh9cve7HIrN0i6M/p1u8bY24BDzNPHUvdH1Ml9Q2zThjGNd9fzVJtymw5v76vH8v1eww
ZSWw3PTtwzsT46+ntZdf+3H+LONMll6lS2KZpO41yAP9VhkksTBMNmp9KQwLpcuKftd1X2cnFU9M
jvoXOeiT6/J73yfXNkhc9H3y757L0SlpAX9IYHH0Lq23ATthgBZ9Trf5Zhgrj6kvXPT98z/78e5N
I3XyJuIS8COT1LsJ53Pf780KmqNd9P31T6lzo81B6r/qrbRNCNOIYbA589R5otSQvDLpf2MJMY9h
DXKSepNZ3qWda+qKJYmFWl8Mn/tBX83ZdKu+k/13pS+RFrLr4b7JX433GQ8nYp2l3sXJ6/73/Svd
SQI1DuxPCvzek4K+q7uj2YaS2u00EliA9nxKt0g6zfg336zSxs7MdfOIS8Bjaoy7rp/ovSr0Nyz7
vvqXCvvqkk+KfeqcqBS/RAyDzTlM2VeZPzbWP0k5ySt3rSezfKx0rM+ISWLxYqjxxfBL4RON57pI
naeyHFT6fEJrA6ePfR+1bKQOL/t3UI2nspQYoCxlAX+4Xx22Md4vwa8RAAXaMpy8cpryYhd3d2bW
7CjiEvCQaZK3lf2mYaf8sqI5/CTdImpN5prfKMYxruJgk85S19V0VwWP9R8a/89S30lfb/o6YqQk
sXgx1PZiaPUOxlXqXDQ9i11PtGPaD5xqcZPk536A29rO+uFUlp9T166nNynvmMVS3iEzXSBbbLcl
vC8EQIFWXKU7TXWa8hdJh2SWf6a+BdJ14hJwv3lFv+VLupjyvMJ6uk63QFfTSd41XwEx1f5p0CTJ
h4p+T80bOpf9b6tpHVI8asQksbStpt0kw/VBl+q0qkVTp7GgTy7TVT/xXjRep4u+HK48p3udCJcw
hlnpAmnYRVwjBLTh93Rxi4vKftcq9S2QrjuI4DbcNU09m3A+9r+n9pjycJJ3LdcfzzXDvfmSdk5b
Rnt+jlY2dA6bN2sZ+x/F5sLRksTSrlnqOYVlmGwIfncWfXncVPSsQu0mqecY3i+RVLjuMnUFit6m
rPunjwp5b8O23i3RBgD2bjhO/Cx1xy0uUue1FUm363/iUYb/mVfyO1o7PXaVenbQl3hS7FOUcCXy
RWBzDvtxVum+pL0NnTUlR841xXGSxGKyUbrfI8nhPpf9S7OGF4hMSPTJ5fgcSYX3ue7L5ZPnlXss
FQFbMtEGAPbuU+o9Tvyhce9p6rtW0xgYbp2k/FNYbtJdH7RotA6Hk7xr+B21KWHTsfkb2vG3hnh4
ixs6V/1v/1j477AGOVKSWNp0mjJ2Jv/Iz3HVzGNqSmSZq04qNkkdGedOxXrcEND/WMFvsRN18xM+
aNVnRQBU7Jd+/Nfi+HiReuIRxsDwrdJjscNu+dZPj12kOyWs5ITD0k6KrcVSEbAhhxW8U8TDu98+
S/kx77kmOT6SWEw2SvVzHD3+1BfINOUHjo5S5xGRkNSR5fsxspWfU98fPbc7mwyPnQV8AKjPTbqF
wfPGy6GmjTUDG6lo3SRlb8KRwPKtZcq/kn6uGqFYs5Rx+tBDxMO/r89fCv7+1iBHSBJLe2o48vFj
JLA8x7D7v/SjfAWLqFUNu5gM2J8/qP/iud3JmAcYNwsIQG2GBdKlokjSxSNOUkcS9zCOP1StNKzk
+MXQPzs99vvx+DTlxo2dkrVbNuLgndKRwHK/85R9Xd1cFY6LJBYvBi+HNqxSfma9IyKp0SxlZ5wP
QSCeb5qyE1kOvI8BAO4dG0vQu3/eU0Miy0FssEFbLrl/lsByv9ITWWaqEIpzmu7kixJZo3zcIsnv
hX73N7EGOSqSWNp8OZTKbv/XT0hOC/8N6p/alPxM30QQ6DWG695uPL9bsyqgDO3kpXVOTAJqYYH0
aWNH12pC2c9+iZtwbtLFQ/XPjys5biy5cHfeKAIaH09JYHl6v1zquH+u+sZDEovJRimuYrf/JixT
9r10BgjUZFL45G8aQaDXui783Tb27PRVAWV4rBnQOIlcQA0ksDxvTl96IstRxBrut8MAACAASURB
VCZot/2WZth8s1J9T7JMmddAOCkWyjJJd+p+iWN+SXPPGzeUeAXZacSqRkMSS1tOC//uAkKbcZ7k
U6Hf/Sh27KJPHoNf4pj0TblM2cmFp6rw1bzXaJlELqB0Elieb5ZyYxLrvwFaMkmZm3DOInbxXIuU
mWwoNrE7U0VAg+3VieQvr+urwr7zgXfKeEhiacdhysxuTCyWbsOswJfH+gQUammHJfqULhmOzSk5
uVCf/HpTRcCWrAr5noIDQKlcUfG6udCXgr//2E8khG202dL8ni4hgzb66LeV9MslXDlt/sZrlRhL
NOZ/meu+7G4K+94zVTcOkljaUerg4nMslm7r5THzLMPenKTM3ec3BnFbHRzfFPi9x35C1k0hdQ/b
sDK2A9iqaVxR8VLDtZo3Bf8GYzham6+WxJUPmxmj3xT4nUt3qZyp3Em6WGJJfk133Rov79fmhX1n
CesjIYmlrYFnaSyWbtcy3a6E0hzErnX0yfsyi6zzbZFcuL2J0tgde6/RuHeCA0CBfo4TYzcx/i15
IWymCmlEiQuO2ufrrVJeIpB6340jZU1D7fRLykvAGKPzdAcWlETC3ghIYmlDqVcJnceupm2bp8xr
hbxAKF2Jz/DnJBeqbqsuChzQj/15LiXpyqlzbLPv1gYANutjXFGxKct0u1tLNPYTCaGF+d59fo0k
w01ZpKwYxXHKT44v5dmdp1tzgtrfKTNVttGyvFH3PIckFi+GsbqKDMdduC60nCWxULJJyrxKyMBN
OT9kzIGiy4LK0LiHlr31ngEKcRVXVGzaPN0uV2N3GKeSYnB2zG+nn7vxvO5MKRtxjrQ1XmCSsk72
khS5WavC+o0aEiOLJ4mlDVOBAB6xSHk7/4+8QNAn73zQvlJ1OxvQ/+q53mh5luKD8Q9bsCzou57H
jnZg/GZxvea2yrVENthQu0nK2oQjyXA7c+qSTk0sfU5d0vztfcQwqHfcdBUnxm7DecpKXjfW3zNJ
LF4OY/S5sAFbDeaea/DsPuDGoH0vA/obz/VGrAqse+83Wm0DB/0cQCILMFa/R6xiWy5TZiK3K4Wo
3bSg7/pJH73VeWop19Efp+xrblaFfd8/IpGFpysp3jWPxPVtKSnhdKq69ksSS/1O0gWESzJXbTu3
THmnsXiBUKrSnt1zg/adu055iUNjfa6XhZXjQZI/Ywchm3NZYBtYRjIXMD43EavYtnnKWSQteX4H
z1HSmMwcantKu46+5H55lfI2Nf1hjMQTvSnke35Od3sB27FMOWuRb1XXfkliqV9pgzansOzP3LMN
W1daYqFTWPantNNYDjLeXaifC6z/3/rx0ERT4JUuU14QdEjmWmgDwIicRWL3rsq5NBIvqdm0kO/5
Ma5A3rZFykk0LL1fXhb4nT/EqZrU8T5JJGUp43Kf3epIYvFy0HmxPkAuaaFvzAumUEuf7BSW/XEa
y+ZcFvoMvEny3yQX6YJwh5oFrxjjlehd3wYWsUAI7NdV7MbclYuUl4D8xjiNSpW0CWeuupTzmqn5
297eh//pv//Mu5FC2+WX2GS/q37us2eXH/mHImhiwlGKKy+IvTtPOce6Dc/3pWpDn7w1C1W29z75
g+d7IxOj9wU/B29ze3zll4w3sezuGG7Vf669q0dRNyUfgfqu/5TWBi777zq0BaBcc0Ww8/L+q7Dv
PE2XgAM1KSV+8SXd6X0TVbZ1q3SnPI49uemofx5KHYMvC39O3vSfP9Kt94y1Hi7vzC2HeZsYxvbG
SqW0v6nq2llZv/Hs8pi/ff369evYv6NqerFJut2Lpfg5FkzHMiE5KuS7fkyX2T3Wl9vYA28/ReKY
9lVm+2rJIreLt2N3lfEGDr96lPbuS98HXvbvHu+f3TlJtyOO/beBy7U2IDC6f8uMO2hW21i91Hfx
mMc32ud4/J7yrkIae5sUrzAXhdf4d8pOLrxOWdeR1zp/W0UMo/W5ACTyFPbGdUJ1K2nH/03sWhnT
JNUzDpt3mHISWErrC/TJ43CU8R4X+8mjtHfH6U4D+ZAuyfNruiDQWSwObttlyrm/vvY28C7Jb+mS
iq77+c9MG4BRmysC5f4EYhPUyHON53d/rJOMY/72UAxD/+h9gmeYHZDEomGNaWB2rcpGYVHYYBL0
yZvnerfxWKasxeexPucCQOP0Jt2C/n/TJVpIaNEGWnKQLij6x5024O54GNeYeKEYjIGfOKaB2oi5
UbKp+Rtbet8PmxJW6a4BnyiWH5IAgHcKLyKJxcvBwIy7VumOy/Ocgz4Z9VHLc+6ZHr/j3Ca0LJOc
KpKNWiiCYtrA/+vra6pIQN/ZuHlh31e/TU08z5SuhpNYblTjqB0leZ/bGMZMkTxoogjwDPMSklg0
rDFwldD4LDzn0PSzulBd6qOy5/w6rhQqyZskf6ZLrJ3FyRSb4EqhsrxLd2T1ZQRDwRisXaUt4Nlg
Q00mioDCHVTwHFsvKcebdCdsrtIl4YphfGuqCCiccf6eSGKpWynHPi5VlTrxAsFgZzRu0i2aMR6X
KSeAP+bnfOFRKs5Rvg0E8TrniqDI+dzQBmaKA3bqU9/22J/rOJEQ9mWiCPAcm7/xbEdJPkQyi3cK
tTHO3xNJLF4MY7BUXaNT0m5dLxAMdvTJLVh6zl/tIk6iKNVBbgNBM8XxYgtFUKwhoesydrHBLscN
qIfnmKguKmK8QQ1KjxlfJvmiGoskhvH9fBZKb9OS0vZAEku9Spo8L1XXaAfKJfDyoKTBjj6Z2utl
7M/5wqNUtGEhfxlJrC9xneSjYijacbprhi5isRS2TRLLeOqhlBMJ36guKiLWhud4HJzGUraD2Iwg
doNnmReTxKJB7ZtrK8Zr6VmHJp/TpepSLxU/7+cpZyGEh71J8p+4YugllFkd3vZzqDNFAVvxOV3i
H8bBz2Xhn1ocKwIqMK3gNywihlFLn/pXuphUa2MFYyNqMVEEuyeJxcth3ySwqJvXOlBV6JP1y/pk
z/sTXcdOppp86NuGhNanW6VbnKV8B0l+S7e4KzAIm+UUFvXxUsYkAGyaGEY93qe9k2WNjajFRBHs
niSWepUSyFyqKnXjBYI+eTQsbKqfFp53p7HU5bgfs8wUxZPNFUFV3qRLTpoqCmhyLqw+xmWiuqiA
MQU1jZNrIIZRl+N0J8u2cqqmDRfAi0liqVcpGY4rVTVqV4V8z4mqQp+sT27AyvO+EU5jqc9wz7R6
fZplkk+Kobo28FdcLwSb4mTC8Y2BS1m8m6guADZMDKNOv6W7Lqp2klioxVQR7J4kFvZtpQjUD6DN
o352zE6mOr1Pd+WAIMmPSXaoUyuBUNgmJxOO07KQ72kMAqBf3oZ5ytlsytO9S5c8XfP4wXVCwItJ
YmHfVopg1ErZgSZQBPpkfTLPcR2L+LV6m26hy9jgx/3974qhSu8ikQWMt9TLvliooQZTRUBFauqX
xTDqNFyRLIYBcIcklnqVcufjSlWN2rUJCWzEVJ9MQ31yKc/7IskXj1WVBIGeZh4nEtWqhR19sC2S
WNQLAIzRRZwYV6vjfqxjjQPG640i2D1JLAAAtGqmCKolkeXHrrWBJtoA8DwrRTDad1YJJqoKgC2a
xUaEWh2lzhiGmAzwYpJY2Ce7n8dvqQigKXY46pNbfOZ/VQzVksjyYxdJPimGqtvAQjHAs6wUgXHw
KxypKgC2PE6ZK4ZqHaS+GMaxagVeShIL+3StCAD0y7Bn80isrdlxukQNHjZLcqUYqvUuElngOVaK
AAAYsfO4VqhmNuMA9CSxAADQutM4krdmb2IR/zGuFarfuyRnigEonKRjAJ7rpNLfJYZRNydqAkQS
CwAArGIRv3bv1PGjlkl+UQxV+y3JVDHAo+xqHjenRgLwXLWeZnFtbF+9t3F1FNA4SSwAANBdOfOr
YqjaH6l3J94mnCf5qBiq7+cmigFgqxz/D8AuXCb5WTFU7UMkKwENk8QCAACdeSzi1+4iFpcecxbX
NdTsII6lBtg2CbMA7MoiYhi1E8MAmiWJBQAAbs3iOoGaHaU7cYT7DcdSS2Sp15s4lhqA8ZsqAoAn
mUUiS80O0iWyADRHEgv79EYRjJ4dRNCWqSLQJ5MkOY1F/Jq96+uY+0lkqd8H7xQAAKjGLBJZavYm
3ampAE2RxAI8xlF1L7cq4DtOVRPok7mXRfz6nWtT2oA2AAAAVGIWiSw1m6fMGIaTjoEXk8RSr5tC
vqfFAzbheoTfaaVaGPkzqk+m9ef9Kb9jGov4tTqKnUzaQNvepAt0A8AYTRQBwLPNIpGlVgexEQFo
jCSWel0W8j0dYz1uU8971SaKwDOqT9Yn65Mfdd23C0GgOn3wLnxSG5hGIkutnEgEwFgZo+13/AeU
axYxjFq9i5PVgYZIYmHfBE3VD/szUQRo8+qHJ5kl+V0xVMlOph8bElkEQutzECcSAbRq7Mf7T1TR
3tgoBuWbJflFMVRprgiAVkhiYd/s+h+3Y0XwKl+0P/TJqJ9qnCX5OeVc2cjTvI2dTE9xHYHQmvu2
iWIAYGSOFAHAq5wn+XfEMGrzJmXFMJaqDHgpSSz1KuXlYEFO3dT8vI/9CNaDWLTQJ+uT9csmqM+x
iKtVajRXBE92nuSnCITW5EAbAGhSCVfGTFXTXqwUAVTjIl0sSQyjLgtFALRAEgv7ZsFU3dTsWj1T
GIlN4zXp64f9u0wXUHe9UD1K28m0b8u+T/qkKKrxLq6sA2hxTDt24hX7sVIEUF2bPknyq6KoxlHK
iWF4pwAvJonFZHQML9yJ6hqlUoIFV9rhq0w96p4Fz4R6afh5f6nrdFdw/DTy9xBPN1MEz24Dp3E8
dU3OFAEAIyOJZX+M76A+8yT/ilNZaqrPEqxUFfBSkljqdV3Qd52qLvVS6UDI8bzok9mUE8/7KC1j
R1Mt3kVi9Utc9OX2UVEU7yxOYwFoydLclEdcKgKotm0PMQzJamV7kzJihStVBbyUJBaTDZNS7jNJ
cuxZb6IdHseCxa6UstNBnzxOp57z0bpOtwPmn7GQXzonUby8DczS7er7rDiKdVDQuwaAzby/x+4o
TmPZl5UigKrNYzNCDUqIYXifAC8micVkdAymqkudVPqslzJIs2DhWV3nmrfxmfT14jkf/8R8lm4h
/5PHtkgzRfAql/0Y8qdIZimVRC6Att7bJZiqqr3NbYC6DZsRbMgp17uUsTnVFVbAi0hiqVspwWM7
K8anpKSGpUl/U/VdsqU+AH1yEy77OhMIKo+TKDbXD0zTJbNI6CrLcSSyArSkhEWlmWra25wGaMMq
3yazuGaoLKeFPGMAzyaJpf4BSClMSsfjMMlbz/nGlJBM9jauFPKs6pPVh4npNspiluT/0t03faVI
tLfGLHOb0PV7BENL4TQWAHPUMZFguR+SWKDNd8Ks73N/iRiG+Zt3CrBnklhMOMbCrld18RI3GX/Q
pZR2OPPoexbWCBSOx6SvD895ua5ze9/0v9LtbBIMGi+JnZu3ShdYO0zy79jdZy4AgLH780iw3M/4
DWjTdZLz3MYwfo8YxpiVEMNdqiYYlcN0JyifpotZP/Q56//v9naTyj/UlcnoSBz1jcELbf9mnvEm
2+FZP0HBs7DeF8xVmz658ud8H+Uz1OlJP1mYJnmjaEZlmuRCMWzFxVrZTtc+2sC45mUn+nOAJiyT
fChkTiSRZfc+G6NB8y77/vesnyOsz+EOFM9oTJMsRv4cAfvtI4bPySv67y99e16mi+1db/uLS2Kp
fzJaklkksezbpLAJagnPSymDNIlku1FSEGgWSSxjqYeSnm+e935Yf0ec9J9J3x8fpqxTeGpyGkks
uxrHLe9Mqoc2cKIN7NU0Ao0ArYxHS3DQz4sWqmznz0cJ8YurODmGh3k2NtsnXOZ2E+TdGEYi8W1f
Tkf+jrzu++qjAsryS3awMA876heGz6aSDo/7z7skf/TtZdF/ttJuJLHU70vKCf6+S7dganC5P6Xt
bCnlJJablJGdPl+bdLC956GUCd1RLOSOYbB5VNjzzevK76EynGT8x8Me5jaINU3Zu7K8C/djmYeT
abWB3b9/nNAHUL/rlBM3nEcSyz7GZu+N34EHPBbDGOZGJfQdk/7Po4LrYlrI81JCGV/Epk7KdZhu
jXe2o/Z2nOS3/vMxW1jf/9vXr1+/jrzQ/+a5e5XzgiYcSfKrl8ReO7hVygq4/1/KyIy9SPK2kDL9
KU5j2abTJH8W9H0/RzBon5YpaxfLvyPpiW/7u7OUuxPrn5FYzevM+k+pbaCFefjY37O1jcu/FvAd
jX212Rbbbklxw58jkWWXDpP8P88G0IhpP397V+j3H/v44yzdQvfY3aRLbHIaC6WN2Yar3/a9vrvR
ZJa/q9smJvklOesbHPsp+5ISWK4KGkyU1A7nmoJnYc2bCOTvc/Jc2sKnk1hYd9E/xz/17+wS2yC8
xqJ/jn5OFwjTBgAwR32dueraqeGkHs8G0Mr7cJYuhvGlwO9/UkD5luAg5d1WQNtO08XkP2Qc67vv
kvx3U2MzSSwmo14SJLeZep5t31XSwnaVFAQazFWbcn+CL3FqBQ+/A0/S7W4vyYmqY0MW/fP0RRsA
YKRjtVIcpVtgxPPh2QC22e9N050mUJLpyL/fZcrZ3HGW8V9nDIfpNhD+mXFe1fWhb/evii1JYqlf
iQumTmPZT5kfFPadS7qy4jJl7UI/1yS2PhkqicSm/Uw833iuqWw8Ok1ZQSAL+GzSqm8DX7QBAEY4
Tivp/TSPmKF53v3OPRvABt+Ns5QVw5gU8B1LWc85iE2djNs0XZzp7ci/53FuT7l6EUksJhxeEpR4
CkuJz3VJ3/c4TkTyLHxLn6y8a3yu2b2zlLNIYgGfTRuSuUrZfTZRZQDNWBT0XY8iXrFLJW0gE08G
Nm2Wck6VPS7gOy4Lqvt3samTcTpL8lfKOZTgIMkfL51vSGIx4Rir9xE43ZXzlHcKy+d0CwHa4fbM
tUHPwpo3cTTvLifIbzzXVOo63V2tJSziH8ROTrbXBkp59wPQhmVh3/dDJBzv0qeCvut7zwawYaXE
MFJA/1da7NBp9YzNIslvhX73d3lBIosklnYmozeFNki2a9p3HqW5KPQ7l9QOD7TBrfpU4Hd2NO/2
HRY6Qfqk6niGVUHPuQA425qbfVQMAIxIaVcgJ+IVu3Th2QAadp1yTnk6LKAsS4ohHscJX4xrfPOu
8N/w7EQWSSwmHGP2Jo4I3fagZuF59r21Qc/CE0ls2s1g9MDzTAPOU2aCNWzKvJDvOVVVAOaoI2Vh
ybPx2LNh9zywSaXEMErYiFPaO8Xpb4zBIuUnsAyelcgiicWEY+zmcaXJNsv2qMDv/SXdLm7tcDd+
M1DzLKx5m3KuQSjNaV++nmdacF3IczNVVWzJqh/TAsBYlLjo/8F4bWdj99JO33wfsQtgsxYFfMcS
TtAu9YR9p5Ozz77nXWW/6cmJLJJY2lHaVSaDg1gc24bTfkJnwLj7dnhV6Pc2UNusEoNA621wogo3
alJw3/apf57hJe8W0AYAYBxWKTPB8sL81LjlAQvPBmD+NjolxsSP4nRy9uM89SWwDN7lCbdASGLx
oi2BYyA3a5LyE0FKVmLZHxmoe5bXSC7czrNw4DmmMZeKgMYtC/iOU9UE0JQSY2/D/NTGm+3P+248
G4D5m/nbht4ppXkba5Ts1izlHkTwVL/9qN+SxGIyWor3faPldQ5T9mLpp5R7ldBgUej3fhMZx9t4
Fm4K/e7HnoeNPgfHhX73G88Br7BSBDROIhcAY1PqKc7mp9tXynWgng1gm64UwUYsCh1vWKNkVyZp
J2nq0YRjSSxtuSz8RftHkhPV+CrnKXexNKljx/8q5V4j8y4yjj3T3z4PBu6vM0vZRwI6hYXXGvuR
9cadbJOr2AAY47up1DH+20hW2LZzzwbQuJUi2JhS++U/Ih7O9pV8EMFzHTzWH0hiMeEozTIWFF4z
MCh5sbSmHf9ORaKWPtnA/eVmffl5fmnZ2BfxHT0OALRmXvB3fxfJCtt0mfEnoXs2AMpwXvh3t0bJ
Nsfix4395rd54FohSSztWaTc6yuSLitr6SXxonp/V8FvqMUy5Z+KdKZZbcRlks+F/waJLM83S/kJ
LJ/jKgwAAKjNqvA5qmSF7Tr3bADQ+HjDGiXbMkm76273jtEksbSn5KNBvSRe3vjfVfA7atvxPy/8
+/9m8r/dF3RhJLI83SzlJ7BE+2dD3oz8+y1VEVvkpB8Axmpe+PeXrLDdeeCNZwMwh6Px8cawRjlT
jWy4TRw0+tuP7mtPkli8HEp/SUhk+fHksoYElo+p787JRco+jWV98m8A71lIJLI8xSx1JLBcReCP
15soAhpnHgPAWC1T7rUxg3f97xCv2LzzCp6NC88G8ALHimDj442SY+IHEQ9ncyapYy33NeZ3/4Ek
ljatknyq4HcMiSxTVfqdw9STwJLUu1g6r+A3CAx5xte5auphZ6kjgaXmPpndMn5DGxg/18YBtKuG
03DfxAa4bT0bN4X/hrcRywLqm78tCyzXeQXPxh+p7xYBtIV9+O40FkksJqOlO0jyV2Q7rjvsByy1
JLB8Tr3H+S9Sxwkcx+mS46aa36v65JtKfourpu5v679V8ltuTMzYEO8MWndawHe8Vk0ATc9haolX
LAt575biupI5oVgWUNv8zXhjf95HciQvN4lTWAbfbJCWxNKuZbrkgFr8EdeaJN3uklXqOtpuXnmd
1fL7hoSyeXiJWoJAg3fpdm9PGq/Xw74cahqEnseiJptpGyW0C6dQsC3TOIoagPGr5ZTNgyR/9nMZ
i0ubmxfeVPJsiGUBP3IYm6i3qZY++E0kR/Iy+pdbx1k7RVESi5dDTYZrTU4ars//9BOwWtR8Cstg
kTqyjQcf0i36Oa73+Wo6jWUYcFym3Z0Kp6kvqdApLLQ2BpWwRettQCIXQNsuUtcGuPf9u22qajcy
Tq5pbvghXfxxomqBe5yljDWXZaHlu0g96yOSI3mJmSK4vzwksbRtWdlkNLk9JvSsoXqc9L/5Q4W/
rZWX/bzCdvif/nfZ5fR0tQWBhoF7azveDvvf+2fqSipMnMLCZpykW0AowUp1sQWn6XZolTI2AaBt
88p+z1G6xSWnsmxmfljTRpw36ZKczlQtsGaiXzDeeIFho+9U1fIDJ/34lFv/2xQtiYV5hb/pIMlv
aeNUlrP+Zfimwt/Wwiksg0XqSyhbH6zNdLVPVlsQaDDseKv9VJZp/zvfV/jbnMLCJhz277xSrFQZ
GzYpqA18Vl0ApIvLfKp0jrqKeMVr1LoR57dYeARuXaScTWrLgst5keRLZc/OcbrE2UUkzvKwU0Xw
naP0p+NJYmGZegOUb1LvaRAnfd39lvp2+g/mjbXFWn/vUZI/+udVAODHagwCrT8Lf/aTv0llv21Y
mP8r9WZOO4WFTbhIOVdsWcBnG++KkgKgK1UGQK/WHegH6eIVq0hmec088arC3zUsPNYYvwCebpFy
Yhg19MW1jjfe9WONeSSz8L2pIrjXaSKJhbpfDoMPFU1IJ/3g6T+p8/SVwce0cwrLYNn/7lq96QMA
Sy/mH6o1CDR4m+S/lQzcD/vfseonJLW6irtceX1bWRY2dlmpNrbQBo61AQAKtErya8W/b9h8s4pk
lue6rnyuOMQvFhHLgtbmb4uUFeu7rKDcl6nz9LekS5wd1innkSDJrTeK4F4niSQWbl9wHyv/jaXv
rhgWSi9T90LpYN5oWzxLnVfJ3H0p/7XWFmUff++6kTawPnAv8TmY9X3yh9R7IlbrfTKbm3QsC5yU
Xao6NmTav++OC/veS1UHwJraN1sk3yaznEW84qkWqf8Uw3fpYlnDldmeDajXEMMobQ2mlhhG7esj
QzLLkCDpKpm2TRXBo32xJBaaeTmUOiGd9IGCVdpYKE263T2rRtthK8kLd9uiAdv3FmnjKov1LPTz
jD8Lff3klT9S79VB6z73zyO8ZAyzSHd63HGB33+pCnmlaf8c/VXoGF4bAGDdddo5peQo3fXd/y/d
dTLiFT921sjvPO5jAcOzMYuEFqjFJGIYY7BKFyNuwbskf6795qlm2GS/w8Njrvzt69evX0f+Rf+m
rnY64fitsd980086zjOubNVpPxF611h9XKXLsLtuvC0u0+YxYkN7XPafVePPwUk/cWrNp37SeDGy
AeVZ3y8fNFYf/4oTKXj62GX48zRlBn3W30eC0TzHYf/ePlxrAyUnOn5OGwG0sY+5f0pdyURfPfs0
Mk+ure3edZHuipXW3PT1OsQsVprkd+bpNqm06EtuY1mXng8ownTtz9JjGEl966irtLF58KHxxvA+
WWqqxk4N+5ckFu66rOCF/ZoJx6KfkO5jsnGSboG09KD3a/w741q43pdJ3xYPGi+Hq74chgHbddpb
TD9P8r7h+r/o++XLPbXD075fbvW9+HvK3lE3TXcCAjzXp9Sx41Yb4KV+TRunAy4jiWWXJLFQe5ut
te3edZguZtZ6vGJYZBpiFtex0HTYl8VRuMltMssqbcazXmoos1Z99QhgDJtEPGPdVd8vDuON1vvJ
5xjKbKxaTQ5/8rxKEgteDg+/GC6y3Qz6SV/ew6f1SV4tC0ab0uLJSM/x5c4A5KzSgIAAYefmTp98
uaWyXu+Tjxsv8xpOxjKm4aV+Th3XaGkDvFQrp3AtI4lllySxUHubrbXt3uc03fH3PDyXWq39/SLt
XNFq/MlrtZJMXfJ4ifH5JXVewdPy5k7aGJeXMr/Zl3//QxlwT6P53cshR30ZvL8zAV3m2+z5p3SA
0/7P9SPGT2JRet1N2rlb+TmDtGlkYj7kboJBrdc+DPeOtx4gPEh3vdq7tT7jvh1vT8muHvriSf85
6T92i31rFle70S6nwtH6uNxOYQB+NFYSO3zY0Z355bKh375Ml4TgaHyA3b6XazSPjYbQshNJLDz0
cmj5SpvHJqCy4rZjFoulD5XLKhKeTES6k4okNN066PvjoU8WINus3+MoVXCo7gAAIABJREFUbNr1
yZgE4w4A+KF5LCzx8LNx6tkA2IkvqfdqmWFz539UM7Tp74qAR14OsAufIlj+WFt0xRLp++QbxcAO
XKXto3vBmITWnSsCAJ7g2jyVR3g2AHZjUfnvu0x3XRLU6EQRPE4SCw9ZptuJDdvkGqGntUUDNSQX
siuzOIWCtsclklho2VVcJQTA010mOVMMeDYA9qaFGMZ5uo3QUBs3MPyAJBYec5buODLYltNYLDVQ
4zmTEsmFbNOvcY0Q+lnjElofcwLAcyzMU3nk2fioGAC25lPqvUrorlm6TRdQk8+K4HGSWPiR0zj+
ke2wWPr8gZqkMuaeA7Y4aJ4rBhpnAZ/WLRQBAC9wFkF47jeLGAbAtrQUw7iOtUpojiQWfmQVxz+y
eRZLDdR4+XMw8xywYTd9/wKtj01co0LLPsZJRAC83GkkK3C/acQwADbtKu1tEHZVHTTW5iWx8BSL
OBqUzQ6wLJa+zMrkn37APlMMbNA0Fi5hrgjQBgDgxWy44LFnY+rZADB/24BFuhP+oQYrRfD4GFIS
C091FjsqeL1ht7/F0peTwECSXERyIZvxc5w+AS3uYIJ1HyN4AsDrXUayAg8/GzPFALARV2n7Kth5
P4eF0q0UwePlI4mF55j2L0h4qbNYLN2Ei3QLz2hPnxQDr/Cx8UkvDGaKgMbNFQEAGyJZgYeIZQGY
v23KLOLi1DFu5mGSWHiW63SnaNhRwUv8Eoulm7Qw+acfsDsli5f4FMFlSJLPcQoLbXMKCwCbJlmB
hyw8GwCv0vopLOtmERenbCtF8KDPSSKJhee6TJfIAs/xMcm5YjD5Z+PcLc1LfIkEFhjMFQENu9EG
ANiSRcQrePjZcA0EwMvMFMH/DHFxiSyU6jLWdR4rG0ksvMjSRJRn+GhwtfXJv/ZowD414OGJvvTP
y7WigHyMU1ho23ns/AFgexYRr+B+s0hkAXguJ8l+TyILpdOmHykXSSyYiLJNdvtrj+zGZSSy8GM3
6U5Tk8ACXXs4Uww0Pk6fKwYAtmwR8QruN4tEFoCnuol1lodIZKFkF4rgXstEEguvn4j+rhh4wLDb
n921R4Ghtl2azPCDye40dtzDYBYJXbRNEhcAu7JIF6+w6YL7xuS/KAaAH5pHTO8xElkolSSW733q
27QkFl7tLLLm+Z7rKvZjEYEhgx7JTHxvSGC5VBTwv8mQSSIt+zWOrAVgtxZxeij3O484BsBjPvd9
JY8bElk+KQoKe249s9/6X8xWEgubMItEFm5JYNmvRQSGPAMCQNySwALft4mZYqDxsfpcMQCwB8M1
uFeKgjsWsSkL4D5iGM9zne4qdeuVlDYO4rbPk8TCxs28GEiXFTyNBJZ9u0xyEsfntT7wkcjCVSSw
wF2nxik0Hgw4VQwA7JF4BQ9ZxKYsgLtmcY3QS8vNdXWU4iKSvNfL4n9xW0ksbPrFYNG0XR8jgWVM
VnF8XusWSf4VAaBWfUkXHJbAArd+iStUMF9bKQYA9uy6n6vYDMddkpwAbv0eVyG/xnmSf0dsnDLM
FcH35SCJhU1bRCJLiz7GsXZjNByf96uiaNZwXLPBeltc6wb3j1XcIU3LfokAKADjMosYIt9b9fNZ
SU5Ayz4lOVMMr3bRv1MkRzJ2iziN5WPubLySxMK2GpsMx3b8EgksYzdP8pM22axLg/XmBnsnkcAC
6z4bq+DdIIkLgFFapDtB1BHqrLuOqyCAdn2JGMYmDbFxJ9Yzdq0nrs3v/gNJLGzLkOFo0bxeN+mS
lQTEy7BMMkm3kEe7g3WJLHX71SQXvvMl3alk0ConJgJQwnz1JBaX+N55uiQnsQygFU5X3o7hxPpf
Ys2S8bpoeDz8a+65/loSC9uehE5MNKp01Q+mHEle3mBtarDWdP27d7xON+mO4Z4rCviG4A+tk8AC
QEnz1dM42ZnvDZtyflcUQOXEMLbvPDZ6Mm5nDY6Fr/LAuoYkFnYxCbVoWpfPfZ1eKoriB2tOZWnT
LO4dr3GCu1AUcG/bEPyhVRJYACjRRbqYk3gF667TLer8FFdPAXUSw9id4QS4XxUFI7RKe7GcB0/Q
lsTCrszSLZraTVG2Xw2mqhqsTeNUllYt4t7xGnzq27GkQviW4A+tk8ACQMlW/VhOHJG7lukWHp3K
AtREDGM/5nFlHeN0kXYOhvg1j6xtSGJhlxZxVFeprtLtdpgriuqcx93TrXLveLlu0iWgnZrgwnc+
9n2btkGrfo8EFgDqsEh3TbnTnVm3fiqLGDNQumGDmhjGfgzxcRt9GZtZA+OcT/nBmrMkFvb1UnBU
V1kdyUm63Q7UaZVuMdyxrO0Z7h23w60cw+6Mc0UB3/k1Fu9p28/pFnUAoKY56yxdvMIVQ6xbxsIj
ULbfY4PaWJxH4izjM029iSxf8oQYriQW9mUeC+Zjd5Pk3wZSzQUAJpHQ0KJF3Dtegl/7enJ9ENw/
ZpkrChp1le4Y5IWiAKBSy9xeMSSWyLph4dEVQ0ApbmIDwhhJnGWMz+Q09SWyXOWJJ1BJYmHfE1Cn
sozTp34CeKEomrTo6//XSGZpySruHR+rL+kWJ+eKAr7zuR9PGrPQ8rhdgiMArViki1c4fYN1wxVD
/4xd9MC4DScsLxTFaC37OnJtHWMZ48wqehZv8oyDEySxMIYGOE+3OCe7cf+u+pez01cY2uYkklla
s4jjE8c0qPslFifhIb+mCyysFAWNviOcmghAq4bTN36Nk1m4tUq30COZBRgjJyyXZdnXl1Pg2LfL
1HEiy3ACy5P7QEksjK0ROgFgP276QdSkfznD4G4yiwFbO/U+i4zzffrYt7tzRQHf+ZwuMD1XFDTK
qYkA8G28wgIT61aRzAKMhxOWy7boxxquGWLf495pwc/gl7wgiU8SC2N9IbhiaHc+9p2HQRQ/eknO
cxscktjQhmVuM84lGO7G535SNIud9XDXVd8fTeP0FdptA05NBIDvLdLFK/4dC0zcWvVz6/+LjVnA
7t2ki2E4faUOy3TxqH9FgiT7MSSy/F7Y9/7Uf+9nx7EksTDWhjiPbPlt+9y/cGexEMTzLPrB9zBg
k9zQRp1P4mqpbbpKF3CdxolYcNdwYtxJ3BtNu++In+PURAD4kYt+TvXPdAF+81eS7zdmSXQCtmn9
1PuF4qjOZW4TJH+JBEl27yzdOsLYx7k3fRt58UYsSSyM2SqOftyGYZf/NDKA2cyAbQgCfFIkVbt7
tZRg4GasL0y6FgK+n+wMgZ95nDxB2++IheIAgCdbpQvwH0a8gm8tcpvo5HQWYJPEMNpyne4a+Em6
9Tabfdmli/7ZG+va+ed+vHX+mv+IJBZKmXjOIpllE53GkLyyVBxseMC2SJdR+c902ZUCRHXX9zyS
WV7LwiQ8rX3MI/BDm+N27wgA2IxFunjF/0VCC7dWuY1t/CvdyT0SWoCXuEoXD59EDKNVy3RrmIfp
TsiQ0MIuXPfP3U8Zzylz61fBv/oQBUkslDa5mOX2HlMvgaf52E/GppG8wm7a6Xm+DRB9FAiodpA0
z+1JPOr4aT73k5lJLEzCupv+ffHTWvsQ+KElV+kWT4Zxu3cEAGx+DrvIbbxiWGQyl+Uy3ck9k9ic
BTzN3RjGecQw6FzkNqHlp36e/0WxsEXLdHGkfSazbGXD7j/ULYVOOuf9Z9ZPMo4Vy3cdxqL/rBQH
e2yri7WX1qR/mU6TnGi3VdbztO+T3yqW7ya2F/2E1jVu8O14Zdm3D9dp0WobGJ7/peIAgJ3OY9fH
oJPcxiqmEa9o2aqfuw/H30/vPBsHigiadXNn/iZphR9Zrs31D++8U94oHrbwvE37ce1ZusTtoy3/
//yUbl1oK3FdSSyUbtF/TtIltMwan0xstcOADQQChjabtWDASf9iHf48UlTFD8wn/SDprPH6/JIu
8HVhYgv/axOX/WcZSV202waG9+VKkQDAKKzy/a7RaT+3naz9tXhFe4Zx22CSLn41fA5jIRJqn7+J
YbAJdxNos/YuMdZg0+Pas/5zkm6dZrqh8crV2tho62se/8h47kmC17hca5Sna58WElq+5DYxwCIp
pQcD1gdwh/3npP9n63993yCQcQ2Uhp1LQ5LhLjJ/x2DYVX8ei5NjmBwa5+6v7C/X+vn1v0cbaOU9
uNIGiphDjr0N1+SzZ4JG6sfctB7LB/75pP/cjVFMHxkXUN847+4GwvXnYf1ZGOJbPL18W2b+tr93
9+XaeOM6Tspkd+PbyyeONR5bG8G4/KnP2HTt+Ro+P+obr9f+Ozst0799/fpVFVKzIcOstsXTT7k9
ts5kGCjFkPl7mrqOZx6SCZex+AEAAAAAAPBiklhoySS3d85NU1ZSy+fcnlixVJVABQ7v9MklJbV8
udMny+oGAAAAAADYAEkstGyS2zvnpv2fY7h+6Eu601WWub1vEaB2h3f645OMI9nwaq1PHvplSSsA
AAAAAABbIIkFvjUsok5ym+Ry2H82eUrAcL/l+n1iq7iGAuCu6T19c5K82eD/jy99X7y681kqfgAA
AAAAgN2RxALPNyymPtdS0QFsxfQF/44TVQAAAAAAAEZGEgsAAAAAAAAAAHv3d0UAAAAAAAAAAMC+
SWIBAAAAAACA/9/e3V63bex5AP7fnHyXtgIxFUi3AjMVWFuB6QqiVBC6AssVmK4gcgWXqiBSBZeq
YKUKvB8wOGIUvXBAgBgAz3OOjrN3KQoczBsxPwwAgN4JsQAAAAAAAAAA0DshFgAAAAAAAAAAeifE
AgAAAAAAAABA74RYAAAAAAAAAADonRALAAAAAAAAAAC9E2IBAAAAAAAAAKB3QiwAAAAAAAAAAPRO
iAUAAAAAAAAAgN4JsQAAAAAAAAAA0DshFgAAAAAAAAAAeifEAgAAAAAAAABA74RYAAAAAAAAAADo
nRALAAAAAAAAAAC9E2IBAAAAAAAAAKB3QiwAAAAAAAAAAPROiAUAAAAAAAAAgN4JsQAAAAAAAAAA
0DshFgAAAAAAAAAAeifEAgAAAAAAAABA74RYAAAAAAAAAADonRALAAAAAAAAAAC9E2IBAAAAAAAA
AKB3QiwAAAAAAAAAAPROiAUAAAAAAAAAgN4JsQAAAAAAAAAA0DshFgAAAAAAAAAAeifEAgAAAAAA
AABA74RYAAAAAAAAAADonRALAAAAAAAAAAC9E2IBAAAAAAAAAKB3QiwAAAAAAAAAAPROiAUAAAAA
AAAAgN4JsQAAAAAAAAAA0DshFgAAAAAAAAAAeifEAgAAAAAAAABA74RYAAAAAAAAAADonRALAAAA
AAAAAAC9E2IBAAAAAAAAAKB3QiwAAAAAAAAAAPROiAUAAAAAAAAAgN4JsQAAAAAAAAAA0DshFgAA
AAAAAAAAeifEAgAAAAAAAABA74RYAAAAAAAAAADonRALAAAAAAAAAAC9E2IBAAAAAAAAAKB3QiwA
AAAAAAAAAPROiAUAAAAAAAAAgN4JsQAAAAAAAAAA0DshFgAAAAAAAAAAeifEAgAAAAAAAABA74RY
AAAAAAAAAADonRALAAAAAAAAAAC9E2IBAAAAAAAAAKB3QiwAAAAAAAAAAPROiAUAAAAAAAAAgN4J
sQAAAAAAAAAA0DshFgAAAAAAAAAAeifEAgAAAAAAAABA74RYAAAAAAAAAADonRALAAAAAAAAAAC9
E2IBAAAAAAAAAKB3QiwAAAAAAAAAAPROiAUAAAAAAAAAgN4JsQAAAAAAAAAA0DshFgAAAAAAAAAA
eifEAgAAAAAAAABA74RYAAAAAAAAAADonRALAAAAAAAAAAC9E2IBAAAAAAAAAKB3QiwAAAAAAAAA
APROiAUAAAAAAAAAgN4JsQAAAAAAAAAA0DshFgAAAAAAAAAAeifEAgAAAAAAAABA74RYAAAAAAAA
AADonRALAAAAAAAAAAC9E2IBAAAAAAAAAKB3QiwAAAAAAAAAAPROiAWAoVtHxI8df+aKCw5mmdE2
fyguYKLmGf3kWnEBAAAAMHZCLAAAAAAAAAAA9E6IBQAAAAAAAACA3gmxAAAAAAAAAADQOyEWAAAA
AAAAAAB6J8QCAAAAAAAAAEDvhFgAAAAAAAAAAOidEAsAAAAAAAAAAL0TYgEAAAAAAAAAoHc/t/Ae
ZxFxvONrbyLiXrEDAAAAAAAAALCtjRDLZUS82/G1v0bEWrEDAAAAAAAAALDN44QAAAAAAAAAAOid
EAsAAAAAAAAAAL37WREAANCBTURcKwYAAAAAAGBXQiwAAHRhlX4AAAAAAAB24nFCAAAAAAAAAAD0
TogFAAAAAAAAAIDeCbEAAAAAAAAAANA7IRYAAAAAAAAAAHonxAIAAAAAAAAAQO+EWAAAAAAAAAAA
6J0QCwAAAAAAAAAAvRNiAQAAAAAAAACgd0IsAAAAAAAAAAD0TogFAAAAAAAAAIDeCbEAAAAAAAAA
ANA7IRYAAAAAAAAAAHonxAIAAAAAAAAAQO+EWAAAAAAAAAAA6J0QCwAAAAAAAAAAvftZERzUWfqZ
RcR86387euV37iJis/WzTj+8bLZV1mcRcZz+t5M3fu9pWd+kn40i3clxKu/5Vrm/Vb8fUhlHqtfK
vN3+IyLieqt8t+s1+r/n6snNVj0x1uxm/qR97lLetxFxr7wH39bq/rj+74iIdzv87tP2ttYvD6KN
z7b61VNtfK95Yt1mdinL574LbcwVB18H6v/etd/UpgAAAAAm5F8/fvzY9z3WsduFp4iIX2NaF5tm
UV2kO0//HrX43tcRcZV+NhOvx12Wc0R18fwmlfW6wPLevgj8lk3Lx38WEYtU7qctl/lVRKxiugt7
Z1v1+l0H73+d6vPVCMp4yuNQ1/3f7VY9WQd1mZ931Db3HdvrBfa33B+o3eeMT4c6pn3P+7yjthYR
8X2rvW00tV7nVXUbf99Bn3o1krF3F9tt5rTl976Lx3D/VepDhmoeEf/JGCfmA5uj1D8nHfyNoX0n
nsVj6PEtNy3W63m8HrjdDgldGAYAAACAvr0UYslZdLiM3S9K/h7tXrBt88JOW+oL3xfR/sXal1xH
tdi/mlDdPY4qPLE4YDnXblO9L+WC+Tx2v/D9KSKWLZX9RXRzMfql8p5C/Z5t1euTA/7dOjR0GcNc
OF3HtEIsffV/D/EYLlvH9JynMn9/oL/XZGzftS0cahE0Z3wqcWG2DmqeH7hPjqgCLZchPHbo+dTF
Adv40MfeUvrK7TZzFcMMtAy9r3w6lz3v6TvaEL4TLyPijwPNWedbY1hO8PJfhgMAAACgby+FWOax
+4W0PpW0GDmL6sL3Irq5O3cXd1FdGFuNuM6epXL+UMjxfEtlvunxGHLa6z4hlln63fOe6vhdal/r
EdbrOvj2roBjGWIobh3TCLGU1P/dxWO47D7GbZH6vpMey3rXsX3XtiDE8rI+wsivuU3Hsg66rKfL
nsfgoYeWjre+B530fCx14LLv+fnY+8rnxspFIXPZkr8TL6P7EMt5+jtNxzAhFgAAAKB3PymCvR1H
dYHsvxHxW/QXYImoLhp/jeqC7Xxk5TyP6iLeX1FOgCXSsfw3HdvZiOv4Mqqdjz70WMdPorrAfzmi
sl2k9vpnlHHRP9Jx1P3IQhev/3ulPX5O9WQZuz3CZojlfpPaw0nPZf01Hctcc+jMLB53xPgaZQRY
Ih3Hf1IfMHOaWj/n61S+fY/B7wd6nmfpe9D/RbUwf1LAMR1tzc9X2s1BviPcp36zlLlsPW6O+fvZ
c87SZ/6zoDEMAAAAoBEhlv0so1rs+FDYcW0v9g99YXEW1d2UJSwwvOZdVAvMqxjXYu55VAunf0S/
Aa1tv6VjGnI5z6OMxfG3+pE6zHKuu++t/1sX3v8dpf5hE9Vd+GNwnMbP/0RZi0B1mGGpaXRyvksI
I781z7jRH7dmkcrzXYHn+b8DaOdPA86l2g6zHKv2rZ//TWHfEV76fnYxgXNykT7rO9UTAAAAGAMh
lmbOoryF/ef8FsO+A20Z1YXn9wM65g8xjsXcelHvzygzZHGa6vbxAMu1DmUN5Q7Jk1QP1uFu5j76
v6EsRhxFtTPL0HcLqe9i/q3gY/xjoP1fiS7SmP3bgNrZnyHItK9VVCHNo8Lb+U2hc/gSA85TmZ87
/818jvE+brf+bvFZ1QQAAADGRIglX32X01AWoOvF/iEFWbZDQkNUL+auY5iLjLMofxF3u24PpYzP
o1pAeT/Qel3vAmABSP/3Vrsc6mO/zlOfcjqQ9jjUMaakdvY5hrMIu+2PGO+CbNdWUfbOIc/Nc0rZ
fadeLC814JwzP59pCo2/Hwz1/H8YYb95nM7Je9UTAAAAGBshlt0dR3Xha4h3OR1FFbxZDOBYFzGc
RcS3vIsqtDAf0DHXC3tDCmmtBtJ3/BnDXCx92pd8jmoRy+J5+4YWknxN/div2UCOdzHANjrUHam0
s3Z8iGGGxfq0iuEEWLbH3T+j/yBLPT8cw2K5R3Plq3dfGfqjasYUZDke0XdmAAAAgH/4WRHspIuL
RNdRBRy2f7bNtn7m0c4db18j4j6qBegSrWJ4iwtvOYpqV4KPUf5F03mqG0MLWryP6uL61UT6jlLK
fB3Vwv+NIaKVenI5wv7vNB4XCtcFH+cijY9DLeN1DPexgX0Y025SdVhs5bS+aTnwPnajj2x9fv5n
RHwJO8zt2m8ejeSzfEjfh4d83gVYAAAAgNETYnlbWxeJ7qJaZL+KZot5s6hCBovY7y64VXqfm8LK
eBXd3N15ncr7fuszPy3/s3QMx+m/z1IZtXmx9utW+ZfoLKqwTRvuolpsuUnlvl3m861zXpd1G+W8
Sm3kvrAyXUf7F/3v0vtutsp1E39f4JrF4w4Y8/TfZ9Huxe568by0/mSqY8xTD+l9b7ba4v2Tc1W3
w7q+1uNMm8dSepDvPNpdnL3eKvPNM2X+Uj+4z5hzGlUIykLsbhYtjnfPtbP1G3O52dY5b2Oucbn1
93nePPZ/RFs99j4df18a/4/T363b+D5h9C89nt/L6Obxktdb/eRb8/PZ1vjU1ryx9lv6GxeFzSFL
7Df/22K/ebPVd77Vb26PlXXfedLCeV9HuTd27NIuBVgAAACAUfvXjx8/nvvf59HeBf4u/Rrd3t3d
xuLit6gW7to8zlnsd0fpQ5Sz4N/2Au6+YaFt9YXSRYvH9+9ofyEip71+SnXn6edcR/NFgYet8l5H
/t3CZ6mMF7HfwsRzn60vbQdYvm+V8WbP9naeftoMjfUdUFjH7uG+rseNPvu/263x5mbP45pv1ZW2
6vHvUdbjT9pqp9+3xp37PY9nkcq8yQLdr6kP3KUtXMdhHnWXMz4d6pginav3PbezWt3OPuzZ9u3G
87JNNF/0bmseX88pLzKPpc85+yra272m7fn5Pn3lS21o3uN3o1L7ym3LaB4Gu906/230m2epLe0z
R+mrbeWU43Nz1ovo/vHG/zJsAAAAAH17KcSyfXf2W3LuBPo92l3A397poW37Li5+j+oi06bD8zeL
6gJzk51Z+roA2mYZb+siLPS0rBex33baXZX5PJqHWGapHR01/DyraC+8UN8J2/QCeSnhrLYWxu9S
2V529JmOt+p0G4tAfQZZ1jG8EEtb/d/DVj3parxZxP67gNVKeXTDcer79qn731J/2kW5z2P3QMp2
n7EJIZZdz/8mo5++2xrvumpns9SOm4ZrSguJlWLZcF5xG909Mm+e3nuXgEhfY+sq2gmwfE/v1dWO
F3WYoY1j7TPIUmpf+dQmY9w8xPzkOLXxprsF9TEnyemTns5ZzyLirz2/W6zj9d27zowlAAAAQAle
CrHkWMcw74B/S9O7dO+iujB9yM/Z9I6svhc8curOS7pcRHzJIv3NUu6knUfzEMtN5C+iX6f36KqO
n0V10bvJ4n7fu7G0EWC5S59hVXidLqmPH9o41EaA5SH1312FnF7qa5Yt9NslPFpon504rlObOcS4
c57O8UnL7zv1EMuuc6e2w5q7nvNVg3GkpF32SnLfoCy/pTbetVm8Hlzqa4edVewfCjn0/HwW++1S
2XeZl9xX5h5nH/PYeRrXm8y/fznw98hlNA+xNPnedogwEQAAAEDrflIEz1pGs8Wtb/G4iH1Il1Fd
5Hpo8DlnPZXxKvZbCL2O6qLjIg5/QW6Vyu1TRpkvoryFpdznqd+mejbvuI7fpL9x2+B3Fz2W5yz2
D7B8iscQTx91+vcG/ci2q/BIi7e09Zi6WerDD9mvrFPb/N+oFqma+tpzW93ncVq/pzI41LhTt6lv
mk4nY+DtDuPdofvjq/R3c/vioyhjl6OSLBqMyZ8O2D9tUn/06wt9ah/ncxn7P9rq1x7m55v0N39J
3xGaOo3+Q5YlW0e1u85z7qIKqc56KMN1+ru3Dev8EFxkzh0f0pyl3ulyo/oCAAAAQyLE8k/zaLbt
+MfoN6iwjvxFj6PoZyeWfbb9ri/IzaP/i3HLqBYXv7/xuu/R3Tbq+9TznK2363DF+kDHdx/NFvFO
op8QxXE0vwM0orro/kscPpTw1GUqv6YLQEepHI4NJa+WcdMAy108Lg72WU/qYMWXFupaH221ybj3
kMq+jzHzPp3z3zWf1i2eOc8fo59A8rabhmPgwin9m/PM13+Pfha01/HPsNq3HurgIpo/0rGPueJz
NqntfIzmodwPMZxgQ1/95sOTfvNT9BNeee67w22D8136vPU4s05+icedngAAAAAGSYjl7+qF6Fwl
PBoholr0yL1g/z4OuyX1WTR79FHE47PqS7ogt0ll/tLF8ococ1Fp1zK8i4h/Rz8X8+8b1Ofoqbz3
CSZ8Se1iU1Cdnke1INHESZQX2irFPgG+79H/4uDT9nkR1a4sTRYK+wo8XUT+o3keovsdqHbtZz5q
Rq3Pm75stbFZlLMLQ5M53UnDcXOs3me28z7na3VYrZ5PHnoXlrM95tcPPc4VX7JKn+m24e//oS29
Wlfrc32dynlZ0LEtYnwBwIvYLSj/kOZlF+HRcgAAAMDACbH83WUjqGCoAAAgAElEQVTk76RQSoCl
to78u7WXBzq2piGhiMfnv98UWndW8fzdf+dR3kXERewWuLiN6sL0Tc/1+Xvm78x7KM+mwYSPUe7j
H5bRPKDwLtzF/NQ+Ab4vhfYlEY+PPmnyeKGTA4+fs4bt7bygsWcVzQNmvN7XldjG1pG/45GF92Zz
gVUh53+Vxov7Hv5uk93kblPfWuL8fJPqwbc9ysTOci9/Zy5lZ8ynbhrMQRcFl/XxjnOXu3Q+BMkB
AACAURBieTSP/IXo0gIstcvIexzIuzjMwv8y8u+Aj6guPs+j/DvK6u3/6yDLlyhn14Sn52FIZZ67
6Hwah1t0mMV+jyZZFV6n64BCkyDLH9HP42JK1fRclxx02u77mt7x/j4Ot3i0653M234vsB9fRn64
j5fdR9mLfsvMPliI5XFef4g+ugubHupYk93kbgcwP6935mgSZDkawDyt7++bJR9bTrj2NM3pS7TY
Ye5Swo0HAAAAAK0SYnm0zHz9lyj7wuYi8hY9Fh0fz1lE/Nbg975F+Vs8b6ufx/4pytyJYh5vB4nq
Mi9lUWITeaGsur4dwiryF8VLeTTJrupw1kPD8qH5AuHHAZVh3fc1CbJcRvfBs+MGY8n3KHeRbtGw
TTI895n18CgOvyNZiXL6lIeY7uLvWVSh01xDCLA87TObBFneh2DYVL7bl9pvvhVkfhhYWwQAAADY
iRBLZRHVbiS7uo3y74zfRN6ix4fodhGxyULgbQwrwFKrnxVf4sXEdwMt81Xm6+cHOKbzzH6jdhHD
Wyy7aVimpwPoK7s2i2YLhKUHJV/q++aRH644iu7DIovIC5w9FD7+3Gtbk1LiGFi6nDDrlHcvaDo/
n8fwFs0X0SzIcoigJd30m0PfxWoer998IMACAAAAjJYQS2WZ+frFQD7XZZSxG0uTxf76ud4cTsll
nvuoh9mB2leu32O4u5PcRLUzSJP+dcqLP8sGv/M9hhtQaBpk+RDd7qB00aB9l74otIq8xyUwXJvI
25HM/Ild5/258/OhL5pfRP6OYSchNDhUOd8fSnwE5vkObdgjhAAAAIBREmLZ7fEq277EcC4W3Ufe
xbtFR8fRZLF/Ee4qO6SHqC6U3hdcl3MWHWYdH88is9+IKPvRJLtapT4wx1FMd/FnHlU4I8ddDHMH
qm03Dc95V+3jLLO93kWZj4N7zjKYiqEvxjKO/qPkueKu88nzyA9aXoTdWMbeb54UeI5f+z7zJfJD
/gAAAACDIcSSt9D2EMNbMMpZFDyN9i/enUf+Yv+niFirmge1jPLDWTl1YnaA8spR+qNJcj977u4P
U138aRLkGPoCYW0VVXArx7voZgeJRYNjH1I5241lGnLGwKOw4M7b/eJU5+ebBuPClAO5U+k3I8oL
AL70GMQhhW0BAAAAGpl6iGUWEe8zXj+Exws8dRN5O1i0/Tzw3Au+dzH83SqG5nogZb7JeO1Jh8ex
aPD+ixjPzkL30WzxZzGxdpU7vkRUC4Rj2hZ+Efl3u3dRT3LGtYcBjkErw9gk5PYNU9+NZaOsXrVs
MD9fjujzX0V+0NJuLMOcs5a0k2Ob36/tWAoAAACM2tRDLLmBjaGGK9YZr523+HdnUd1Zn8NFucMb
yp2lNwMtr+sY33bf64j4NtJ61pZl5uvHGOC7b1AOH6LdRaTcRwldDXAMWhnGJuNWEexsk/Hao2g/
xF2yeTQL4059/ju1ejKmuUjOd9fSjfF7BQAAAMA/TD3Essh47bcYbrgi50LXvMW/a7G/fN9iODs/
bDJf38Wd1WdRPXary3YwFMvM15/EtBZ/zhuU5xgDfJeR/7ibRYt/P3dMG+IYtAnhhqm477Duj03u
3GZKQcvcPvY6xvmYz01UO6CpJ+M2trq7dEoBAACAKZhyiGUWeYvRQw5XrDNeexLtbZU9lZ1uhmw5
oGPdZL6+iy3fF5mvH1JIqMn5+NZxnzBUi6ju2N7VXYx7N41lx+3sNfOM1z4MeKxfB1Nwowg6axPv
JjJGHUe149VY54q5LiPvsXen4VFdY1b6uR1roAwAAADgH6YcYsm5UD3kha1azl3abVzAy32Ew13Y
heXQvkV+MGTqche4ViMvj2XH5TeVerIceXmsIm83lpNobyFpnvHa9YDLeB1Mgcct5pVV7g5Fqxh/
QCF3fBr7ovl9g+8fC81rtP3mceGfxQ0fAAAAwGRMOcQyz3jtGMIVm4zXtnEB3y4s5RtimT/0+Ldz
g1m3Mf6F5U1EfM94/VFMI8gyz6zTUwjwrToeQ54zi7wdcYbcXtcB7NvvHKW2NOYgy7zjMhyiZcdl
SL/GsoOVGz4AAACASRFi2c16BJ835wJeG3eh5S5Auih3WLcxzIu6fR7zPPP1UwlmrTouxyGOLTnB
iauYxu4KfdST2YD6l33dR95uNzCV8Sk3/FoHWcYauMzdiXI1gXqyibxde04bjC9w6HkUAAAAwKBN
NcQyi+ncnd1EG3egnma89jY81ubQVoogm2DWy58zZ5FwPvLymKsnz9pE3iLhux7OxXoEZQw8uo9m
gdKjiPgz9c/HIyqPs8gPWZoXT3Mug+9uAAAAAL36eaKfOyek8RDjWBg65E4s88zXrzTFg7PzTbf9
xm1MY3eN2joi3u/42tORl8VcW3y1r/+cWZbrPf5ezlh2N5J2+C4YQt8wi2Y7OcwVY7bLiFhE3uMA
a+/Td4BljGN3NePT6581Z3w607QG02/ORlAObvgAAAAAJkeI5W33kf+s9BLNCi3fiOntdNO3u3Ah
tEmddvfy65/3fcbr5yNu9zn93/XE6knuOd+3nuScizH0ifdB321/tvVv/XOiaHpvF4uI+E/D3z+K
Ktxwkb4PrCYyPk1tfr5J8+OTjsqSl7+fztJ4X//3cYw/8BzaIgAAAMDrhFjedhIRfyifTn//RlM8
qLUiyDZTxq224bORltEsPKquzXoyK/jYxlC+7NfW56kvOws74Axh3vMxIr7u8R4n6fcvt37uB1hv
dzW1HeXqevJhx9dq8/mOU785129ms4MmAAAAMDlTDbEcO/WvOtrz92cZr71W3AdnoTOfYFa7n3es
ffAs8/XrCbal69h94Wq259/KWSCziwlvOY9q8fU87K4yRKt0/j7s+T5HUYXb/4iIb+l9h9KX5/SJ
U5wr3mTWj2Njx07z50Vqe3ZXaW6tCAAAAICp+Wmin3vm1BdTvhvFdXBCLPlyQhcPMc1FjZxA2nyk
ZZAbdppi/3fTYXlC2+ZRhRTuI+LPiPgtBFiGbBERX1p8vw9RPaZoE9XjhsYU0DQ+GaP2mQtdpjr0
V+o3BVgOM78GAAAAGI2phlgsQJRTvhvFdXDKPF/OQsVUQ0LuRs5fwNyoJ686OuBxrUdQtmtNsDWL
1D7/E1VQ4UiRjMZFVI8Wanve+zki/i8ed3wpTe4xTbE/MT/ev99cx2Nwxfftdrj5AAAAAJiknxQB
PdsoAmU+MlMNc+RcZJ+pJnE30c+9duop2DL14V/DAuyYrSLi3x31w2PencX8+O/miiwiHkN/XyPv
kVW0P78GAAAAGA0hFp6zz7bFHqfB1LnY/LaxLg7P9H2tmysCDuA8tck/wq4rUxqrz6Ldxws9HedK
353FXIZ9x+ebEPrrmvkiAAAAMElTDLF4fnm33HFaNs9Vb2amCFBPYJRzlquI+DMswk7RfVS7pfwa
Ebcd/p16d5Z1VLtWDKVs4KV+8zLV6VPF0bm1IgAAAACm6OcJfmYhCyCXxU2AcTmLanHw0Duv3Mbf
AwL3sfuuF/PwuI4urFN9WET1SKmuxvx36WeZflaKvjjX2tib/eYqDh9eedpv1u12F7OogmRD9KDK
AQAAAFP1syJ403MXzcZu7bTD39yFIAvAWCyiegRGV/PGTVTBlJs0h9xEO4+EWIYF9i6t0s8iug2z
nKT6t0x/y7y7HNrXy7oM/t1t9ZmbJz/7msdwQywe6wUAAABM1hRDLLmBlItwcRmmbhNCLLQ/vlCO
M2P9ZCyi3QDLdao7a3VoNFbxGGZZRHfBhpOoHslynf7ORtEzwX7zxvwJAAAAgKd+muBndkdTt1yE
ZOpmiuBNY90ePWd88Wi73awP9HfGcD7mqsubFtHOQuz3iPjfiPifVO7LEGAZo1U6v/+OiG8djl3v
0vhxoT+hQGcRcdlSv/nxmX7Td0ffqwEAAAD+4SdF8Ka5IsiSGxKaKTJGZuZzt95PjNGpMbVzD6oZ
W85ivwDLQ0R8imoB9jwirsIC45Tmtos0zv0e1aNP2nYUEZ9TvRJy7Mdxg3oxhbndOpo/QughIr5E
xC+p31zpN9UxAAAAgF0IsdC3mSJgADbqtM/dAQuV3cpZ/JkrrtG3tauGv1uHV2ZR7RxgAXa67qPa
kWIWEb9GN7uzvI8qNND2mJq7GH42wfN71qA+jN1VNA+wfEn1+CL6fVSW+SkAAADAAE01xJJzB+WZ
apIt54L+THExAJuM155MtIxy+sqx3lm67rDMxmKe8drrAx7X8cTa4NQsG/bN16lcl1HGgrXgW1n9
/SLNYz9Gu7uznKZxss02fa+utf6dZDPy8lhGs13jbqN6BNdFIf2m75oAAAAAAzTVEMsm47Uz1STb
jfJlwn1GxPR2dTiOvDt1x3r3cu7nmmLoYHbAerLOeO3pSNohz7ez3xr83rfUl28K+yyU1++v4u+7
s7ThKPVhbbbrW/OYVtvXZsRlcRxVCKVpv+kxOAAAAADsZaohlpwLa6eqSbZNxmvfKS5GVqcjprfQ
OM98/drYMsl6Mou83TD2XQSbWqhoHjxn2eB3Pka1y0ZpBJXKtk715peoHqWy76OGjloeL3PmMh4n
9LrbkZfFReQ/Ruhbqv+lBZWNjQAAAAADZCeW3cxVFeXLpK3V6VY/72bEZeFO9/Y+774hlpuJtVsB
h3+aRcT7zN/5FtXOGiUSrB7OPPgi1b9PsV+Y5TQiLls6rpw+8SimFWQ5jrxg/c3IyyJ3F5bvUWbw
z9gIAAAAMFB2YtmN7dvzrDNfP1dkDIBwwsvOM157F+MOseT0fycTG19y24UQSx4Bh3/KXYi9jXIX
Ys1Fh+c+qp2AzqJa5G/qt5b6p9z5+fmEztWhx6fS53Q5u7A8FNxvGhsBAAAABmqqIZZ15uvnqkqW
3Au754qMkfUbRxOq17PIe0TMeuTlMbXgRFd9fRthp/uYTvjMPKWd+cVFwZ9FiGW4Nqku/hrNd2VZ
9vD9Z0rz8/OOy3Ls/eZ9oZ/F2AgAAAAwUD9N+LPnLGy9D1sR58hdODyNaiEcSrbOfP1UFn8uOi7H
obnquPyGKvfO7rbqSe7jM4babue66H+YRV7A7rrw/sk5Hsc8YpY5R669a6kOXJuf7z1ne4hx78SS
8wi2uyj38Wv6TQAAAIABm3KIZZ35eruFdFu+C0VG4XLDCecxjfCbu5f/LjfEN5VHCi06bm9TH+vN
Uf5pnvn6S+eYA40RZxHx7QD9aBt96xSClovIC1lejbgs9JsAAAAAFOHQIZZ5QZ899wLkQnXJsla+
jND3jNceTaBeLyJvp4Pb2P8RMUOwynz92BcJZ5F3Z/dDtLdIOIXw2XFUOybwz3rXZV05pLPIW2Sn
fBeRvyNLGwvyTb7/HE/gXIylr2ijrxlLWRgbAQAAAAZs6jux5DyX/l145E2Oq8zyPQlBFoZRr3OM
PZyQ22ZX6smzPox8fFl2XH6vyd0ZZ4iPFHKn+fPmGa+9LvyzmB+Nz31quzlz5aPYf+euzQT6xNx+
Iifo0GbIskTHmWWx0W8CAAAA0IU2Qiz3Ga8t7ZEJuRchl6qM8mXSViGcVZtHFe7LLb8p2ET+ovhY
+79ZVCGdPuvJauTn4iLY103hx7dwikY7VuQ+jmXeU5841t1Ycvv7q5HXyZzv6vpNAAAAADrTRogl
5wJWaSGW3AvHY79bvm2rzNfbjYUx1uvLGOfiT245fIu80OPUyudDgWNkG5aZr7+L/MfRTXksOguP
S3hJznyt5L5pER4lZKx4dNzS38wN5I4xLHce+WHcy5HXx7HMV+fGRgAAAIBhO/TjhE6irItjN5H/
PPpL1WZn66gWJHPL91jRUbDcPuAoxrfLxjL15zlWE6snq4b935jMI38Xli7ayn1EfG9wHEMYi+zC
8vqccyz9LeO1yRwr5i31iU0ejzgbUbkfNxhzr6P83UcO/T2vVAunBwAAAGDY2gixrDNfX9pz1XMv
YL6Pdi4gT8Uy8/VjXPBnXDZR7SqS47cR9RuzyF84v46yFzu6ssp8/bsYTyjhuMHnf4juHtWQO9af
DGAsmkV+SIhhWcR4wji8Pq8Ywvx8NaIyXzZoW76f/F2p81pjIwAAAMAItBFi2WS+vrQQyyry75Zf
hd1Cuizf3wqsJ7Bt2bAtjKHfuIr8R1ssJ1pPLiPvkQ11WZ2N5LOfNPidrh7rsm44Fs0LH195WU7b
m41orIFdv7/lBnLHErQ8T/17jqmGcYf63RMAAACAgWsrxJKzUPA+ylvIXWa+/iRcIOuyfCOV72wk
n1/gaXw2kb/4M4Z+YxURp5m/M+WFn/vIX/Cr73Yfcr+xiPy7oO+i+8cpNR2Ljgst43e64lflPPaj
xODYRdiFhfL6xM8x7KDlrOFcbKm6DKLfnBsbAQAAAMbhp5beZ535+tLu4ltF/h3a72M8j30osXyP
otrx4XgEn/1KFRili8jfZeN9dL9Q35VFNNuefVHY5zjuoQ+4zfyd0xhu4OksIr42+L1ldLcLyz5j
0Uma45Q0Fs0G3I+U6rTAc7x0Wiajr7q3iYhPDb/3nQ20nJvsJvc9ygrjzjp875zPeVRYPWjyGEMA
AAAACtVniKW0cMKiwe98jvIWaHd1HIddIGkS+DmN8hYPc6yiWvR/F2U/koJm7hu2od8G2G8solkw
4VPkP3Kua30suDTp/97H8BZjzqLZQt/1AT9rk7Z3GuWERpouwk7RTebrS3qM4co5btWy8Llkzg5n
65b/9mU0C5qvBjY/P05ll7ub3EOUd9NClzs05YZJF4W1c7tXAQAAAIxEWyGW3J0mjqK8O0zXUd1p
l+trDG9BepY+7x9xuHDFVcPyHWKQpV5k3N61YqW7GaXLyN9lY2j9xiKaBVgO8XiYJvoIsawj4kuD
3/swoL6jDrDkLrw/HLgtNB3rP0T/u4M1XYSdqtwQSynz0mV4HEabLtJ8t9S5ZG54atPy37+P5uG+
oczP9+k7l1FeGLfLuUxuv7kopA4sogqJAwAAADASbYVYNpG/kPtblLc7xSLyHw8SMawF6fOoLlDW
F3JXAyjfIV0orxdy3z/530/CowHG6nyPfqP0OrGIZgGWulzuC/xMfY07y2gWeKqDLCX3f/NoFmCp
y2Vz4OO9aNhm36fPOeuhjAVY8q0zX39SwFxuEVXggvbmZJ+35pI3Ud5jcHJ3+bjpqK00CVqepv67
5EcL7dN3Xke5j27rai6T228eRf871ZztcJ4eAgAAAIBB+anF92pyke8qyrrw2fRuxIjyF6SP0zn6
M/6+0HjIcMU+5TuEC+UX8fqF8ovoZ/GTbm2i+QX8P6L/3R1esormAZbfo5uFtpfk/K2j6CfIUvd/
TRZSPkR/4Yld+r3/RLMAy/foZ4FwnzZbL4Qf8tEzZ/H38Cm7n+fcx6Qse+yPd1mIvYtqYZ3d5r1P
d4o8iYi/opzHw8wjb9edhw7H1otoFrQ8SuPTeYF1YJ++8+HAn2md+fpFh8fyvUHdmfV4jtdvzEG6
bDcAAAAAdKTNEMtV5C/O1Rc+SwomXEWzuxEjHrcrnxV2nudRXbx7aZvlQ1583Kd8j6JafFgWVr6z
dN4/x+sXUUt8jBbtWEXEt4a/+z7Kuju8XvT50PD3v8Xhgwm5O770tYB5E8MKT7ymXiD+3PD3b6Pf
XS/2abNHUQVCux7vj9OY8VdUi+9v+RI8d55znEQ/j/Cax267GS1jWI9X7Pvcv9RuPhcwX38uZLPL
HLpLTXeWq/vEy4Lq50VG3/lSmzz0bnI5ZX8a3QVymzwmuI9A9q6PMSx1Nx0AAAAAXtFmiOU+ml0k
qoMsi4LKpendiBHVHZX7LFS2aZbK9j/x+kXcozjsBb6L2O9O4j9SGc97Lt96gfEmdr+T9kOU9xgt
2rHYo17Xd4cvo98FoGU6jqY7Ptz21PetM1//vsd2uIr9gnx/RrVYNOu5rm/in49N29VDeo++Hze1
z1hfj/f/Tee0zfp0nI5tE7s/WuYhhCRfam/RoH9YHbg97bKb0W06Ljvy7Na23+/YfvsYd+tH3OTu
YNX1XH2zZ1/2WwHz8zrY8HmP9/gY/ezccVNIfVhFfpjpNA4bZJnv2IYeQogFAAAAYJB+avn9LqP5
HXxfo/+FuW3zaL64dRTVxdNN9BPOmUV1AfK/sXu44tCLuuex3+LhaVSLPqse6szTBcbSFiHoz771
+o+e+o1F5C2YP+c2+rlzOSI/xBLR7+PsLqL5LiB1f30Th198naey/hrNHh8UaY4wjzK29r9Px/Kw
5/t8SOPRJp2TJvXqOPUfq/Q+nzPLeBF26HjOpmFb+5DORZdlehx5j21bOJ07OYu8AMMfW233+EDH
t478MNL1gfrNm6hCHE2dpP7w0N/p6u8+f0XeI5qe+j362Y2pyVzmtMNjbfJd5V36DF23o2Xs/hjD
RfQfmAUAAACggbZDLPex313476MKXlxF/49MqBe37vZ4j5OoFic2cZgFpkVUFw//G80eBbLqoXxv
93yfD9HNnfDPmUV1UXcT+QuMT99npvsZpTbq9dFWv3HRYb9xHI/hla/RfMv9SP3kPPpdKPjeoJzX
0d+uWYvYL8hyFI+Lr5cd9ynn8bir1z6LgyUFWJ622YcW3usknZO/IuJHKrNVVAtuF+nv1D/L9HOV
yuP/otpl50ODseVLlBUCLs1yj/nFuqO5Rd0Odp2rfSqs3ZSqySN6DtmfLqNZgGWfetzEKvYLsjz9
Ttfl/PwsHoP7H/Z8rz4eh7itSd3tqp9qemPKaWpHXXyPr/vNXQPX36P7R3ABAAAA0JF//fjxo4v3
Xcd+C13brtP7bdLPtln6d9VhGdUXR9vYvv0hqotpV+kz7bvgexzVBb3z9HPUwjF+jMOGWeot1dva
Hv8ule8q2lnwOUtlvGixDsyj3cWoeVSLy7u2p/mA+6ycvuXXaLZDR4n1+luL/cZ5y31GnzuwbFvE
7jsaPNcu18+0y80B+sNV7L/wtt2+V1tj5r79Sl1PTlo4thIDLE/7+nVLbeKQbuNx55cSx4JSjmkZ
++009S29Rxvtapk5R94+xxFVQGoXn2J6j5g6jyoM1lbbWr0wNuTOBxZRBdma9qXfo59w/z7japfz
81kqj7bm5hFVGLCER8Fu9qgnty/ME1cN+659z/91PAa39h2fLzLnSnfp9+4zvz8cYmzMGY+m2I8D
AAAARER3IZbjqC6WHWIx6BAXm9pekK7VFxs3UV3QvY+XL+zO0s9Z+nfewfFcR3VR+NCL0V2V70Mq
z/WT8n3p883TsZzFY3jlqOXjmUf7i7jzEGJ5Tp8hlrpeX0V7gb6n/cbNk77jub9/ttV3zDs6lnmU
sVV7F+POodrLKtoLsmyfm5utn028vIi13ffN079D6Pva1mZo9RCetr8Sx4KSjummhXN7HY9h5E1G
vZpHsxDDQ+q/t/tYIZbXLaK94MX2eVhv9af3r8wvzrbm6+ct1Lnn6sAh1Y85O+qoPNdvfP85i8fQ
fj2XOWn5Mx46wP+ai8h7HFbX8+GrqHbV2XesWkVeIGwWzYNKz805dv3+IMQCAAAAUIifO3rfenv+
dQzvrubXPk/bC9KnUc5iWZ93INblu4r9L5RuO0rn610B5VvSYj+Hr9dtBhRK6Te+pT7jvqDyvoz9
dlvoyyKNl187qCcfev5sQ+r7bjoai4wpZThP53ifeWk9p/gcj0HZ54KE24HYpn/vwTluZBVVwOiq
xe8gR6lPeN9Tve2zDlxtfQc66aA8+xyzH9L4e1VY/V0W9P25np/sM+88jcdgTt1vbuKfQcA2+s1I
c1OPXwMAAAAYuJ87fO96MWgd4wqyLGOYi6QvKeUC7n1UF+rHVr4R5WxRTj8WqR+8HElfGBHxe/o8
pblM5X0ywDJdpXHzaqDH/5zSgk5jGYv62jVt6DYtz0u7DMoOZfeiUq2jWgi/iuHsrPScj9HvjnLb
3+nq8nw3kjpym+YLpbWx+zRufi3oeBYD6TfrNrPSBQIAAAAM308dv38dZHkYUZkto9qW+W4En+V7
VNs1XynfTtylzyLAwir1hbcD/xy3EfHvKDPAEvG42DJU9ULh94HXk4eI+N90LoYatFimun5d2HF9
CrtzjH1eKsDSjk3qT78M9PhLW4yvw/y/j+B73ZdUN0ptY6vC5gFD+T4vwAIAAAAwIj8d4G/Ui3K3
Iyq3dQz7wngdrij1Tu6hl2/E4wXytW6GJ33hpxjmAtCnKHvRZ7v/+DjgelLvBPK/McwwX4nhyH3a
7DzVp77PxW0at5e60tbOa4n98F0IsLTtIrWdoXwPeYiyF+Mv01zgeoB1oe5HhxAuXxRWZ0v+Pv+Q
zusqAAAAABiNnw70dzYx7MXb59TbPf8aw7mQexfVhfFZlB+uqMv33zGsC+XXEfFLDO8RGhzOMvWH
3wZWp5cDKuNVDDvIElGFQIY0btaLg2N8zM0qjZt9hFnqcVsosl036ZyWNL/4HsMICg7ROpVt6buI
3EYVYloVXp6bdJxDCVs+pHM/pH603vnmtsDz/q2wNmN8BAAAABihnw7895YxrMXbXayjuqBXcphl
exFsNbDyvRlA+UY6tl/TsW50LbxhE9Vdtr8U3B8OvU6vYviPJrsfwLg5pZDFKqrgw6/pfHS5GP49
qgXiWbi7vMv2NU/1t89gQ73APsYAWGkuU5sqMRxYPypsSCGmq+gv4Ldr2/qUjvFygPW17qO+FHZM
i0LmV/UOgb53AQAAAIzQTz38zU08Lt5+imEvMG5bx2PYoiZqCE4AAAXlSURBVJTnmF/H484rqxj2
4sh2+ZaymPuQjuWXdGxrXQp79IddL4rv6ltUOyCNoU6vYxy7gNX15H8KGjfrkFM9vkzJOp2P41QG
n2L/kOVdansf03k+j3E8kmkIVtFfsOFbDHeBfajqcOAsqvBQ3/1pPY9cDnieXrehj1HGziF36dzO
Bl6udX0tcefPdY/95vcY3g6BAAAAAGT6148fP0o4jrOoFoTmEXGa+bvX6fdKM4tqEWrR4DPt4y6q
i8mrGPedacepbA9dvhHVxdOr9FPChfF5RPxn4O1lV+uIeLfja3+N4YUwjlO/cR4R7w/4d2+jWkQt
pU6X0meU3F7Ot36OjC9Fjf2zNK85fuO1N6m9tdVPlTgWDG18Oo5qwXgREScd/Y2H1NcuG7ajXSfu
n8Iib873kIsD9qcPqR+9HGlfWn+vO++wHb3UrlYx7lB5XVfnGWXb9Xy4nrtedPyd7FtqM012K9r1
+8MhxqFlRPyhHwcAAAB4XSkhlqfm8bgQVP/fz1lHdfF3VXg5z9JnqH/avKD7kMphHdXF280E63F9
8bSL8o2oFvjXWz+2+2fo9fruSZ3eTLBsz7bGlvmAx5d4Uk/aXEAyvgzLPKYTaDyEthfiSwvA8vJ5
r/vUdy2+791WX3qlPFtx/WQuM8W6erb1nXn25P9/H1XgY3XA8XufG1NeOsd1OEm/CQAAADAhpYZY
xm4Wj8GWejE10r8v3QFabyG9ST836WejOP9he4F6u3xn8fJC1N1WWa7j8cLvWnFSeL3O6TfWW//N
eM3jcSeQ+Q7933PjS/0vwzrvQizdzdvOtn6OX2lTt2kOsT1XM5cYru2gQH3uj+PlBfp6Pnm/df7N
1/cvz3gyh9GmhjVvnW39nLxynrfPr3MMAAAAMGFCLAAAw7eM3R9RIMQCAAAAAAAU6SdFAAAweMcZ
r10rLgAAAAAAoERCLAAAw3emCAAAAAAAgKETYgEAGL6cEMtacQEAAAAAACUSYgEAGLaziDjKeP1G
kQEAAAAAACUSYgEAGLZ5xmsfQogFAAAAAAAolBALAMCwXWS8dq24AAAAAACAUgmxAAAM1zwiTjJe
v1ZkAAAAAABAqYRYAACGa5H5+itFBgAAAAAAlOpfP378UAoAAMNzFhF/Zbz+Nv0OAAAAAABAkezE
AgAwTJeZr18pMgAAAAAAoGR2YgEAGJ6LiPic+Tv/ExH3ig4AAAAAACiVnVgAAIblLPIDLN9CgAUA
AAAAACicnVgAAIbjLCLWEXGU+Xu/RMRG8QEAAAAAACWzEwsAwDA0DbB8CwEWAAAAAABgAOzEAgBQ
vqYBloeImIVHCQEAAAAAAANgJxYAYMqOowp4LNN/l2gZEX9FfoCl/l0BFgAAAAAAYBCEWACAKbuI
KhzyR1SP3LmMaueSEswj4iYdWxPf0+cBAAAAAAAYBI8TAgCm6jiq4MpzO5xcR8QqIq7i8DuZzKPa
QeXdHu9xF9UjiOzCAgAAAAAADIYQCwAwVRcR8XmH132PKsyyjir00oVZRJxHxCIiTvd8r4d43MUF
AAAAAABgMIRYAICp2kTESebv3EYVDlmnf5sGRWZR7ZQyTz+nLX0mARYAAAAAAGCwhFgAgClaRMTX
lt7rLqpATP3znOOoQisR+z0m6DUCLAAAAAAAwKAJsQAAU7SJ/F1YSibAAgAAAAAADN5PigAAmJjz
GFeA5TaqXV4EWAAAAAAAgEETYgEApuZiRJ/lU1QBlo3TCgAAAAAADJ0QCwAwNauIuBv4Z7iNiF8j
Yul0AgAAAAAAY/GvHz9+KAUAYIoW6efdgI75LqrgysrpAwAAAAAAxkaIBQCYurN4DLQcFXqMwisA
AAAAAMDoCbEAADw63/opIdDyLSKu0g8AAAAAAMCoCbEAADxvnn7OI+L0QH/zIarAyjr9e+80AAAA
AAAAUyHEAgCwm3lUjx46i4hZRLxr4T2vI+LmyQ8AAAAAAMAkCbEAADR3HFWoJaIKtsxeee0m/URU
O60AAAAAAACw5f8B9/coECSxUZwAAAAASUVORK5CYII=
"
     id="image10"
     x="0"
     y="0" />
</svg>
`;

export const fotossinteseTheme = {
   logo: svgAes,
   colors: tenantColors,
   default: defaultColors,
   chart: {
      colors: chartColors
   }
};

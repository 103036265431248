import { environment } from "dg/environments/environment";

export namespace Api {

  export const empresas = `${environment.apiUrl}empresas/`;
  export const faturasMes = `${environment.apiUrl}faturas-controle-por-mes/`;
  export const distribuidoras = `${environment.apiUrl}distribuidoras/local/`;
  export const distribuidoraAll = `${environment.apiUrl}distribuidoras/geral/`;
  export const distribuidoraLocal = `${environment.apiUrl}distribuidoras/local/`;
  export const acesso = `${environment.apiUrl}acesso-distribuidora/`;
  export const acessosUrl = (distId: string | number) =>
    `${environment.apiUrl}acesso-distribuidora-url/?distribuidora_id=${distId}`;
  export const medidor = `${environment.apiUrl}tipos-medidor/`;
  export const usuarios = `${environment.apiUrl}usuarios/`;
  export const groups = `${environment.apiUrl}usuarios/grupos/`;
  export const estados = `${environment.apiUrl}estado/`;
  export const pais = `${environment.apiUrl}pais/`;
  export const ucsPlanilha = `${environment.apiUrl}contas/planilha-create/`;
  export const rateios = `${environment.apiUrl}rateios/`;
  export const medidasUsina = `${environment.apiUrl}medidas-usinas/`
  export const boletos = `${environment.apiUrl}boletos/`;
  export const uploadBoletoLotes = `${environment.apiUrl}upload-lotes-boletos/`
  export const uploadClientes = `${environment.apiUrl}planilha-empresas/`
  export const userProfiles = `${environment.apiUrl}gd-common/perfis/`;
  export const contatos = `${environment.apiUrl}contatos/`
  export const funcionalides = `${environment.apiUrl}funcionalidades/`
  export const fatorCarbono = `${environment.apiUrl}fatores-pegada-carbono/`
  export const adicionalAccessField = `${environment.apiUrl}acesso-distribuidora/`
  export const temas = `${environment.apiUrl}temas/`
}

export namespace GeracaoDistribuidaApi {
  const base = `${environment.apiUrl}geracao-distribuida/`;
  export const grafico = `${base}graficos/`;
  export const financeiroResumo = `${base}financeiro/resumo/`;
  export const creditos = `${base}creditos/`;
  export const dadosUnidades = `${base}dados-unidades/`;
  export const dadosUnidadesv2 = `${base}v2/dados-unidades/`;
  export const projetos = `${environment.apiUrl}projetos-gd/`;
  export const downloadFatura = `${environment.apiUrl}faturas/download/`;
  export const uploadFatura = `${environment.apiUrl}faturas/upload/`;
}

export namespace GeracaoDistribuidaV2Api {
  const base = `${environment.apiUrl}gd/`;
  export const grafico = `${base}graficos/`;
  export const boletos = `${base}boletos/`;
  export const uploadBoletoLotes = `${base}upload-lotes-boletos/`;
  export const contas = `${base}contas/`;
  export const unidadesConsumidoras = `${base}unidades-consumidoras/`;
  export const usinas = `${base}usinas/`;
  export const usinasDisponiveis = (id) => `${base}projetos/${id}/usinas/`;
  export const rateio = `${base}rateios/`;
  export const programarRateio = `${base}rateios-programados/`;
  export const rateioHistorico = `${base}rateios-historicos/`;
  export const rateioContratado = `${base}rateios-contratados/`;
  export const itensContratos = `${base}itens-contrato/`;
  export const projetos = `${base}projetos/`;
  export const clientes = `${base}clientes/`;
  export const contratos = `${base}contratos/`;
  export const usinaResumo = `${base}usinas-resumos/`;
  export const dadosUnidades = `${base}dados-unidades/`;
  export const disponibilidadeFatura = `${base}disponibilidade-faturas/`;
  export const unidadesResumos = `${base}unidades-resumos/`;
  export const rateioPlanilha = `${base}rateios-planilhas/`;
  export const reajuste = `${base}reajustes/`;
  export const reajusteUC = `${base}reajustes-uc/`;
  export const reajustePontual = `${base}reajustes-pontuais/`;
  export const unidadesPlanilha = `${base}unidades-planilhas/`
  export const contratosResumoFinanceiro = `${base}contratos-resumos-financeiro/`;
  export const referencia = `${base}referencia/`;
  export const parcelasPlanilha = `${base}parcelas-planilhas/`;
  export const energiaFixaContratada = `${base}energias-fixas-contratadas/`
  export const encerrarEnergiaFixaContratada = `${base}encerrar-energia-fixa/`
  export const consumerOfFixedEnergyContracts = `${base}contratos-energia-fixa/`
  export const energiaFixaPlanilha = `${base}planilha-energias-contratadas/`
  export const downloadPlanilhaBoleto = `${base}upload-lotes-boletos/`
  export const faturasDownload = `${base}faturas-download/`
  export const economyByMeterReport = `${base}relatorios/relatorio-economia-uc/`
  export const powerplantConsumersReport = `${base}relatorios/relatorio-usina-uc/`
  export const co2Poupado = `${base}co2-poupado/`;
  export const summaryApports = (powerplantId) => `${base}usinas/${powerplantId}/resumo-rateios/`;

}

import {
    trigger,
    animate,
    transition,
    style,
    query,
    group
} from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
    transition(':enter', [   // :enter is alias to 'void => *'
        style({
            opacity: 0,
            height: '0vh',
            transform: 'scaleY(0)',
            transition: 'transform .3s',
            transformOrigin: 'top',
            transitionDelay: '.1s'
        }),
        animate('300ms', style({
            opacity: 1,
            height: '20vh',
            transform: 'scaleY(1)'
        }))
    ]),
    transition(':leave', [   // :leave is alias to '* => void'
        animate(400, style({ opacity: 0 }))
    ])
])

export const fadeIn = trigger('fadeInOut', [
    transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0, minHeight: '0vh' }),
        animate(400, style({ minHeight: '12vh' })),
        //animate(400, style({ opacity: 1 }))
    ])
])

export const fadeInOutGrey = trigger('fadeInOut', [
    transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
    ]),
    transition(':leave', [   // :leave is alias to '* => void'
        style({ filter: 'grayscale(0%)' }),
        animate(400, style({ opacity: 0, filter: 'grayscale(100%)' }))
    ])

])

export const fadeOut = trigger('fadeOut', [
    // The '* => *' will trigger the animation to change between any two states
    transition('* <=> *', [
        // The query function has three params.
        // First is the event, so this will apply on entering or when the element is added to the DOM.
        // Second is a list of styles or animations to apply.
        // Third we add a config object with optional set to true, this is to signal
        // angular that the animation may not apply as it may or may not be in the DOM.
        query(
            ':enter',
            [style({ opacity: 0 })],
            { optional: true }
        ),
        query(
            ':leave',
            // here we apply a style and use the animate function to apply the style over 0.3 seconds
            [style({ opacity: 1 }), animate('0.8s', style({ opacity: 0 }))],
            { optional: true }
        ),
        query(
            ':enter',
            [style({ opacity: 0 }), animate('0.8s', style({ opacity: 1 }))],
            { optional: true }
        )
    ])
])

export const fade = trigger('fade', [
    // The '* => *' will trigger the animation to change between any two states
    transition('* => *', [
        // The query function has three params.
        // First is the event, so this will apply on entering or when the element is added to the DOM.
        // Second is a list of styles or animations to apply.
        // Third we add a config object with optional set to true, this is to signal
        // angular that the animation may not apply as it may or may not be in the DOM.
        query(
            ':enter',
            [style({ opacity: 0 })],
            { optional: true }
        ),
        query(
            ':leave',
            // here we apply a style and use the animate function to apply the style over 0.3 seconds
            [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))],
            { optional: true }
        ),
        query(
            ':enter',
            [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))],
            { optional: true }
        )
    ])
]);

export const slideLeft = [
    query(':leave', style({ position: 'absolute', left: 0, right: 0, transform: 'translate3d(0%,0,0)' }), { optional: true }),
    query(':enter', style({ position: 'absolute', left: 0, right: 0, transform: 'translate3d(-100%,0,0)' }), { optional: true }),
    group([
        query(':leave', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translate3d(100%,0,0)' })), // y: '-100%'
        ]), { optional: true }),
        query(':enter', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translate3d(0%,0,0)' })),
        ]), { optional: true })
    ])
]

export const slideRight = [
    query(':leave', style({ position: 'absolute', left: 0, right: 0, transform: 'translate3d(0%,0,0)' }), { optional: true }),
    query(':enter', style({ position: 'absolute', left: 0, right: 0, transform: 'translate3d(100%,0,0)' }), { optional: true }),

    group([
        query(':leave', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translate3d(-100%,0,0)' })), // y: '-100%'
        ]), { optional: true }),
        query(':enter', group([
            animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translate3d(0%,0,0)' })),
        ]), { optional: true })
    ])
]
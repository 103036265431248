import { ChartColors, defaultColors, TenantColors } from "../models/tenant-theme.model";


export const logo = `
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:xlink="http://www.w3.org/1999/xlink"
   viewBox="0 0 156.8 37.119999"
   height="37.119999"
   width="156.8"
   id="svg2"
   version="1.1">
  <metadata
     id="metadata8">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title></dc:title>
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <defs
     id="defs6" />
  <image
     y="0"
     x="0"
     id="image10"
     xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeoAAAB0CAYAAABKdnG9AAAABmJLR0QA/wD/AP+gvaeTAAAACXBI
WXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH5QoHExAkNZvrIAAAIABJREFUeNrsnXecVNXZx7/PubMU
lR0E7LEhgmBHxZYo9o4liooiKCVYYkkxeU1iTEwz0cSCJbtgQcWGRizYYo+9K6Lggr2juCt1d+Y8
7x/n3Dt3hkV2Ztvsen98hpmdue2ce+75nadDggQJEiRIkCBBggQJEiRIkCBBggQJEiRIkCBBggQJ
EiRIkCBBggQJEiRIkCBBggQJEiRIkCBBggQJEiRIkCBBggQJEiRIkCBBggQJEiRIkCBBggQJEiRI
kCBBggQJEiRIkCBBggQJSoEkXZAgQYJyxlX7HsWEB2/L++4fu51ASrIigCKiCKqCKqig1oIRo798
8trvVV/9fqfR/OHZ5dtsJ+zKBW/0ZXGmK/U2xQUvXNXo/kM3+iXrrrqIqW9ekQy8hKhXDFVFRDrt
+drrnC19Xc1pg6q6wVdGfdDc9pRLW1Z2LeU69gpx8dBjOPOxm/O++9fQY/sYWEOQnkAfVUkrdFdk
FVUxCqhKxipLLbLYKrWq8nVGqVNkgaJf/OGZaxvix/zFdmO58KVJHXYCv3iPYznz0ZvyvrvoR8d1
mb+kW596a3pkrPSoR7qpSo8GK6kMIlkVtUp9VuXbjJXFCousldr+Xyz64u/fTM471kH9z+DeOZe0
S9sO6H8mgjKjhPOP2uIUrpvZeRYboqrnt+P5vwU+Ad4FZonIgracUOLnUFUBtgc2B9YHurTAKbLA
N8BHvo1vi8ii1myjJ8EtgJFA/co294u12cCtIlJfJFlvCxxexIJPgG9E5MJyJDdV7Q78HOja1F19
v81s7/bEFz+q2hXYB9gS6A58CvxPRN7oSGQNcNHux/auMLq3gd2BQYpsiLI2SDdFsEpOkkawCFbd
91bBIsuyKl+p8rFVed+qzMoqz1rVF/7+4tXzw/Octu14Jr5S1WEm7suGHsNPYwuZC3YdudaSTLBj
vTU7ZpH+mazZoEGld1alT8aablnomrVCFiGrYFXUwjKrLLTIAlU+s8j7annTIs9kLS/NqLlkYXj8
ffufwYNtTNiH9j+N6XMmckD/M4Ya2EdQG4giAoG4ycRg3d9udhEjugSVqhvevPzLcVufQvVrnYOs
RcMnvH2QBZYBS4HFnjBuBiaLiLb2xBYj6bOBE4C1gVX8RG1a4jRAJtbGRcBbwDRgiog0tPTE6W/n
cOCWInZbDJwjIpcUea5RwNVF9pUFjhKRO8qFMGJE/Xvgd/jnvon4COgb3ssyIOnjgPOAtfxYDvz4
+xZ4DBgtIovKkawv33M4pz5yKwCX7nFMPyP6OyMMFegtyqp4AtaIjAXrpOg8gs564o6+g+g3VdGs
8q0itVnLbKtmOl3qr7z4uWuzHWHCvnqfIznpoWkATD3gkO6fLkyPWpINjmywZlDGymoZNT0arNBg
hazmXhYha12fuEVN2I+ubxTCvs2qUpdFvlF42SrX3zvnkuntJWEftOnpG4nhOQNrGlEMYAQMihFF
AJH87wz6+NVvXDWUToT2JuoV4RvgbOBGYLGfgFpkYikg6EHA3UDfdmhjHXA+8G9gYUstTEokaoBr
gAkiUl/EuUb5/Yq9MbOAIeVEGKq6DTAd2KCE3dcSkS/KoA1nAheuZKHxIbC5iHxbbg/9DSNGU/f5
4t6i/CkQnSAibgUYEktIOgpZBGtNKDVjY6Sk4LeLk3mOrG0kVZqIqDJIVUPW/DOjMnfKzMszAEds
djp3vH1pWfVR9V7HGCS75tJM6sxl2eCMpZmgW0YNGYWMCg3ZgKwKGQ37wP0W9l+40AnJOiRpBdT6
RQ3hQoew/75S+DVw471zLlnSplL1gNP3NaL3GKHC4MhYPGFj3EAX1BF1+D0smPT6lb06E1GbMr2u
nkAV8AKwn6oGIVk3e2Xij6OqRwMvtxNJA1QC/8CpxCeoamVcOmoHBLSdz8IA4MzwfrS3NK2qKWBs
iSQdak7aux17An9sgjZgfeBBb+ppd0za5ygArtrzyB5Lvlx0ZhejH3YP7IRuRukqlgqxBOKlJ1GM
UYxxqk9jLGKcVIU4utGIlHPEnLVCxgoZ9S8rnqQ9uSNYNeMR3gamHbv5aTsf0v/MijvevpRDB5ze
rv0z7YBDAXhl+G7mun2P3NqiVyzJpD5dmk39qt6abop7ap36P35Lc5+dtBl/aew7jb6XUEKNkaEn
v94GqoGL9u//8zbljAqjmhJIoaTEkjJKSiAwUCH+O9HYy1JhLJ0NpsyvbxAwA/i7qqabS9axfffH
DbyuZdDG3sAVwDRVHezb2Nm98QPgFFXdrL1J2i8UdgV+0hE70i80ugKjgR5N3G0H4Jj2XBhOHHoM
N+z/Y8Y+dBv/3nv4Nt1Sels3sf9axWS7dzGWlH8ZowQhOZscYSPqCMrTs0X8akm96juUnmlU6g6J
O4uQsYas9Spz5FBVeTxl7D8O6X967+mz20einjHsAACOvG86dx54yIDXv1nz0mXZ4OH6rPlJgzV+
7DpyBfW87Ay3ElMJiyfk3GImR+Ha2CrTq5HFbyX5q/d1DNkubdkPFaJUGEfQgbhXODZSoqSMUmHc
d4FYAoGUJETdXtf4M2CKqnZrDln7fdPAdUVMam2FfYBHVHWYiLNIlKdVosWwLs5xq93aGZPmJwGp
DtyXqwEjilwoDW8vs0PVPsPpm67j+PtvZ9I+ww/qkWp4uHtg9+tusnQJslSYrJt4/eRs/ASdU1+E
ROzesxisGrIYR7oIWeKq29B2TZ4UnVXIWuPeCQkcrFIhcIoIe7fHzbxv2IEceNd9ANxx4KGnfZvp
+mi9Nac2WOltVTwJq5N8o7FMjFgdYRsF8c+WAcTvqzHqDT9rjKmt5qvGrfv9PYW/zJhz8dI2nfz9
GEiJUhGoJ+ycNB2IEoSkLfhx0/kmy47UpGHAvaoqzZSs/w2sWaZtTAPTVXWciHQYz9xmYKyq7tEe
4XGxz78D+nVUadr33SiKc4AD2AbnFd7mGP/QrRw4fQY37H/4CenUsmndTLZXN5OhIsi4ydcogVfJ
hlKhoyWDaoCqwRKgmkJJ5b6zAVaN+6yGrDVkrJC1EtlrszHidqSds1mHUrW3g9cp8mVb9suLw3cH
YHCvj3nw0P17TT/okFsW29Rl9dask1VBNKaiDqVpyVGuMTay4aZEMUa8dJwTiyMpWnN/h+aCLMYv
ZpyXr1vQgIXzlsAmM+Zc8sKB/c9o07FSYTSSnFPgCNsTdCoi7FDS9i/pfAJOR1t77Alcq6qmFLJW
1Q2BoztAOy9W1SPbU9psQ9ygqt3ao62quiXOrtshEVvgnFzC7hsBg9v6mmccchAAdx407LDKIHtZ
t1S2W9cgG0lHofeusz17ZbZGcmIUmhNEXr9uH4le4jXBuX9EJB8L5fLSeEhSaokROCjy0N2zL3mk
rfrlhaN2Z/tbHwdgVu3ag+uzqYeXZCuGZ6xBw4laQMRGtmUhp5oO1dXG5GzREtsmJy/nCDrn7e00
DK5fHElblaUWHrCw1b1zLvnDw3MusQf2P6OkmObmwNmm8Wpv60kZT945wg7JOijQviRE3X44AeeB
WFRyDo9xHaSNqwBXxWzWnZmo1wUuKOZ+tgTBqWqX8LwdGaq6dTM0Avuoare2Gl8PDDuQA+++l3sP
OWinCmOvqTDZyi6SdbZFb4c2nqxDgjUIQTRhh+pO652InK0yIHyplyglZDUQk9MLR2JlzNPZq9Cz
5IV0LVLLLwCGtYEE+eLw3dnhNkfS//vxPqOXZFMPLM6mtmlQAY3yrnn7fK6PjJeqwwVL4EKTCIwW
qMY1JkHHFiph+8lpFzKuP15SGGW71B88Y84lb0SLrHZIfOJU3aHPQsxpLCJpjcZP9J3pfPNlR7XL
naqqD4jIS0VKHod2oDb2Bm5S1c1aO6a8DDBSVaeLyCNtaDf9MbSPDbKFCDrsp5824zBH47zvW93u
+NChB7LP9Bk8cMiBa6rodCO2ZyAahQEpYFS8B7OXCI1iNYsGhqyNycjiZQxVsAKBol7ytAjW4tXj
IRuLI2fJERQxCTKuBlcLKpxz15xLPga4qw3IKZSknzlqr1MXNqQuWJpJrZpVF5qm4tsgmrt2Uf9V
mPzD/Y0qKq5d+ZK0+xT2s/WvrMZMAI60l4jo2cC0u2Zf+hnAIQNOJ6vSLiQNEIjNLUjIqf0Dck5z
puBdEom6rKSwCaHKtIkTWxoX9tWR0B/n6NTZsTpwmqqu2pokHUqOqroG8FugoiOTtM+kdkQzF+oj
Wltr8+hh+7HP9BmOMgx3pMSumZJsFAYUxD2T1UmKkfRslIAsQeTV6yTnQJQAG22XkqymJKMpsZoy
Vo2xXvKMuUvZnC9zmMkskqy9dJlF3rxr9iWXDhvQ+pL0nON3AODt43aUl4YPHV6fDSYuyQar2oiE
cm0wQp7GAb+NIywiojLkTAa58eJJ2sYXJTlJOqOowBOrdMlsNn32pRNDkga4e/al7UbSEFdpk3MY
ixwMiVThkdOhl6oTidqN6a9YeXrKFZ2vEujWAtc+CvgXLnlGU7BGC0zMy4AFfkG6QgHen6cHLRP+
dZKqXiEiL3Wk1I8l4HDgAFyYWmu3cywu9K9DItY3x+LMJM3BKcClrdnfe9z5AACPHLr/eJHsEBOP
efZ2YTzhBKFd2kuQoooVcclBjYA1nnvsAjXytbX6tVGWBIa3EVCxS2xWu6roaiq6oRVTKSK9REkD
aVvoRBWb1Vw+XT0F4K7ZrUtO88f2o8+kFwDI2OCwZTa4ZUk25drslAROa++vywCKRSUXmmUUrCii
jrzVayS8eO1aQ34yk6wKDfEMbjCna8qee+usibcADB90GrfOmlg+BBWq+wnV/DHbu8TjvfMl7ISo
nZrsl8ArJey/Ci6pxFbAQbi82qWiAjgeOKeJE3tzE3rMAG4D3gYaVkLUqwEb4jxr9wMGNvM+na+q
B34PvMAvUtWHRKS2FaXQwcCfOkl/7dsCi8EBqrplmAe8tfD4Yfv+ALGnG9EKR9ISEYoghIprp9GW
SGMt6uzUWRWwpk7FzlDMs0Z504jMGffwLR9813n/vtMR8oXtPRBMX0UHgmxr4YcK66M5G7UntNtU
eX5Y/zNaXeX9xaI1gRpmH7/jgbXLut6yJFvh2iiKUYuI8ZxrCRBQLyt7NbdRwUqo/laMut8CVbJI
TkNBzkJgfcKXrAooXxjRy3t2abhi8utXzYewkMXEshrggQEj1ps8NErQEpJ09H08WUsnnBhLIWoL
vCUirzVj0pyKS3U4geZ53Z6Jy1Hd2v10FXCWiCwtsp03AP8ExgC/b8b5dwSG4nI1d2ZsgHPwmtDS
UnXsWNfQcU0+8bG1iV/wtgRObY0+j0j6iL3FYA8Q2Fw8SQvq1VKCEQvivgszbYUJSgTFqoDIVCv8
KcB88OP771jUlPP+eocxnP3sZPVat1nAPWO3OmUVq6xtVXbPIr+zsLGz3coC4J93vXNpq9vr543c
nr7XP817Iwdvs6ChyxVLsxUVWR/jnBLIGkWd6zkpwaUAlZwGQtWrudWFXxlvw7ae6F0AWhD6un9r
hFkB+uEyTdUqiKALu6eyF++w0RfvnX7vtEj8LMdqUymxLibcmwEkT7J2XvBCLotaZ7VRl+pMZpo5
aaqqfiki56vqf4EnSryW7l7SnNGKfVQjIieX2M4sLrfyear6Js7eXFnCoXoBe6nqY98Dqfonqnqr
iLR4aIwvvrJ5J+mn7VpAUxPiaFU9Q0SWtQZZV4h2IxZCJjhbaaiizP9fvMSoiJjQxnTYftNnTAe4
b9jBTT7v316YvNx3k16/YjEwD5g3cotTplgbHIKyJ/DAnbMvfba1b9qCsZuy+qQX+WjUNj1qG7r+
vj6b2jDrpcLAWL84EWxgEWvIWAgkXNZ4Bbhf1BgsgRhvJvBEZYWU6MOY7M0Vgf63V7elH571+M2N
FBy5km1Xf6rNBuvckYPZ5PqXS5CobSz8TPNU3BIrzBH/PVF9tyBihTaeUdUf46pmdS/hUAcBM1rR
rnmBn+RLOn6sstFt3vlncon9vosn7K/p/LhYVYeIyNIWLMYyAGePDTpyx/jx1A3ntd5SWAU4Dri6
NZ4hI7o1sG1ExKqecjxpx04Z2mStCGAbRGX0PtPvn/7fYQeQDZT9/nNPi1zTsYNO4/qZE7PAnf7F
kQNPJ5uF/8xpvbShC5a6dfqybOr4jA0Oy2jgbMzk0n1G3uriQtY0q56sFVGLEUOgghqwFh+axVy1
+lD3IPOXXz51w4fxc/511+P5v6duKLiSk/nZ/1q+fW+N2IGBU53tffbx261tBDa9/qXPSpVyU97D
MM8GjU/4QizuXvK9vxOibmGy9u93qerVfiItdqbYIX6sVsADzTl+rFIXIjLFV+z6VQmH2gnopapf
fw+k6kHAacCFzW2rJ7YAZ2bZsKN3jF/g9gIObMHDdsHZu69uyWt9afhubHfrExjRn4knaA3t0xJ3
d5Ioe5aKOmkbg1W5cM87H5j6yGH7seed97VoP97UiMPUtLdaN6/3/JM2o8/VL/HhCVv3W5wNLm6w
KU9CLp7K+vAqsYKIIaNKYEEDRa1zMHP9pliv8g6wnyrmKjF67c+fvLFRe/3yJN3yeHPEEDaf+jwD
p77AWyN26GaECaI6UkRT807YdmTfKa+8/u4J27DxlFeLk6h9XvfQmz1czBgTkrYvzgLR351xdmx3
W10sLOSfQClORK2dDnRRCx/vfOCdEqWezb4HJB0S6+mq2r+5Y8v313Y0L9643LAnzmGxJbGVt3u3
GLa79Qk/2do9RbIY8eFGxmUeC/y7q4hlfcxsKD3Z90R0KsCe3mu8o+O4Jy4GIKupG+ptqktoq3eh
ZDZXWMJkXeiZt8tGyVyiRCc2DEm6x4jud/oTN/3xzMdv+gBcsZO2xhvH7sjmU58HYOaIIQcDj4Ne
KMJgQbcSuAkomqQBjMllrUsZdSlDo4IcvnALRBnJjFhMUj2rVaXqecDDJRyi0meZarVLbEFJCGAx
UKpr5a58f7A+rsKWaU4RFo+r6eAq74JF7WmtcPiBfkHTonjlmB/9QLC9TS5bNz5Td+QEFITxwp6w
fKz0u7vd/tDMzjKYPz9xcx6o2Z9PRm81oUHNjtZnMgnJN0p/iY8PDqwvNpFb3KQiMoKU2MvGPnzb
IRMevfUNgEl7uXKhpz12c5u267Vjd2LLm57j9WN3rJw5YsgkA3eKMAQIBBsmhRv03gnb/B/AB6O2
Lur4FVH8tI+XjlXRCnBq71T4WZTAl0BNiLp1cVuJarsOkbjCF9pQ4FkgU8IhNuL7hTOAnZqTkENV
z6ITOJDFwsv646IAWgNHtHRKUVEdZLyk4yRm6712bezlpEZDSExWA9E3AZ4+cs8OP4gXjO3PWte8
yeJxfbtnbOo0G1a38gQTJXKRXPnGIMp9bqM8134hs7RC7NnHP3j76Wf98HZu2PdwAMY+fFu7tM1a
s9prx+50rCA1gowRNIiX2CSnsh7/4agtV9/gutf4eHTTa8FEpS2DXIrQIL6wiVVXyyVCSSTq1sar
pcwFHfHZBT4pYb91+P7helXtUgxZxzKQbQn8uZP1x9mteOwDgV4taV4JJLuuCSVpCaVpR8rulcVI
1qm+TUToEkhmbYBdpj3S4W/Y6pPmAFDbsOrRWeiHDzszYjFBNlJtB4SZtbIx8s6RdoAuqTB2/FH3
3fmPWw88jJ1WncLxD/6nTdvy4tE/zE3Wx+y6v4jeJDBVRNcInbsgHtccZk7THwgyGmC9a5sesl9h
YpoG8BoH6xc4saxkvnZ5KinK0WYE9n3A0hLb2o3vH/oCfy9Wc6GqAS6xSffO0Am+TV1x2dtaCz2A
PVr2ujUIImnapQINVdyhPTEwmidVum1041eP2XX1zjKIvzppYE+LOUZVuhKlBfWLFhP2gyPp0PYa
FqLwKvBMSuy5w+65+/o7DjyU4TPu5OgHprd5O7a/5X+8ePQPu7989A9vUuVWEQ7Or9KVKwhClEVM
ETQlokd9PHqLtYqTwjRf3W2cett46TpMFRsUVNBKiLp10elrOsb6PUWCpmKkqu5WpAr8cFxWuA6P
WJsPwOVFbyosxftDnFpwzmYOdJ0vYpGIiLOkJBvlZE6FzlKetEK7bErspkZ0h85w/zKnrkG9Bpsq
MlTISdOhatvnKieQLBXGkjLZfHW4WFIme+/+d8+4EGDtVb5t7yZdKnCMiO0Rr9AV5hiPcpF7L20i
EmdngS0+H9308P+4ettEhG2j71KiVISOiORKYSZEXZ7oaKS3CtCnhP1q+X6iF/BTVe3WFLWsqq4J
/B8tk2u9LKRpj6OK1BA8DPy7yNPtrKobtVShDmN0dqj6zoXQhKpwi/iaTm7CtznSFltpxI6eeexO
qwHMPr5jcrae2pOvl6whguwHdCWKBbY+YUnMDm0sKbJUSMa9TMbbZfk8yNYfB/DwYfu1uzlAYBWi
WGZiubjj2cHypOl4drFhKsZ8flLTyDrfJm1jdn1XAjM8X7wmdVI9q4znsg4mHa0LrFXC7p9+j6Xq
w/GxwysikNj3o4DBnanxqropsH2Ruz0iIjOBmiL3O6flHkx9Jwh0Sb7qMqfqdlJRNpevWZTAZEm5
sJxjA2PPn3nczt0G3OCSaNQcv12Hum/zl6zLmlfPUuCkSMoMq3+ZbGSPdu317TZZupgMFV6yTklm
xG53PbboicP3Zq8yCFUL0HNFbENUlYyQHDXfgSw2OUc55tCRoBVrXf1WEUQdal88GZv4d96JzORr
aRKiTtBckobSk1XM+h53X4Ar2tG9Mak65hU9EPhLJ2z/tkC/IrZfDIT12m8q8lwHqWpFSziVDbjx
hazBPhbaYSMnslA68l7gQSy7VKTCdGR9ZjdT/0DN8TvsCdDvhpeYN7LjrMHWuHoWX40ZsIPCxiFR
5+yq4cLF5lThxqlyK0zWSdSSuW2naY8+ct6mZ7Hbf/7b7u155ehd2eaWp+YK/DGXgKRAii60UUuY
nRFEdHURu0+TH/qYtiGQmD9D9O5fxO3Yidd3a6MrnRh+4usN/KzEQzz5PV/rbASc25hUHSOVm+hk
9n/vRLZHkc/rHFylN3CFSIpBL2D/79JeNOkCjt8+lIouDiRXOMEsF1pDLvMUuTSQgUQhObsZyd4/
b+Tgq+YeP7hH3+tf5v1R23aY+2c1ONZNtj5mOvT4DhckPiwt8LbqlMnSJcjQ1WTsKkH9+QAHbvtK
eYxFCe3QOkWED0xMijYxdXikPZDlK1oZYSTA/DEDVk7URmPqbxrzhPfkTKx2dyJRt7akWcrTJ7hY
6o4gSUNpseIAHwOftmSMazuhntJiyEP8WlV3aMyGqqoTaF5FqaVl2mc9gCOK3OdVEQlzPn8BPFPE
vt1wta6blZq3/w0v8t7IwRiyjxnsq4FkvU0xtM/mCCqUtnM27PyJNzBaERj9SRDoJ++P2vZnQnb1
muMHRxdXTGxuO+AgJ2Hm4shzixAnHXqHsYiEKkyGrkH9HYi+CzDk1sfKoiGDb346ZI5PBHt73OO7
IG46Z58mrGhlQ9LeA6DP5NlNkKjDGGnr1Nvh30TjooCwbeL13QY4uhQeLOMJNpL2VLWLql5I6eEv
zwDzO0EK0bnArc08xhWq2jVO1qraF5dDvTkdVA0sK8MF3l4Ulyp3KXBP7BjLgIeKPP12qtqvuW3Y
6PqXqQhMgzH6L2O8/TlQTKCRmjuMrQ6M+0xE5rEJ2H9OYVcz2ItE5LluqcyvPh695baQi8399MTy
ym1TN7ZvJbBxzuEqluoy5gmf8/DOeM/4TDZlMo/3v+GFheX4EG9547MZgSdEWAguFSx5dul4feic
/dpPX2vMH9O/SXn3QyI2ebHTOQ/waDEXhrgl4VmtNxn5CXcjXGGAUqSgsiNqVY2TSHfgCkpXeVvg
KRFZ2AnGXBdcDfLmSNXbEiubqKopXNGNjZpxzDnAFCBbhn32iyK3/xb4b7hIFJEM8DzQUMQxNqGF
HPLWufYVBTtdxD4exg9HduoorjoXpmVwUqd7xbeLTcjYTQX9q8D0T0ZvccvHo7bYAWCda96kqR7F
bYGsprYSoSL0as/Z521B7Ljz9k6F4VlGF2Ssea6sBRD0GYGPRHLVv+I26rhtOk7enrSHNImow5A9
g8vrHVd7m5i3fCxkyySZyVpH2vQ4g9JCluaLSH1rcm6p7fILkCOBN4ExzZD2PsJX8eoMEJHZwI3N
OESAC9fa1I+fgc1YBIX3+A5gdpn1E6q6IcV7e78oIrWNLERqiuzjPbx9vNn4wXVv1AboLwxaZ9BY
uE2OiCUWN5xvs7ae5LKRlG1M5Ii1fmB0eBDok5+dNOjpz08aeHjco/izMe0rYSsyiLANfvERT6fq
nOYyPo7aEjgHMjJW7l7nmlkvlPNzPHDqC5+DvmOWU3nH7NSS8wqPx1sLNMlWEUnUcd8FEzeJ5Ag6
iJlTOhvazekmXmdYVfcHTiyRyGYWHq+FsbOq1hZxbeIXHEM8OfdpgWt4QETeasU2tgeuBQ7GOdeV
gr7AWFU9B6iieUU3PqH4eOO2ej7GlrD7lEZI/x1VnekXNU3FMJzz3pfNbc9no7dg7WtnvvjZiZv/
UuByIBUugV2RS+tLOLq/XElMKZAoNO8plNgaWqAr6M7AHV+cNPBLQf8iovdC5nOgrh3v5DaOqFyi
E9Xcyl/iMdVGySosrq/4xhiOW/eaN2foH+Gd2dvT/8YXy3jRrQ8KHASYuDOg8/i2eZOmxOO10G0B
Foztx+qTar5TkpS8etOu9wLJz3xm/Gd/7ISoW2ICikvSqnoIzjaYLvGQ0wsk85aWpu9p53v0NS0Y
11pG0uJjqvoY8ONmHObn/rHfqZmXc6WIvKeqPctMmha/2CsGy0Tk5hWQ/sPAoTTd+XJdYChwW3MX
iWtfO5PPTtxc1r7mzaovThzYxaAXIdJFG3MK9JKTIqj6WtWeyMOJOzchS97E7K9wDUX/BVwAPD5/
zIC7BB7rPXl2VI3r67Gb0mvSO21wH+mbv6DI6d8gAAAgAElEQVRQf8WhzVbpajJ8sWRVnv5ifV6Y
v+6Uf704acZnJw1Czp0FvFjezzHMBNW4p7V4vwO/5Ipi5AsWV32B7yTpkJBzmoi4DdxGiVbiEruT
ujsdT5es+tbmTEB+ElpbVS8Hrqe05B8hbmndcdjuOFlE5ncyaTrEuc3cPwB+2cxjfOIn9LKSpj2O
wIVKFYOpBceIj5v7cPHVxS6GWuhhUv1s9CDWvOatiQgnIHZB3GZrfKrRqMIWLiEIsUlZxIJYL0WF
2c00z2mJnCdyF4F9BL0M9O6vxvS//eux/UcCEUnPHzugVe5h3bi+4QSyjimwT4u3s3c1DaQkywMf
bcLkOdvy1Gfrk82aeQBrX90xUiYYdI6I0xPIcnHT6h0Dc2rvmBTcJJNKKh5jjsbMBWEWMu+7EBbn
8OMnkagdeXVT1W4l7J/Chc+MAo7EhZ00h30miciyTkpi4FSEt9FJISKzVPUSnH9Ce+F072xVTv0S
fjyY4kMPp6xIwyQiH6jqHGiaI4/Hjqr6AxH5qLntWuuaWcw/aTMA+lz99i1fj9n0HZDpAj+I5GPN
rY9VnEQWoCjGkTROVZ4npUp8kZMvR8R+28i99JCvx276T+AvFq7qM2n2km/Gbgx0oeeklnNRqKye
F16RCSVLIvuty54179vVmTpvC+rqu0akJPBZR3qG1WitgYxAKj+3d06ClsbNFUHd2H7dKyfVLPnO
hUB0z3PJVYhs4u7+BrEQMNNJVd+lSNSrAI8CS3DepcW8FgCP49LpVbaAxHrliialToDngPPCUddJ
2wjwW9rPa/9J4M5yjE1X1fVw3u3F3Pj5wOsrkdKnlXA54wql9FLR52qXg+XrMZvSa/I7L3dZUreB
Ef2joAuMOBVqTrLORvnADdk8J6V85Z7GbJVusjYxm2b+iwoDfQz8MwWffzO23/lg1lLNtLgZ8A87
HtVFhS6IRoRisCzKVHDLu4O4aOaOzF/SHdWYChe7rCM9vJbUMtClEluERGRcIE1LTP0tYGwT8tbn
J8cJNS0unWjgHcjEh2sZyVezf9+JulxwO/BWJ0gA0hhmA2NEZD6dGF4TspDmq8BLm2Nc+cxsmS6C
BlOc4xc41faixp6JWBuvK+Faxrb0YrHX5HdYMGZTVpv6mfac9M7vTWAHC3qJEX0x7jkscQ/xXL7o
vHcpIO3cK34cCoheAe3hF4qzA8O5deM2GRT+Wjduk5LbdkD/M9hrk7N6v7DgB7+pre+yjqB0MRkW
NVTw5Gcb8NfXduXhTzakwic7CW3VLslLWYYHrlj7Y+tFQAsWQwW5v+P3SuPidRMIKpYDnlgcdZTw
JJZ21jjiToi6fDAfuFhElnQiSTMcXW8Dw0TkzU6s0o/Iw5PKdcAbbXz6R4DHyq1/ffx9V+AQild7
PyAiy1bUJj+evqD4VLR9VPXwlpKqQ6w+OefMla6a+17PSTVnGbGHiegwY/Tu0ImI0NYploBs3qQl
eQT9naTciHQdSeBp4HfA/XXjNjkLoLJ6bslkLUC3wA6pt3JufTbo0TXI8ML8dbn0re25+d1B1DV0
oXuQiaS/eOiRSMdKo1xhgpSIdpUY8YaSs8mPm46pw0EEFVm59sBJyjZmf9Yonj4IfwvzpZMLf0uI
ujwI7XoR+V9n4y3gTmAnEZnD9wiePK5pw1MuBC4vxwQynmR74Lyzi8E84NUmbjup2PnYLxxabeEY
kmKP6nkfp6tr7hb0SBEdYEQvknjpQlFEsvkOSnHSjhi6QMIrkLhjWbLiWB+4sG7cJvfVjdtESiXr
GXMuIRBdrMCXS1fjr6/twlVvbcuHCysjZyghv5iFiUKQispAVwYDNttDoAt5Dn3ESk0qjXjlA5pJ
V81btLLDB9hYsQ0ics7ljM8Veolqnic26rLAcyLys5Ze3bczvgH+ABwhIrWFIWydnKTDj9NwiWHa
Am+IyJ1l3C1DoOgJ+21WktAk1tePAsWIHQJsq6rrtlaDK6vnFn5Vn66eO6eyeu4v0tVzReAXIvqW
CHWO3GxMihakUI8qhWv7eAWnlc6J+wOP1I3bJF0qWQu6bLVUAxe8MYS3v+kdy1tOLo+5kZg0HdVz
7teRnl8DfcMulViBjrCvoz6PL6rcH03KkmdM2Dc5D/+wPrUh5/2fq8CmoUdiQtTtiCdxeY/pJGrh
BThb+/4icp74Zej3gaDzplF3Lz/EJS5pC5xdjgu92PWcVuSuWeAFEVnWxHN8RXFFOsDZy7dvq74I
ibtufN/w74vS1XMHCXqAiE40oq8asQ2mwAYafs6pYptM0IUYCvy7btwm3UqaWIVFRvi2m8nknNri
6l/J92SWKO+5K0x07k6jy/qZ/XBUVPtmK1DJyzwmulzFLHyhFb+IAVdkiNqVLIJC58IwG1mUYpac
VB36MUhYkYxE9d2euAsYLiKLO5Ht9re+Tc91Mg1BqZiIU+G2Jq4Tkf+V4xjyNvv1gAOK3HUpPsXs
ysaQb/NSnJmlGHQFDvTFZdqsTyqr3HAIpdrK6rlPV1bP/SlwiMCPBXuRiJ2VI2jNt0037ireVBwN
nF1ZPTdaMDT9XrJQ4NNQes5l1sp5o5uoNGRcqtah5+08Tv747LUdZZk91IiaPG9vwCxnftBCTccr
AOnlNSl5CEI1N/lx9sbYmAo8/F6j94So2wfXACNE5LM2nmBb+44fKCL2+6Tq/g6CQpw+8/RWPFUG
+E25ahU8flrC7p+LyDNNHUO+n5/FmVyKwaFAZXuM00LVeGX13I8qq+feDfwfsBPoYHH545eu2C5a
yuDUP9SN7zsgXDAUMbF+a0Q/ibJqeUkyHj4mSGiXjqR9I4rV+kPK/Zld/7rX+WDUVqsb2CLe0SJx
wtbltBix+Oo3m9aPubzoJpKc44scG8sFnvu9syFVxtdmcSq6M0TkpnAya+NJQrwKbGW5vocAV5Vw
/INUdU8ReaSze3gXQdb3quojwJ6tcJqL8DW9y1Ga9jiqhN2vLuH5eAcXBrhjEedZG6f+vr9c+q2y
em4Dzt75CnB87biNTwJzBHCqwHY0IVb3O0g6/HQ5sPd+m57ZLRBtmDHnkpWGUFnV2grhvdA+a2Ip
LgWNsqyFGoAAjUncjMVpEMv7mYXtRXS9wqxwJi+9q11ODe4/P9skoo7HXhekIc1LW9qouj2RqFsb
rwH/BDYVkZvCkpHtNLm+KiKvisgrK3rh6is/XuLxJ36fpekVkNU50OKGpg+BahGx5RiS5d93x+XX
LhZTix1DIvI5pYXEnVagASgb1I7bhHT1u/Xp6rk3p6vn/gjYG6eNm1v08jxfKttr8r4HHm1Vrge2
acoh7nh7YoOg8wQ0gCgFqonVpc4lZiGXWtOR9d5/+uHxvcv5WZ173NYiwg+BXqHneo6kw8+2YMxF
knYdmNlNG6exRCZ5GomcGSFX1zz3d0LUrYc5uExjhwKHiMgvQw/oMD94uWodRCR0Cmso4fgDVfWk
cp382om0XvOLnxY7LHCLiMwtxzbHxvYIKDqO9jURebfEU9+PyzBYrBZo7XJcWIb2zgJ79kl+Tvkz
sGyl5Cz5avNw8PSoWHZDVs2RwM4ru45jBjlfQDG8YUQXisnVZzZRpSfykoPESdyIdjcqZwD8bdfj
yq6fPxq1FV1S2gs4Mifp5ic3Ee/NJ3mOfdHbc5XVNRrmQ/9uiTpniw77T+K2afLjrMX/3tlQiup7
Ga6IwTs0r7SgAouAz4EPcOrlJfHa0h1BHRy7xqtxNZE3KuEwP1XV/3jCT6RqWKqqVV4iaokyoV+F
motyHVOqugGuClixF7euqj5ZwqJbgdUoPqkKODv6b8q1LytjhF1ZPZfK6rlv1o3b5HfADOA/xEPf
miB9CbBJj9rUKqkGsiqDV7b9zbMmhj38vDG6QNAeIRGL5KtpJSZhu6pgEfEdc8GuIyb/6qkb3y+n
vv1w1Fb84LrX+WjUVj8WdNDylcEa8b6nMPlM0wspmbwkKVqQNEWXy/keLnoSonYOOTNCT+VWILzG
JI2yJhZ/7YtU9QKvFSgWWwDDKbOayO3cr4+q6tO4msjNxVUi8n6ZL/y2Bkop5bSGf7UljgF+U+7P
Z4EDmlZWz326btwmP8LlQ+/a1HhbAVZN1dOr61K+XLJKk+KcR25xCtfPvPyTE7c8eaaBDSRWOzn0
8DYmFpolsVAtJwFtasScAJx/8e7HcubjN5UFSa9/3et8OGqrHsA/4nHTkleCUhuJm87r6xvd/Vm5
g168yEbUfwVx2XGveVjuXJ0Cpo33W5kk1ZGlQETkKpwttJQF00hV7Zmov/NMAH+hNHNCHF8A55fz
GFPVCuBH0GHSR66nqj8quFdlT9pewp4DjHQd/93jIfBEPqu2N5Pf2Yovl66CiK7TlPNdP/OKkFyu
DEkmsk8Tcy6Lq8ElFl/tMnBN+NduIzY/8/GbmDj0mHbtvw9Gbc3610X1XiaKaGWedzexOPXQXl0g
TfvtbwIamqL2dvuEsdE5+3Q8/WtgXAIU400X0km9vjujlqC9yeVnJR5iV+CgWP7r77M0HWopngOu
bebhzhSR+nLtU39d3SnN27u90AVnT+9QC+xYlrH/AC/n00g+upos7y+spGr2Nlzw+o68/tUaIdEU
Ze67+o0r7zGiX0Z2acHbUvNs0nnStIk8xVnXGJ0IcNpjN3PlHkeXg1Q9QYQTCkOw8itjLa8Cj2Ea
kG2KNB1K1PH46Kj+tK9B7c5to2QnIYEnRJ1gZRqBuyg+61OIv6lqkHiA5+FsoL7EfR/3k3LZEoq/
rl0ozbeh3S4b2EVV1++gYyoLTG78frjsV/95fwB/e2Nnnv58PTLWUGEiB6Um+5H8bKsJgGCEy3Mh
WTHJWdWTTFy6DiXvMJxLh14+9Ogpju+yVO85vM07671RW7PBda/x/qitdwF+H7evy3KlLJfvz5g0
/RbwSlNJOpSoo+Il4qXnqB/DNKLkx6l3wkkwIeqWRz1wYYn7/gD4SYGE/n2Xqr/B1eUuBRfSfrWu
i8HpHfAW9aeJoUrlJlVXVs9V4I2cMB2aruDdb1fnvJd3Z9p7m1GfCXKkmZPSmuxh/8/XXWqFAPvX
QFgaZiMLiSWSCkOpGhupdo3g1N9Oqhx51R7D/3zyY9MY98itXLPPkW1D0Cdsw8djtmGj617jg1Fb
9zXoZBHWlsJSogV51WW58qMRpldWzysqQiGsM53TSORShcYl6CjczdX0Tog6QZMkpP8BzzdDqq5M
VOB5ZTCvwkUZFIOHgKc7QNTA6hSfMrQc0A34kbevd0QsA+oFl6byiyWrcce7A7nojZ34fMmqdAsy
uQQlkpeJtKjCMSdvM55Vg2y9iP5BIhU3Bc5Xy4doxVXhuO/PnrTXUb+atPdRwYkPTeP6fY9o1c55
94Rt2WjKq6w3+VXeH7XN5jjb8mZSWHCD/BzfeUuf2DbAJ/jEPE21T0POph8SdKSZIBeLngt1s41K
9QlRJ1gRwXwBTKE0lW0Pytz5qR0WPgtw9YKb6lj2DfBbEfm6zG3TACd04NtzBNC9OX1czKTdkggC
26XC2C71NuChjzdhyjtb8+RnG1DhHZTyVLv5RF1UtMuVr1bx95euxqDTjOgHkU0a8lS6eVnL8sK3
Io/wlBH+nBI7cfK+xwQjH7yDqfsd1mr9s/GUVwCYd8I2hwF3A0Pi1xd5eufl9KYgbjpvXNxZWT3v
HafVaLrq28TSh8Y95E1kk47n/CZKfpIQdYKmTsBTgFJjIA9W1U2T3syRtYjcAoxrwubfAgeIyPPl
3iaPMzrwrdkE2LmYBWUhMYeTdlsQ9l93PZ7f7TTKDZJl3YfNWrAG1W9vz5OfbsiCZd3pYmxk44zI
UzUuAX8jrpxo0bj45Uk1IvxbBCsxQgltrVElLQljqXNSd44ANTDChK4se2nqfodtOeIBV1dl2gHD
WqyP5o4cHJeqzxOYIujGjcYtx1N2FhBzgaf3AuCsUu5zziPe9UkQL/QRW0RFanC3fadj6hQJWnwC
9rbVb1X1auCvJRymLy5c69xEss4tgETkOlV9BrgOZx8NtWCKj+8HRvuY9vbOaLeytqCqe+PyZ3dk
nIqv3LUygq6snkdl9TzqxvX9C66YxixcxrD7gW/rxvXNVFbPa9FJ9so9jubkR11+jf976gauGHp0
cP4uI39z5weLfvnBtz3JqKMCI+qyU6t4CVEiD+wsPmmZ6KOifFnsNfxi8BgufHkyInK5wY4Q2NwR
TS5zV5QQJfyMIioYY3Mq3hyhb22El27d/9BzKoy9UkQXx1ny/mH7s/9dTUvH/uaIIWw+NbemzVgT
zB05uC9wE9jt4rHJElMr55W1LAjTCj/HMKKyel59OAaKm0/j5/Hvkk/ieYsDt32vz07ccqeKisxz
mYxJhWHXufjrwutbfsFRWKJTGllAeNiek2Znvhk3gJ7Vs1vtIRMtXm+1CNgnrNbTgSb6AcATxLMS
NQ19ROSrZpz3Y0rL3/wRMLTYtJf+dg6niOw/MQ3A+KbUNI6daxQul3IxbDhXRPqV2JcR8arqhrhE
MWngS+AVEZlfuF2Rx++Jq5O7SpG7rikiX5ZwvonAyR1cs6VAL+/0t1KirhvXdx/gQXJVG8ClMX0Y
uBdXXOND4NPGSPvj0Vuy3rXfnaJ8yj5HcMJDd+R9d9nQY3tkrOyyLGvOzSC71GcDVUQy1tBgIaNC
1hoaVMhYIYt/939nkAZrOenO2ZfdUEonnb3DGP7+wmT+b8hJhxjR/6TEBsb4Mo44b+aUl+gDgcA7
lgUxb+fAWF9NKvysBEbfCrAXGqOPNywK5h3+yN1Rnz10+P6kyLDHf/6bdy2vHLMz296cP32/NWKH
tBG2EbGjA2F0rtJXGEpmY051GsupHa8/HY+njoh1amX1vONKIWmAD0dtu48bF1pRSLR5FUxluUXC
MhH9L+iySLpfGVHLioh5hUQdAB8j/Kln9exPE4m640qAAKdQfO1fcB7gxwJ/+r5X1oprK2Kf36cR
00JH6StV7QPsUAJJv0rx+bmbigwwGFi1SO3kccDlK+r7GEl3wxXbKRBK6A4c7F8LcQ5bs+vG9Z0D
zG7Qipr6+mDeOlNm1a2MpIGIpCftOXxVRLfLWtmlQXVPkD2M0ZSxkDJWMtYQSrZGXRWYKPGICgbF
Ik6iVZmVFbmn1I79+wuT+dWQMfz1+cl3/27H0X9E+EOeB7hovjQddyqLSdPLFaSAgcBkgTndV2t4
4sFD93suEH05a8zMfe64v1EfmZCkXz92x3VF2DZAdxTRnYDdROhKjJBzUm08bjruOBavnFVIgjrL
a05Kf+bj2c9i3wF5ucVjoqd/oLQrwkHLlyOPe6RrocNbARE3oVSq89L/ktIjUxKiLhNSuc9PrqWE
spyvqhNXJq0kaJzMyxzbAJsXuc+XOAeu2la6psW4bHBnFbnfz4DLm9D3pwGbrWSb1XClN3fEaZ2X
GOxSSQVLPhi19eL6bOqdejWzG7Kp9zOaqm2wqSXLsoE22ID6rOneYIOeWWs2q1ezXYPNrNdgg1Wt
0MNAyggYJZfdKvTCVrBR0QzBAFa9bwTR71XT376sWc9hyocNBYa/CWyHMExQn0o0nr867umsBbnB
Y8UpfBlJ44p+9Af6i+jxIiys0MyiJw7f+wsT2JkB+okR+5UR7WbErmmw/Y3opmBXE1hV0NXceiBm
N49SdcbCrSRf7by8Gjovbnop8LvK6nkfACVJ01BQytITcSGR5nubF15jIeFq3o/LE3RjhL0igo6O
3ermq4SoWx/1frV1Z4n7X4XLrZygk2ha/HO3b5GSK7gKbR/HC9e0wvVdUAJRr/tdddW9NL0pMKbI
OScAVhPR1YyvkmSMHRBkzcEZUZcwBEsghqz31rYKVtQRcjRxS5RcxEjg95NcCJR4UtZQovXe1kpo
q376trcmXtHcvv3z89cAcN4z19b/cdeRJxt0AwPbRJW1TOzcBaRtClKQRurouHTt9u8G2s1AHzF2
Q4PukEuyYqP2IvH+8dxj8uOfl5dmc2rvfGJr1Hr658rqeXeUqvJenii1kBzzSDouZTdNgm5MYl65
BC3Le7NDGyQXT7y+20bC+x+l16s+SlW3iE3yCTr+eKgEio2tyQB3tDJJh3Wq7yty1y7A4Y1pNOrG
9aVuXF/BpUjdrKQ+C0k6JmGG6SRz0p/mYm2F/O2isB4hHstsYsUk4irlyIHJEfnXKvbHAEcPPLVF
+vm8XU/g3Keu/8TA6SJ8lC+9xqXpwkIUsSxmRvMqSOV+LyD7UPL1GbxCidSElbrI8yrPEV9BKU6i
7ZePm47Uy7lbX11ZPe9PLeLJH/eCJ18dnytzqTkTQWxBQTydqWiu5nf8OMv9vQKSjtKkts8cnBB1
20zOX1F6vWpDElfd2TAIKDb87hVc1afWXkQA/KuEMTpEVXsXkrSXpjYCzildqtIonCmIfc7lgfZ/
Q6O/RcSsue0klqM6L2lGZCtWBF0qoqNvevPKz0Zsfgq3vHV5i/RzF68C/7+nbnjSiJ5ghG+kIE5Y
cvm+c+2IS9eexPIKfBS0IZT+glgceCiJ5zzb4w5h8SxpOZV3/HricdOF0qf/7rIKGibEtSnNGpNR
m2zeYiyfnONkm79wIy/NaeP5yKUxgo6XJBVdTiXeuNSfEHVHV3WCKy7xcYmHGaqq+yS92WnGwphi
dwWe89JuW+AFiq8CNwjYNv5FbJK+iuLV/PkTlVN75xdo8JWV4tK2UwFrlCAjV/QilmYyJEONqY1F
czZpJ7HZQPS3i4V7Rm1xMlPfvKLFOvecp27gb7seB8Avn7zxUYG9jPCV5JFGvFJUzrEsrKxFQe7w
nHSsMVtxqKa2OWm4IK94lHdc8sOdQgl5uQpZcSKLE7b74Qrg16tUf2Cbq/LOLR5tHtGGHvBCY+rq
HDnnS8sr+rsRko1VMGMl5Jzrr9aXshOibgNVZxhXDfy9xMP0BI5R1a6J+rvDj4UAGF3krouBewrI
vjWxCLityH1WA/ZR1VT8GuvG9e2Fs8c3U7IKs1FZH7KU84aOpOh4dizJJRAxBVmzwqpLOXV3TtLy
Um0mEC6oeu3fF936+pV63cwrW7yDf/3UjU51sfuxnPXE1JcD7I9E9H0TK9qB2nyNQUz6N+RyXhNp
GnIEmucwF070y7W1QLosUDEblvf2XkH8sQr8O10999R09dzFteM2aRGSzpN+pbHwqgL1dV6WtMYJ
WlqEoPNznbcFEqJuownav1+Jy3lbCkYAWyY5wDu8NH1WCbt/LCIPxMdSK4/XBmA6xRc0ORRIx6+x
snre18CTLULUeWpvm5deUoxGMcfLv+Iqbl3eWSuSskGELwXOvPyVqnNOGzKBCdv8pFX7+qzHbwLg
1Mduecugewk8QWG5y7gEG0qXsTSnuUncxtqVs8+HBG0otMHnE6CJkZmJyDn3notHjtuq+QY4I11d
MwGgdtwmpKvntuBYJM80sRxBhxK0FGRKW4EETYsQdNsjIeq2n6h/XuIhugG/SXqyYy/WKC2392Vt
KE2HeBN4sch9BuDiekNpOvx4OPBTnJNayY3IkbPGJOVQ1W1j9l3NL94QVqUqiEGWQlITfUhEh13y
cvXlP99+HNKQ5apX/93qHX3FHkfz7z2GM+HRW+eKsYcJ/E1EG+KhUlEua8izJUPcTt9ILm6c63zO
zlqQnKTQiYx8co+IOe/3SJp+Hji856Say746cSC14/q2KElDY6p3bSRsrZH2xB3zCkh6eduzfic5
tydBh0jCs9p+or4Dl9h/xxIOc5iqbi8iLyY92iEXa4Nx6WGLQRaXNa5NnQlF5CtVfRZXK7uYBf0o
4H9hmJa3VX4FTKwb1/d6YB2/WBkP9C5aqo6kaIsR4whMtREVcbyAgw/P8g5UcWczo4IRXWRVTlOY
duGLkxf+fLsxXPRidZv19Sk+xWn1Xkcx7r+3Lbhm3yN+a1TuEaFa0IHGaJ5N1hi7XGxzfn3ofKkZ
sQXSYzyWnAJP7+W9u/PCs3LhSX8H/tJzUk0tQO9r3mq9sYjNE4mbF/usKzkX7U7KiURdHqindFs1
wKRGpPQEHQPH4LJwFYPpIlLXlvc6dq67cPbqYjBWVYNwUVFgq6ytrJ73dmX1vHNwSSL2wJU+rKMJ
Rj+hEbV2zFYbRBm94r8TOSDlOZK5yW++oL8V0T5/fPbaa7sF2YW/2fEkLnppcrsMjnEPO7eAzbp8
nR310O1PAdsZ4R8CNmpnXCMgRDbquMc4kbRt8+zzhd7c5IWENSKBFhQG8YT9MrB1r0k1v7JQu2Bs
v1btExGVnGqbRpzBmugcthLP7XIm6VCizpSwwu+oDJEtsr2plh94gqo+BTxLLvtSMdhKVUeIyNTv
kLDC+5Mpop2lVFsPi2E0VdQLSmhvpxgjqtoL2C3WZ0099j/bQZoO359U1bnAlkU88ylc2tzLCn+I
V8qqrJ6XAR7zrzF14/r+CBgGbO+l7p64ePPuhdJVnvpbNCyY4YnMIhLEbLkWyQbee1gWg3wJvCei
01ataKg++ZFblwGcv+tIfvfUdWUxAHe+5zGm7nc4Ix64Ywlw9rQDhk0W4W8Cu4jomiGp5Idl5Xt8
5xzKIsLLkZmXio2POc+RnM1XkUekz1JgDlDda9KciQBfj92UXpPeafW+UGSmwX4MrB+OwaZK0CtI
TtLoArBkYd+d4KVWfyZVdVyR+2SAGW0YKtJSUkJP4BCcrbep6ApUF1Oooojr2cVPgKUQ5Icicv93
SEN9gf1pety28Q/iEyKSLaINAzz5FDOw60Tk5jIdI11xeauDInYLgGtFZOlKjr0Gzvt5lSL6KosL
68u2dQx9rMLXTsDWRYxTAT4SkRkr23BFITx14/quA2yMi7/ewH/uC6yjyFoNtqJPfTZFvaaoz7pX
gxoabIqGbIp6G7i/s8FXDdZ80GBT8xqsvFFvzayMyhtj/zstKlV56dBjOf2xm8p23pp2wDCOvO8u
p1o5+KAhKdFDA7GHi+hApyXwXvBeJSWGbDQAABA/SURBVO4KddiYVsF6jUPoAW8LnOzc/rnwp1j2
MnSxEXufEe4Ae3+fyXO+BvhqTH96T57T6m3/cuwg1pg0i6/HbjYYlxffNomkpenSsTRP5jS4tL7T
gWxldestXKS5D3IHIelmXWtLtrWljhWqJ1v6HjT1+prTjnIcO605RjpqUZW2vsffQdxdcOFf3YHu
DbaiW322YpV6TfWszwaV9TZlGmxAgwZan00tbrBBbYMNvqm3ZmkmaxY1WLPwyPvu/jZ+zKuc81aH
uyf/PWhPyQRd1jOiWwaiRxmxRwZCD6dlCIk7i0hoCnBE7BKf5NTfgYkRs8S96i2CPi2it4E+bETn
rXn124sA5o8ZAECfybPbpK0Lxm6GBXpPervs70vduE0pS6JOkCBBgnLGo4ftyx53Ptjp2/nkEXsN
CYweK9gDA2PXF7RbICoSSdlOxR3E1N2BhOlGbYMRXeTJ+XaB2w22bp1r31SAz04cyNqt6CiWICHq
BAkSJOi0ePyIvenNEra446nou5eG79JNCfoGYjcQsesHoquDdg9EuxrUiuiSQHShiH4uYt838O5G
U179rPDYn47enHWufTPp5AQJEiRIkKAl8NxRuzdr//dHbZ10YoIECRIkSNAemDViSPT57eN2SDok
QYIECRIkSJAgQYIECRIkSJAgQYIECRIkSJAgQYIECRIkSJAgQYIECRIkSJAgQYIE33PUju+XdEIL
9Fvt+H5t0pcd4X4lCU8SJEiQoPmT/WrAnsAn6aqaF2vH9yNdVZN0TBFkma6qoXZ8vzWBHwLvp6tq
Xmqrfqwd3y8AVsXVslhcbvcuKXOZIEGCBM2b5AU4HpgGPFQ7vt96CUkXh1h//RVXf31K7fh+A9uw
HwcDrwATccWYEqJOkCBBgk6GjYAKXHnO1ZPuKGnBY4BNvWS7LtCnDU/fA1ehrT9lqGlOiDpBggSt
PQF/px0w/ltT7IWN2TKbeh3NvdbGtk9X1ShwFXAv8Ot0Vc3MUuyexbSjJdocP06p517RfsXeI9+P
FjgHeBz4W7qq5snWHkOxbTX2blewTcn3rLlIbNQJEiRoK8LujlMxroOzBdakq2pmxibqcLuNgRTw
npdOd8CVuPwoXVXzbOH2seOvDWzjpaOFwMvpqprPC44dAP1wtdrfBwYCA4CvgefTVTWLYsdbA9gO
V2LzM+AZXD379YB6fz0ZP1l39dI06aqazwsIKPy8Pa6+dgZ4KV1V83Ht+H6hJD4/XVWzoMj+7Orb
uz6udvkHwKvpqppsI+fe3Le7C/Ap8GK6qmZpfDuvwl/ft+ULzw87+/Z/DLyQrqppiG0fP/4eXgJ+
O11V80ZIYI1styGwuZeav/X36IvYMSv9+U1j/Vg7vl83YFt/D7LAvHRVzWuNtHcDf453/H3Zwbfj
k3RVzVMFx0wDhwLXAS8Bo4GF6aqa92LbVPi+WMMT+TuNjd2EqBMkSNAhpWk/0W0BXAJsAfTyk+wX
wAPpqppxBfs8DqwNPAVsCQzyxF0L3JuuqjmxkCxqx/cbDfzCE82qwGLgI+Cv6aqa62Pb9fKT8Zc4
m+Sefp9vgPHpqpq7/DUcDJwPbAysAtQBDwDPAmcA84DR6aqaTzwp7QZcD7ybrqoZWtD2VYGrgR/5
tlugBrgUOMaT94XpqpqqIvpze+AC3zer+2N+C/wL+FuMJPsAl8VIJvBtfRv4Q7qq5tHYsVPA7f6Y
jwGbeVKs8PvcDfw8XVVTG9snAG4FhnoinA/8K11Vc2H8HvnzngsM9yTbFVgCfA7sma6q+dgf7+fA
eODDdFXN3gVt3gq40I+JPn4MfQk84e9FfBFxl18U3gzsgVNpd/Fj6LF0Vc3wWBtGAf/w/bME+MTf
x3387zv6824GpH1ffwrcBfwmXVWzsLWfo1QylSRIkKCVyXoV4H4/QQO85clvQ2Bs7fh+q6Wrao6N
SSYbefLq7yfj9/wkugYwunZ8vyfSVTXXxCblA4Br/LGXAa/ibJ0DcU5J76erap7wvxt//I28lKX+
HN8AL/jrHeQn4VCQed9L6SOAw710ny2YP1fx1xyqUONS1iNAWBnjG//aErjcH8PQBLt2AWE9Ezt/
vSehNb12INxuLeBBfy6JtXUt/5peO77fvsCz/lrFt6EfsInf9kN/3DWBMcD9teP7TYu173fAYbGF
wrrAP2rH97s9XVXzbuyarwLG+uvN+lfav1aLNXN1f/6goO1rAjf5RQTAHN/mjfx9SQMHx/r8B368
/cz//a4n9zWAo2rH95uQrqq5yv/W03+Pv7eb+HuC13jcC/T2/feW334j4HSgrnZ8v/OAbGtK1YmN
OkGCBK0qTXvJdD0v4Q5KV9UMSlfVbARMAJb6iXPnRia6V4C10lU1/fwk+6D//qQCIrzWv08DKtNV
Ndv7yfQm//01K7jER4B10lU1FcDwdFXNp/772zxpvQb0S1fVbJSuquntiSazkmZr2Hb/Ps6T9BLg
l+mqmtXTVTUbe0J7xs/BGif4FcETngEe9iT9ArBtuqqmq5d6f+sXAeF2ZwNbeSL/PdDVt3UPYK5f
fEzxhFeIT4DN0lU1fdNVNat5yRTgEJxaOtxuX9+GS9JVNb2APwCbF5D0ib7vGvx1VPhrHgi8uIK2
Fy54hnuS/gzYJl1VM8D34wl+cbabV78X4kOgV7qqZpN0VU06NoZOjm1zA/AT//llYEfgIP/3+Z6k
PwEGp6tqNk9X1awHjPK//xpYs7VV3wlRJ0iQoFUQm7yO9+93AOna8f12rR3fbxc/Sc/3pHhEI4f4
D/C1n/AbcKpwYpI5teP77eSlvQU41fS2teP77eql5adwtuqNasf3K6zvaIEbQztouqrmdX+8IZ4Q
FgLnpqtq5sbsrZO9pF1M20O1/uPpqpoLY79/6on1q6YuejxO84uWL4HT0lU1r/rjabqq5s/AFX67
dYEDQrJJV9X80fch6aqax3A22fle8/DDRk75YrztnszAmQLiJtPw+g+oHd/vcH+uWQV22/PCBZO/
DvXX8ba/xi+b0AVj/PstQPfYGHoVp4bu4RcNhbjJS73h3894ab5P7F58hTMFgDOZvJSuqnnL27kH
++8fBTL+vLt6Lc+zfsH0w9Z+lhKiTpAgQWtK1WlPpKEkfB9wD06d+ABORWk8ARTiC09A4d/feom2
IrbNNv69ErgIp2K/xx/7Lzi1qsE5MMWx0EtnhdjRv3/sSSBuZ8Ufu6lt741Tqaqf6Au9l5/G2emL
If7w+ualq2qeLzxmuqrmHb9tTy+xZnC28EIv5rm+jwB2b+SUrxT8vcS/dy9YOPwaZ2ceBEwFXqgd
3+/omFPXGjjnwTqcjTvvOtJVNfPTVTULmuA9Hd7nsQVj6DGcCYUVjKHZQHwMLYxpPbrHtgvNCBIb
X2vhVOrg1PtP+vPeA0wHtve/DW7t5yixUSdIkKA1EU56S3Gq2q9XsN1zjXy3pAnH7xbyIs6pKNvI
NhanAo2jfgXHD683g1OpFhLl4iLaHlcp1xYeK11VY2vH98sU2Z9dY/35XR7HQawPlxScl9rx/bKe
tMDZ1wuxrKDd8fCl+LHerB3fbzPgz8ApOOezG2rH91s3XVXzr1gfZPxCK+86Gunf74L142TBCoTO
52vH9wtCr3ePBaEEHyPipjpRp2Ic+QSwqJF9u/pFT0LUCRIk6LCo80RhcJ7NMwqkzh5e4mnMc7Yp
E+rH/v1TnNf2lwXHX4v/b+/sQruuwjj+cW7NiPiV7cLMiOSIsRxRFGUvIiW5CwMRTXoRLOJ4YdKL
mpYXChaIhMEuDH9kVKOLhHKxMdoSLyKZkqKWXoS/optqolaHuXA2ty6e58fODv/5f3H/mXG+MP7w
f3me33nZ+Z7zvB04ozm6pcj+RV9vRgKrTgdk2FRG23/3iP2+kJA0UCkpsz9zP/o0Z82NSZr1Be2t
Q3zB/Yhpu0FPo4cDOVMR/zVIYFa5lhK/LX1Jmq121uxEfNlzgNecNS36DANq2ZiD5Ej7cmryTUsR
lecQX/H2JM26Ahk3ALV+NHqZc+hyczdP19uZpFlHoLde9fZXO0Urmr4jIiKqhiTNLiIm3npgjZrC
84XuJuBr4IizZkmFKvbrSesuJK3HX0gfQ/Jo25w1M0uU16mL83TguSA3dwYSvFQOkX2jZLFQU9R8
rETMwuXA9xWvy4lOdd6JBF2BmKNzn/5uZ43xvnc9sBZJ2RpCTMjljivOminOmkeBLc6a2iTNTgIf
qMwa4JYkzQaQ4LfrgFXOmqYCfTCjBJV79HWdzhu/LZ3AD86apeM8fX/yNm6bNHsh1zsViaE45qx5
uNrBZPFEHRERURV4ZPUGkrvcDOx11nTq2rOIEZ/rYCU6kjQ756zZhlS0etNZMwuJ3J0OPIMEGd2N
Z3ou8rwXnDUbkdzjVcBtzpoDiA98CZIyVhKRKbYgEcJ3AJ9patOfSCGVZYipfbiM9h5y1mzXPl0P
POCsOYyYr5/STdGnSZr16Qn3ST05dzlr9iHm7vuRvG+QgLnfKhzi25GIegM0Omu+BV5Qkv45SbPc
/74aeEitEZ/rc/TquDyN+NKLYZeS+gKgQ/OkJ+mcmjfeB09vLryH5No/CLQ7a7pUzyLgEd2UXKz2
/1Ik6oiIiGqdpvPAoaNKVh8jqUHzdJHNF9bmwJzpB/b4mMxov2GOrUrMK1XP84z4aE8A8zWy15dZ
G8r3yHUXcA8SuLQUyZ2ejPiFW4EVBZqby6oLZJ521jyhBNqI5B0PqbxuxCTeQJA3XGTjs0nJfQMS
Nd3s6T/k6e521izXk9/M0OIA7ADeDcy2tWOQ3qSQM5I0O+Ws+Qh4Wzcxi/V3AzoO+TP/6qxZgARh
zdK/IU/HcDD2NX4/6hw6rhun3UqQc4PnfDZJsz2enLox5lCN9nVdsfZpGzucNa8gxXoeRwq75HIc
sFw3oZGoIyIirl2y1oX4E2fNMSWLRsSP2gO0JGn2R0AWbyE+zYOBuH1IwNLfgY4LzpoXlQiWKWmf
AdqSNGsNSO48UlnsPBIRXAj1+p1ulTcN8eNu05PhigIEcxItgFGAXHvUbP464i/+B2hT0l+M+Kl7
Sj2lJ2k2CGx01nypm5PZSo5HlMh83e1aWnWNngrrEXdAa15L24u4vqSbnga0+IuHH4FX9SQ85Ml/
x1lzEElDu1U3RpuTNDsbVI87oab/l5TwEiQ3uR2p8pZjL1Jgpj/c8GmFuaOqq0mtMN8hldDOBnNo
q574jwft6FKCHdBx8Nu3FinDOui5O0jSrMVZ061zYrb+7gCQJmnWG+8fj4iIuOZxJRcqjHUxQqUX
SpT43lfOmi+cNXMLfLbeWTPsrPle61KXJLPS57tS+VdyKUex/r3cuFZyKUexMR7vOVSK3FL6MCIi
IuJ/RdjVXOgqvVWrgJxeJePNBT5r08/2X41nG6/+vFr9Ph79UI2+/C/q9BEv5YiIiIgYvQhvQMzc
fYg59MMkzS6peX0HElj2cpJmOyfi5qSIiEjUEREREaOJegoS/HXvGF85hdTBHoq9FTERiHnUERER
ESMkjd7TvBB4n9G1uP9CymTO16piscMiJgT/AqHCpAuqhjrHAAAAAElFTkSuQmCC
"
     style="image-rendering:optimizeQuality"
     preserveAspectRatio="none"
     height="37.119999"
     width="156.8" />
</svg>
`;
const tenantColors: TenantColors = {
   'primary': '#1e194f',
   'secondary': '#322a7e',
   'btn-primary': '#ed6429',
   'btn-secondary': '#FC8C15',
};

const chartColors: ChartColors = {
   'chart-color-1': '#1e194f',
   'chart-color-2': '#322a7e',
   'chart-color-3': '#4f266f',
   'chart-color-4': '#ed6429'
};

export default {
   logo: logo,
   colors: tenantColors,
   default: defaultColors,
   chart: {
      colors: chartColors
   }
};

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GlobalErrorHandlerService } from './global-error.service';
import { ServerErrorInterceptor } from './server-error-interceptor.service';

//https://medium.com/angular-in-depth/expecting-the-unexpected-best-practices-for-error-handling-in-angular-21c3662ef9e4
//https://dev.to/buildmotion/angular-errorhandler-to-handle-or-not-to-handle-1e7l

@NgModule({
    imports: [MatSnackBarModule],
    exports: []
})
export class GlobalErrorModule {
    static forRoot(): ModuleWithProviders<GlobalErrorModule> {
        return {
            ngModule: GlobalErrorModule,
            providers: [
                { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
                { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true }
            ]
        };
    }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SESSION_STORAGE } from '@ng-web-apis/common';
import { isBefore, subMinutes } from 'date-fns';
import jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthApi } from './api';
import { JWT, Payload } from "./models/api/jwt.model";
import { Credential } from './models/credential.model';
import { Group } from './models/groups';
import { checkRole } from './role-util';

@Injectable({ providedIn: null })
export class AuthService {
  constructor(private http: HttpClient, @Inject(SESSION_STORAGE) private storage: Storage, private router: Router) { }

  login(obj: Credential): Observable<JWT> {
    return this.http.post<JWT>(AuthApi.login, obj)
      .pipe(
        tap(this.setSession.bind(this))
      );
  }

  refresh(): Observable<JWT> {
    const token = this.storage.getItem('token');
    return this.http.post<JWT>(AuthApi.refresh, { token })
      .pipe(tap(this.setSession.bind(this)))
  }

  logout() {
    this.storage.removeItem('token');
    this.storage.removeItem('expires');
    this.storage.removeItem('modules');
    this.storage.removeItem('groups');
    sessionStorage.removeItem('identityConfirmed');
    this.router.navigate(['/auth', 'login'])
  }

  getToken(): string {
    return this.storage.getItem('token');
  }

  getModules() {
    return JSON.parse(this.storage.getItem('modules')) as { gd: boolean; mc: boolean };
  }

  getClientLocation(): string {
    //const client: string = 'demo';
    const client: string = window.location.hostname.split('.').shift();
    return client;
  }

  isLoggedIn(): boolean {
    try {
      return isBefore(this.getExpiration(), new Date().getTime())
    } catch (error) {
      return false
    }
  }

  countdownToExtinction(): boolean {
    try {
      const expires = this.getExpiration()
      const timestamp = subMinutes(expires, 2)
      return !isBefore(new Date().getTime(), this.getExpiration())
    } catch (error) {
      return true
    }
  }

  getExpiration(): number {
    const expiration = this.storage.getItem('expires')
    const expiresAt = Number.parseInt(expiration);
    return expiresAt;
  }

  isAdmin() {
    const groups = JSON.parse(this.storage.getItem('groups')) as Group[]
    return groups.some(item => item === Group['admin'])
  }

  checkPermissions(permissions: Group[] | string[]) {
    const allowed = JSON.parse(this.storage.getItem('groups')) as Group[]
    const canDo = checkRole(permissions, allowed)
    return canDo
  }

  private setSession(response: JWT) {
    const decode: Payload = jwt_decode(response.token);
    const modules = {
      mc: true,
      gd: decode.acesso_geracao_distribuida
    }
    const expires = decode.exp
    this.storage.setItem('token', response.token);
    this.storage.setItem('expires', JSON.stringify(expires));
    this.storage.setItem('modules', JSON.stringify(modules));
    this.storage.setItem('groups', JSON.stringify(decode.grupos));
  }

  private setUser(response: JWT) {
    const decode: Payload = jwt_decode(response.token);
    const user = decode.user_id;
    this.storage.setItem('user', JSON.stringify(user));
  }

  getUserFromToken(): Payload {
    if (this.getToken()) {
      const user: Payload = jwt_decode(this.getToken())
      return user;
    }
  }


}

import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { IconRegistryService } from './shared/icons/icon-registry.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-loading-indicator></app-loading-indicator>
  `
})
export class AppComponent implements OnInit {
  //use this to select angular material theme
  themeClass: string;

  constructor(
    private overlayContainer: OverlayContainer,
    private iconRegistry: IconRegistryService,
  ) { }

  ngOnInit() {
    // subscribe to some source of theme change events, then...
    const newThemeClass = 'aes-theme';
    this.themeClass = newThemeClass;
    this.iconRegistry.register()

    // remove old theme class and add new theme class
    // we're removing any css class that contains '-theme' string but your theme classes can follow any pattern
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item.includes('-theme'));
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
    }
    overlayContainerClasses.add(newThemeClass);

  }
}

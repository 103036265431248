import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CurrentUserService } from 'dg/app/app-shell/currentUser.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private user: CurrentUserService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    return this.checkLogged();
  }

  /**
   * Quando o usuario esta logado, retorna true
   * Quando o usuario não está logado, redireciona para login e retorna false
   */
  checkLogged(): boolean | UrlTree {
    if (this.authService.isLoggedIn()) {
      this.router.events.pipe(first(event => event instanceof NavigationEnd)).subscribe(() => {
        this.user.acceptTerms();
      });
      return true
    } else {
      this.authService.logout()
      return this.router.createUrlTree(['auth', 'login'])
    }
  }
}

import { AbstractControl, AsyncValidatorFn, ValidationErrors, Validators } from "@angular/forms";
import { from, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { getPostalCodeAddress } from "./helpers/via-cep";

const cnpjPattern = /^\d{2}\d{3}\d{3}\d{4}\d{2}$/
const cpfPattern = /^\d{3}\d{3}\d{3}\d{2}$/
export const cepPattern = /\d{8}$/
export const installationCodePattern = /^[a-zA-Z0-9\/.-]+$/
export const energisaInstallationCodePattern = /^[0-9]+\-[0-9]$/
export const cebInstallationCodePattern = /^\d{1,3}(\.\d{3})*\-\d{1}$/
const upperCase = /[A-Z]/
const lowerCase = /[a-z]/
const hasNumber = /\d/
const hasSpecialChar = /[-(){}|\[\]`~!@#$%^&*_+=;:'",<>.\/?]/
const hexColorPattern = /^#(?:[0-9a-fA-F]{3}){1,2}$/

export namespace GaValidators {
    const cnpjValidator = Validators.pattern(cnpjPattern)
    const cpfValidator = Validators.pattern(cpfPattern)

    export function cnpj(control: AbstractControl): ValidationErrors | null {
        if (!control.value) return null;
        const isCnpjInvalid = cnpjValidator(control)
        if (isCnpjInvalid) {
            return {
                'cnpj': 'Não é um número válido de CNPJ'
            }
        }
        return null
    }

    export function cpf(control: AbstractControl): ValidationErrors | null {
        if (!control.value) return null;
        const isCpfInvalid = cpfValidator(control)
        if (isCpfInvalid) {
            return {
                'cpf': 'Não é um número válido de CPF'
            }
        }
        return null
    }

    export function cpfOrCnpj(control: AbstractControl): ValidationErrors | null {
        if (!control.value) return null;
        if (!cnpj(control) || !cpf(control)) {
            return null
        }
        return {
            'cnpj': 'Não é um número válido de CNPJ'
        }
    }

    export function cep(control: AbstractControl): ValidationErrors | null {
        const value: string = control.value
        if (!value) return null;
        if (!cepPattern.test(value)) {
            return {
                'cep': 'Não é um número válido de cep'
            }
        }
        return null
    }

    export const cepExists: AsyncValidatorFn = (control: AbstractControl): Observable<ValidationErrors | null> => {
        const { value } = control
        if (!value) return of(null);
        const cepFormatInvalid = cep(control)
        if (cepFormatInvalid) {
            return of(cepFormatInvalid)
        }
        return from(getPostalCodeAddress(value)).pipe(
            catchError(err => of(undefined)),
            map((data) => data === undefined ? { 'cep': 'CEP não existente' } : null)
        )
    }

    export function installationCode(control: AbstractControl): ValidationErrors | null {
        const value: string = control.value
        if (!value) return null;
        if (!installationCodePattern.test(value)) {
            return {
                'installationCode': 'Não é um número válido de código de instalação'
            }
        }
        return null
    }

    export function installationCodeEnergisa(control: AbstractControl): ValidationErrors | null {
        const value: string = control.value
        if (!value) return null;
        if (!energisaInstallationCodePattern.test(value)) {
            return {
                'installationCode': 'O número de instalação deve ser o formato 000000-0'
            }
        }
        return null
    }

    export function installationCodeCEB(control: AbstractControl): ValidationErrors | null {
        const value: string = control.value
        if (!value) return null;
        if (!cebInstallationCodePattern.test(value)) {
            return {
                'installationCode': 'O número de instalação deve ser o formato 00.000.000-0 ou 0.000-0'
            }
        }
        return null
    }

    export function password(control: AbstractControl): ValidationErrors | null {
        const value: string = control.value
        if (!value) return null;
        let resp = { 'password': [] };

        const concatResp = (newRule) => { resp['password'].push(newRule) };
        if (value.length < 10) {
            concatResp('Mínimo de 10 caracteres.');
        }
        if (!upperCase.test(value)) {
            concatResp('Ao menos 1 letra maiúscula.')
        }
        if (!lowerCase.test(value)) {
            concatResp('Ao menos 1 letra minúscula.')
        }
        if (!hasNumber.test(value)) {
            concatResp('Ao menos 1 número.')
        }
        if (!hasSpecialChar.test(value)) {
            concatResp("Ao menos 1 caractere especial.")
        }
        return resp['password'].length > 0 ? resp : null;
    }

    export function hexColor(control: AbstractControl): ValidationErrors | null {
        const value: string = control.value
        if (!value) return null;
        if (!hexColorPattern.test(value)) {
            return {
                'colorControl': 'Não é um código de cor hexadecimal válido'
            }
        }
        return null
    }
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TawkService } from '../shared/services/tawk.service';
import { CurrentUserService } from './currentUser.service';

@Component({
    selector: 'dg-app-shell',
    template: `
    <mat-sidenav-container >
        <mat-sidenav #sidenav role="navigation">
            <dg-sidebar (closeSideNav)="sidenav.close()"></dg-sidebar>
            
        </mat-sidenav>
        <mat-sidenav-content>
            <dg-topbar #topbar (sideNavToggle)="sidenav.toggle()"></dg-topbar>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
    `,
    styleUrls: ['app-shell.component.scss']
})
export class AppShellComponent implements OnInit {

    constructor(private userService: CurrentUserService, private route: ActivatedRoute, private router: Router, private tawk: TawkService) { }

    ngOnInit() {
        this.userService.fetchUser();
        this.route.queryParams.subscribe(param => {
            if (this.route.snapshot.url[0].path != "dashboard" && this.route.snapshot.url[0].path != "contracts-management") {
                if (Object.keys(param).includes('dateRef') && Object.keys(param).includes('date')) {
                    let queryParam = { ...param };
                    delete queryParam.dateRef;
                    delete queryParam.date;
                    this.router.navigate([], { queryParams: queryParam, replaceUrl: true });
                }
            }
        })
    }
}
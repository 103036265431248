import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Subscription } from 'rxjs';
import { fadeInOut } from '../animations/animations';
import { LoadingIndicatorService } from './loading-indicator.service';

@Component({
  selector: 'app-loading-indicator',
  template: `
    <div *ngIf="loading" class="overlay">
      <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
      </mat-progress-spinner>
    </div>
  `,
  styleUrls: ['./loading-indicator.component.scss'],
  animations: [fadeInOut],
  encapsulation: ViewEncapsulation.None
  //this component css in on index.html for a central implementation
})
export class LoadingIndicatorComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  sub: Subscription
  color = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 50;
  constructor(private service: LoadingIndicatorService, private cd: ChangeDetectorRef) {
    this.cd.detach()
  }

  ngOnInit() {
    this.sub = this.service.getObservable().subscribe(bool => {
      this.loading = bool
      this.cd.detectChanges()
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}

import { Component, ElementRef, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';

@Component({
    selector: 'ga-input-percent',
    template: `
    <mat-form-field class="input" [ngClass]="{outline: outlineStyle}" [hideRequiredMarker]="true"
    [floatLabel]="'never'" style="text-align: right;">
        <input
            #inp
            matInput
            type="text"
            [name]="nameAcessor"
            placeholder="0,00"
            (blur)="onTouched(form.value)"
            [formControl]="form"
            />
        <span matSuffix>%</span>
    </mat-form-field>
    `,
    styles: [`
        .input ::ng-deep .mat-form-field-suffix {
            padding-left: 5px;
        }
        .input.outline {
        border: 1px solid var(--primary);
        border-radius: 4px;
        margin-left: 10px;
        width: 120px;
        }

        .input.outline.mat-form-field-disabled {
            border-color: var(--subtext);
        }

        .input.outline.mat-form-field-disabled .currencyvalue{
            color: var(--subtext);
        }

        .input.outline ::ng-deep .mat-form-field-underline,
        .input.outline ::ng-deep .mat-form-field-ripple {
            height: 0px !important;
        }

        .input.outline ::ng-deep .mat-form-field-wrapper {
            padding-bottom: 0px !important;
        }

        .input.outline ::ng-deep .mat-form-field-flex {
            align-items: center !important;
        }

        .input.outline ::ng-deep .mat-form-field-prefix {
            padding-left: 10px;
        }

        .input.outline ::ng-deep .mat-form-field-infix {
            border-top: none !important;
            margin-right: 10px;
        }
    `],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GaInputPercentComponent),
            multi: true
        }
    ]
})

export class GaInputPercentComponent implements ControlValueAccessor, OnChanges {
    form = new FormControl()
    onTouched;
    @Input() disabledInput: boolean = false;
    @Input() requiredInput: boolean = false;
    @Input() nameAcessor: string = 'percInput';
    @Input() decimals: number = 4;
    @Input() outlineStyle: boolean = false;
    @Input() position: number
    @ViewChild('inp') inputEle: ElementRef;
    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabledInput && changes.disabledInput.currentValue != undefined) {
            this.setDisabledState(changes.disabledInput.currentValue);
        }
        if (changes.requiredInput && changes.requiredInput.currentValue != undefined) {
            this.setRequiredState(changes.requiredInput.currentValue);
        }
    }

    writeValue(val: any): void {
        const value = val ?? undefined
        if (value) {
            const percent = (value * 100).toFixed(this.decimals).replace('.', ',');
            this.form.setValue(percent, { onlySelf: true })

        }
        else if (value == 0 && this.form.value) {
            this.form.setValue(0, { onlySelf: true })
        }
    }

    registerOnChange(fn: any): void {
        this.form.valueChanges.pipe(map(value => {
            if (value && !isNaN(parseFloat(value.replace(',', '.')))) {
                return parseFloat(value.replace(',', '.')) / 100
            }
            return 0
        })).subscribe(fn)
    }

    registerOnTouched(fn: any): void {
        const customFn = (newValue) => {
            if (typeof newValue == 'string' && !isNaN(parseFloat(newValue?.replace(',', '.')))) {
                newValue = parseFloat(newValue.replace(',', '.'));
            }
            else if (typeof newValue != 'number') {
                newValue = 0
            }
            this.writeValue(newValue / 100)
            fn(newValue / 100)
        }
        this.onTouched = customFn
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.form.disable() : this.form.enable();
    }

    setRequiredState?(isRequired: boolean): void {
        isRequired ? this.form.addValidators([Validators.required]) : this.form.removeValidators([Validators.required]);
    }
}

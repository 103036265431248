import { PlatformModule } from '@angular/cdk/platform';
import { NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateFnsAdapter } from './date-adapter';
import { MAT_DATE_FNS_DATE_FORMATS } from './date-formats';


@NgModule({
    imports: [PlatformModule],
    providers: [
        { provide: DateAdapter, useClass: DateFnsAdapter },
    ],
})
export class DateFnsDateModule { }


@NgModule({
    imports: [DateFnsDateModule],
    providers: [{ provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_DATE_FORMATS }],
})
export class MatDateFnsDateModule { }
import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'dg/app/auth/auth.service';
import { ApiService } from 'dg/app/shared/api/api.service';
import { TawkService } from 'dg/app/shared/services/tawk.service';
import { environment } from 'dg/environments/environment';
import { CurrentUserService } from '../currentUser.service';
import { NavigateService } from '../navigate.service';


@Component({
  selector: 'dg-topbar',
  templateUrl: './topbar.component.html',
  host: { 'class': 'dg-topbar' },
  encapsulation: ViewEncapsulation.None,
  providers: [NavigateService]
})
export class TopbarComponent implements OnInit {
  session: any = { mc: true, gd: false }
  @Output() sideNavToggle = new EventEmitter<void>();
  readonly version = environment.version;
  links: any
  shortUsername: string = ""

  constructor(
    private router: Router,
    private auth: AuthService,
    private user: CurrentUserService,
    private navigateService: NavigateService,
    private api: ApiService,
    private tawk: TawkService) {

  }

  async ngOnInit() {

    this.links = this.navigateService.links
    this.session = this.auth.getModules();
    this.api.getContactInfo().subscribe(res => {
      if (!res) {
        this.links.pop();
      }
    });
    this.auth.getUserFromToken();
    this.user.user.subscribe(async user => {
      if (user) {
        const isAdmin = user?.group == 'admin-portal';
        let features: any = {};
        if (!isAdmin) {
          features = await this.api.getFeatures().toPromise();
        }
        this.shortUsername = this.getTwoLettersOfUsername(user);
        this.tawk.SetChatVisibility(isAdmin || features.chat);
        this.tawk.UpdateTawkUser(user);
      }
    })
  }

  onToggleSideNav() {
    this.sideNavToggle.emit();
  }

  editUser() {
    console.log(this.auth.getUserFromToken().user_id)
    this.router.navigateByUrl(`/registry/users/${this.auth.getUserFromToken().user_id}`)
  }

  logout() {
    this.auth.logout()
    this.user.setUserValues(null)
  }

  getTwoLettersOfUsername(user) {
    if (user.first_name && user.last_name)
      return user.first_name.substr(0, 1).toUpperCase() + user.last_name.substr(0, 1).toUpperCase();
    if (user.first_name)
      return user.first_name.substr(0, 2).toUpperCase();
    return user.username.substr(0, 2).toUpperCase();
  }


}

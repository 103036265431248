import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Projeto } from "ui-components/models/project.model";
import { ApiService } from "../api/api.service";

@Injectable()
export class ProjectService {
    private _state = new BehaviorSubject<Projeto[]>([]);
    private _obs = this._state.asObservable()

    get updatedProjects() {
        return this._obs
    }

    constructor(private apiService: ApiService) { }

    getProjects() {
        return this._state.value.slice();
    }

    setProjects(empresas: Projeto[]) {
        this._state.next(empresas);
    }

    getProject(id: number) {
        const cli = this._state.value.find(e => e.id == id);
        return cli;
    }

    getGroups(): Promise<any> {
        return this.apiService.getGroups().toPromise().then(resp => {
            return resp;
        })
    }

}

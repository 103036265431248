import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { map } from 'rxjs/operators';
import { Measurement } from 'ui-components/models/greenant-dash/measurements';

@Injectable({ providedIn: 'root' })
export class GreenAntDashboardService {
    private url = `https://backend.greenant.com.br/api/`

    constructor(private http: HttpClient) { }

    generation(meter: string, token: string, [start, end]: [Date, Date], resolution: 'day' | 'invoice') {
        const segment = resolution == 'day' ? `meters/${meter}/measurements` : `meters/${meter}/measurements/total`
        const params = new HttpParams({
            fromObject: {
                startDate: format(start, 'yyyy-MM-dd'),
                endDate: format(end, 'yyyy-MM-dd'),
                resolution: 'day'
            }
        })
        return this.http.get<{ measurement: Measurement[] }>(`${this.url}${segment}`, {
            params, headers: {
                Authorization: `JWT ${token}`
            }
        }).pipe(map(({ measurement }) => measurement))
    }
}
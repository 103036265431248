import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Form } from 'ui-components/models/form.model';

@Component({
    selector: 'dg-chips-selector',
    template: `
      <mat-form-field
        class="chip-multiselect"
        style="min-width: 275px;"
        (click)="focousEnd()"
        [hideRequiredMarker]="true"
      >
      <mat-label>{{label}}</mat-label>
      <mat-chip-list #chipList>
          <mat-chip
            *ngFor="let chip of chips; let i = index"
            [selectable]="!!chip"
            [removable]="true"
            (removed)="removeItem(chip)"
          >
            {{ chip[shownProperty] }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            #itemInput
            (blur)="focousStart()"
            [formControl]="input"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addItem($event)"
          />
        </mat-chip-list>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selectedItem($event)"
        >
          <mat-option
            *ngFor="let opt of filteredItems$ | async"
            [value]="opt.id"
            >
            <!-- matTooltip="Esta usina já possui uma composição de contratos associada"
            [matTooltipDisabled]="opt.availability"
            [disabled]="!opt.availability" -->
            {{ opt[shownProperty] }}
          </mat-option>
        </mat-autocomplete>
        </mat-form-field>
    `
})
export class ChipsSelectorComponent implements OnInit, OnChanges {
    @Input() options: any[] = []
    @Input() label: string;
    @Input() shownProperty: string;
    @Input() isSelected: any[];
    @Input() listIndex: number = 0;

    @Output() selected = new EventEmitter<number>();

    @ViewChild('chipList', { static: false, read: ElementRef }) chip: ElementRef;
    @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    input = new FormControl('')

    form: FormControl = this.createFormControl()

    chips: any[] = []
    showOptions = [];
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    public onTouched: () => void = () => { };
    filteredItems$: Observable<any[]>;

    constructor(private fb: FormBuilder) {
        this.options = [];
        this.filteredItems$ = this.input.valueChanges.pipe(
            map((value: string | null) => {
                return value ? this._filter(value) : this.showOptions.slice();
            }));
        this.form.valueChanges.subscribe(form => {
            if (this.options) {
                this.showOptions = this.options.filter(opt => !form.includes(opt.id))
                const options = this.options.filter(opt => form.includes(opt.id))
                this.chips = options
                if (this.chips.length == 0) {
                    this.showOptions = this.options.slice();
                }
            }
            this.selected.emit(form);
        })
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.form.setValue(this.isSelected, { emitModelToViewChange: true });
    }

    focousStart() {
        //does not work with more than 1 mat-chip-list on the page

        //this.chip.nativeElement.children[0].children[0].scrollIntoView(true);
    }

    focousEnd() {
        const numChips = this.chip.nativeElement.children[0].children.length;
        //this.chip.nativeElement.children[0].children[numChips - 1].scrollIntoView(true);
    }

    createFormControl() {
        const model: Form<number[]> = []
        return this.fb.control(model)
    }

    addItem({ input, value }: MatChipInputEvent): void {
        if (input) {
            input.value = '';
        }
    }

    selectedItem(event: MatAutocompleteSelectedEvent): void {
        const { option, } = event
        const value = option.value
        this.itemInput.nativeElement.value = '';
        this.form.setValue([...this.form.value, value]);
    }

    removeItem(chip) {
        const index = this.chips.indexOf(chip);

        if (index >= 0) {
            this.chips.splice(index, 1);
            const formValue = this.form.value
            this.form.setValue(formValue.filter(v => v != chip.id))
        }
    }

    private _filter(value: string): any[] {
        const filterValue = String(value).toLowerCase();
        const options = this.showOptions.filter(c => {
            if (!c.nome) {
                return false;
            }
            const label = c.nome.toLowerCase();
            return label.includes(filterValue)
        })
        return options;
    }

    writeValue(val: any[]): void {
        if (Array.isArray(val)) {
            this.form.patchValue(val, { emitEvent: true })
        }
    }
}
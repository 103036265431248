import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { TenantModule } from '../tenant/tenant.module';
import { AppShellComponent } from './app-shell.component';
import { CurrentUserService } from './currentUser.service';
import { NavigateService } from './navigate.service';
import { SideBarComponent } from './responsive-sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';




@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatMenuModule,
        MatButtonModule,
        MatTabsModule,
        MatIconModule,
        TenantModule,
        FlexLayoutModule,
        MatListModule,
        MatSidenavModule
    ],
    exports: [],
    declarations: [
        AppShellComponent,
        TopbarComponent,
        SideBarComponent
    ],
    providers: [
        NavigateService,
        CurrentUserService
    ],
})
export class AppShellModule { }

import { ChartColors, defaultColors, TenantColors } from "../models/tenant-theme.model";

const tenantColors: TenantColors = {
   'primary': '#D46D4D',
   'secondary': '#E24A19',
   'btn-primary': '#74AF25',
   'btn-secondary': '#74AF25',
};

const chartColors: ChartColors = {
   'chart-color-1': '#E24A19',
   'chart-color-2': '#017B56',
   'chart-color-3': '#0075af',
   'chart-color-4': '#74AF25'
};

const logo = `
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:xlink="http://www.w3.org/1999/xlink"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   version="1.1"
   id="svg2"
   width="862.08002"
   height="451.20001"
   viewBox="0 0 862.08002 451.20001"
   sodipodi:docname="logo.svg"
   inkscape:version="0.92.3 (2405546, 2018-03-11)">
  <metadata
     id="metadata8">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title></dc:title>
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <defs
     id="defs6" />
  <sodipodi:namedview
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1"
     objecttolerance="10"
     gridtolerance="10"
     guidetolerance="10"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:window-width="1920"
     inkscape:window-height="1017"
     id="namedview4"
     showgrid="false"
     inkscape:zoom="0.32827579"
     inkscape:cx="431.04001"
     inkscape:cy="225.60001"
     inkscape:window-x="0"
     inkscape:window-y="30"
     inkscape:window-maximized="1"
     inkscape:current-layer="svg2" />
  <image
     width="862.08002"
     height="451.20001"
     preserveAspectRatio="none"
     style="image-rendering:optimizeQuality"
     xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABUMAAALBCAYAAAByerDLAAAVkXpUWHRSYXcgcHJvZmlsZSB0eXBl
IGV4aWYAAHjazZpZdiS3dkX/MQoPAcBFOxy0a3kGHr73QWZJpeY9S7Y+TBaZWZERCOA2p0HQnf/6
z+v+g69szbuUayu9FM9X6qnHwZvmP1/9/Q4+vd/vK5nP36O/Oe5W8PG9ixwyXu3zQR2f1zA4nn+9
4Mc9wvztcde+n8T2Hej7wY8BTXfWrfbPk+R4/BwP6TtQP583pbf681TnZ55+fU98U/n+9P3O/GUQ
/d/9fCBVorQzN7IYjwXz/I72nYHpJ9jgtfM7mMXPUR0xx0uy+p0JAfnN8n68ev9zgH4T5B/v3O+j
3/L3498FP47vGfa7WJZvjHjzpx+E/OfBfyH+6cb2y4zibz9YO9gflvP9uXe3e89ndSMVIlq+FeXd
j+joGk6cr+R0WeG78pN5X99357v54RfJ2X75yfcKPURufV1IYYcRbjjvdYXFFFM8sfIa4yJROtas
xh4X+Qkkh+9wYyV72xqZW/E4Mw7HX+YS3n37u98KjTvvwKkxMFjgkn/57f7dh3/n2927FKKgYLb8
YsW8ouqaaShz+s1ZJCTcb97yC/CP72/6/U+FRamSwfzC3Fjg8PMzxMzh19qyl2ehQOb10xXB1f0d
gBBx78xkgpEBX4LlUIKvMdYQiGMjQYOZR0txkoGQc9xMMiazEl2NLereXFPDOzfmWKIOg00kIlux
Sm7oL5KVUqZ+amrU0MiWU8655Jqbyz2PYiWVXEqpRSA3qtVUcy211lZ7Hc1aarmVVltrvY0eu4GB
uZdee+u9jxHd4EaDsQbnD47MOG2mmWeZdbbZ51iUz0orr7LqaquvseO2DUzssutuu+9xgjsgxUkn
n3Lqaaefcam1azfdfMutt91+xy9Z+2b1D99/I2vhm7X4MqXz6i9Z46ir9ccQQXCSlTMyFlMg41UZ
oKCjcuZbSCkqc8qZ75GmyJFJZuXG7aCMkcJ0Qsw3/JK7XzP3l/LmcvtLeYv/U+acUvdPZM6Ruj/m
7U+ytsVz62Xs04WKqTe6j89PGy62IVIbn9exTr/F7DCxtifrnpG1nhNTbrBFOh5cXOBoLmNfNbHp
92ruZlCNt7uele+lmRjwsIA8LwCl3wNSP6whn3t2OYO4+1LaoW0TVb9Ti3W4wxC9ss5uN1S7J/V6
Zr83L7uE7LY9QJgRCRpg6s+dK/WxCQlZrIkMEsRWnR+aQUi1FjuLXi9zboD3rlB2mgtGDPUWwHRk
vwkV2U0EndoKk/dQvpizOmot+UVbJAa85seLl//br+5vX/gJMYA1j0H6qwOmdxZHbXR9VFuGeuKY
APCYUDvlaiKB45sl+pVqXnlHX1nlrlrH3evYzYF+mM15rRKQTrrPIYe93hOpspsV3UIZlNlHzxue
iRSN1VBrTBRzgIt4Mzrx3G6M3E5jmn7HC3YSKjMgZiZqp9PNp5Iba1RXsytlUHekDkuFCP31vbRO
CqYbjQSWcSlqNNFsWmZG8aR4Z7B9pi4nFyEIw2crurZXK2eWFpblm9rczGhVgINBIM59DyugTRnV
qLheJnVR4GDGzfHMbHPkPcPZ2e6EyYkooUNXFRBy5gsNz7km9PBAZs4QF19EAk5enbooO6RMa7dD
cIh7a6vt06DwgmScKQdUrRRbDv/X198PhIDjVkBU5JUl7dUArhw6syMAudYw5kVa3KDFn1DuBaji
dWWXkGfN1D8iZJyEQIq0+5ptUT3EmSJaZHNy5jWqYK2styyoW4I/+CJYjvg04CP+FhT+FBPAskr4
ycasyyq4W4Q2p09u4nINc3fyGNK6aCL+v0G0dCl5DdUB8nHq3dXfAwaQT8bMdD0FSURK7g0Ndx3w
TT1rrVRhGIAr4AMF1THK3gfKYJWlGfOuUzizPcWRj+/5kF4QiqaLpTn6CGj2rQDHPp+UWghw5EIY
XCnTsDuIWE5fZP7MJWtRZ4cTWCUvdh+ebzfjlv4wf9J+DWx5oVBm3fuy3oxuvzMZk2u9EtTSAKXe
K2gV9041rDURLN5Ff0hz0n1rvZNV1n4iLUFa6UYkVYe7tg5HJkbjMsdPIi44cVkmAA4e/RPFmP5s
oHShzRohkyo2kpkCfWsHntJSY6yBIHtoDvkht05c09/gbm1p2NwZXJpzcEXthQRyCsuz2JWss1/m
4xik8AQye8ax5QkC2D/hxOXkgsI+KV9+nbUIK/r50rLKj3gg3xPuwo5R5eSJLoD8rLaVQYF5J4za
6nFcM3h//01NX6TBiYCKgSo78VtgsskL8LdIoDiKrFGYk75pCzwNB0G06+Jfp+4rwgWIwvfSXqBM
lcYPx2o+YwDnSb5MAHiio/ohAi6GG+G5L5Zz5R6MlgkjVddqGnQoebZMGA/FCQW2RdhjgZaZgsue
i2n6Iqq5RNxuqZfI7FXa9PkSLBTvKWuG1uHQtQ5YX0FAmDo0Jt+VLZcg8zOqX4OOvZP+Jo+UJhXc
YpjWCuG6tChiJoyWtViodU4sKEoAvr3MlDoik2VuwkRKbislx64+uETlzD1ybHnQduAdidh05Zhc
D38saCtEpr5slmUulwkQYIVwToaIUsYaQ0W0Aqtsecs+bSNscAVK4qu/4DpDYNPGUBSm2K2BQEgk
UGyL7jD0wVctTSmGHuoeFwTD0E+QhQQzWyaxEZP3FBq8KiBOqpBiB4NmDZIilGxM5LYeAgxv1kK3
FNZ5e0663TQ4kNXeEjfZvTDP2sv1nvJAHi47h1mEDljlmIBEAXQT3wMDqNqjXQz020zKQq+ZGUfq
Y9F8I0a1CPztoVtQ4QE9BfIiVQDhTjQ0JToH8tgDpoSrwWTwL/cpZmu0k7/DbS7L8M+WeqxgT6fn
kOF7YWyZGu1e/ACfyRYXICqPAZBLACmUnGPWR0dxj3KxZLldo+RYgF+zv0kCyPQCIYVJL325af1F
ICqJho5YBVPqPR4/jjYQaloFiAdmKI3lBUp0QW0UgO9jUWgzxzrRRjsENAmZBRUTRdzxzVsSuA7s
OlggnlDq8qGiCgr+VtoIeAO+r+w5QjNWSOckGpLmqYvLoELKiiLOzKE5VHeZo0ErIU4UaSn2qBKA
U7U/IjtTiuSGhghtfUQ5NHqPBMTCyiD0thz49HZrDJUXJDxuhI0gGSNUj7MCCBakjvWvF4a0RdFg
GxDQUI91+Vt3GJ0KBSHwUVQevEDo45FEREzLjSGGjqQPVdByRWMMo2Z2gV2Q5ihxpELBHb1qRUhl
vA4DoQpquUl1NB6nov3vKuSNxAGDRcz5OjIhtjtW5RSW4zBk1HIpa7RADR/h0haMFKHrKonlEZoe
S6YcVComqQEX6j6ZVU8oAWCrk4gNyR65jAKVS79SfLSQwA/iuUwf2gdjYfsTDTRNIPBcOQx6CY8H
sjuwuZJduUCAOCBx0jHghjXZZ9H9dNwrNVxHoGQCeEbIiXmBqxpGR7LMO52MdLg7CkIWkhniQUjR
BDY6WMW0Q0SUfesQeR2wy8Biv9lwdypDSNV96nB86nCqDuFXeCwVL9EnZ/eMDiI53hEBehwAOQNi
yXDfyB/5AHPoFgJks17qOFf5Y4oYqzwQiCjF4bdQGQmee9wHCCH2qPkAnrR0jSINUK93Nq+sxgr0
FJjzqeI8vSY+RXgb/o1B0mnFohRk8Iq29qz1qXk4PuOykUHUF16sTFHzDFwooNdCNknndth35o3r
G/Uas6aoyIdkFTArageBpmMKk3DQMUj2LPNLvQJV/pbQl5BgJASwsryZJZr34BvkCbNogAtZQN/F
MQYagpn0yoQLPACIBAw4ovJlFAYcgAj4xVigIIoHVH34hC6YaLJiihG8boSVDkCw0GJg40aaG7iB
KsX5RjhgAHShAEDI9gZuMY1MuVuDaQ/qL2OOK8lc0i7Cr7IzulaqHraFGZNXK7RBN6ExECsknSqk
PyUFr4TypXSQLccxAfg9MPUP4J+xyUdGeOInkFFQBOhJmJHTG+mNH89A031kQlljACj5M9wEl0H+
hP+ZxTrE0SJic8hycfetXVvMZCXZT6pBuZEA4ZQYoNJaA+qAs1yeaEBTVdFRJCSiKgJisZI74JHV
w/RUOg16cDVMeGgTAiztDc87OTdpP9Rd4Ptj1TF8AZsAu8LMS6U4PJdAUIpHpEAQYOlyMDS5Iwka
iSR4CXBz8BtZ7DiMWk4TDGERyRTWlXsh/c4BK8ltUb3CkTAX1Y9DUEmwWD4LVU8hMMkTiYCohBwo
voNXggAViXnLZn4bTY9k26b9iGfLOgEyGo7eBAnkV82hPtKB2TcNh4vo5BmMzShhTvXYOERP1y5s
kT0Dzrb8jOcGGEPtgybcVcP3C9hlxoZVOIRJj4rBh/RpJlZDnOTnch8yT0nenXRgEJW/JEUBqWLE
xCIoqAqUcY0k4GNr+BroAuLOhwBuLpIlwCjNjkqFY2Dcqm1jBhb3NYfVbtTkDsi02FfTziGsn5XH
p69p8ABZw1lQNJQGKtBxBpTEhGVCJqc+W3beeqbZuEtjZs+84+6Nalr4hExcj8RIQ6kO5l7C9UQ0
RnIhtUgktIWQjpuhSDPGAJ5YrCyJ9TTU931y+cBMSMcTdlQQiACUj2hfVDTaLGq/EfCNwR1vwIAv
RlfsIgKg0aNE/eJuzA5GxnKyyJEOMvJnJ0UNSXtIxFCQoT7vQfvfR8kUrfrwvV8fQZEB2edPPIZk
HBzk0R4aJpZrxhPKyUV9wr2XdpU4lnZZd1AAzGsD+VQc1l/k549xc6SynoZV/yRse9znYX83PcBE
6aBtj6p+qQ0y/wGl0psrhBzhmCAVgOu/mOmOWAIgQ00IUnQPK0CwR/yFyFPKiOolbTIg2L62gY1B
6WPoRm5Mrpc+SsSTQMtATkRu0/9CcaQfgyvGB9poJPfGRM/ZgzkoCFCyiBgJn80ECv9+3NxHc+9Q
OIPyXs785xTSghohdKj1ix1Hql8TBGd9fqn6zqdRzC5lGNqlGyjNOWlW4gX4hwIfImgn9BSxX12P
46BrGpmlUsc4BLwI0k02qV/8c6twEYIdDEX5eIBnuIUWQbaIzvxnKpAsknRPmgGveAHmvmgkjEqq
J1lqdUQ7NQI5aFhBHGa1OvSrfPPGGdHEaGwhKeATSeCCcxRy7Tri5t42InLfI7IvtQlZATW4F5Bq
uyFRROfngMvag4lJS+M1i8z4xDlzzXhxBIzAleej5AtZB1bf0EH0SHNY3tFRK/COMMRYHWmOsxzq
AQ5EgXpK/IqCIH4W8zY8koQTLcwCNwasB4eXSPj2iFNnFtoJEi4+MljUwysD8MKo/KvtoaDCVbHB
MuUz00iQx1NsS6YfSKAi6Byj1q52w8NSOfgmpwaBKKq54VeHNm2IGK6XtpkgPTXhUG9dhPe983x3
LtigByRvdM6FdDCmqJIue0pudliAoGVivd+uniOrVAMFI+PklUkE27M3XYJam2LNHhFhdyq9dIx6
IroPDz6DFm1o0jHapA3ArfYBNCYa6aAYX+MgEQLVSO3S7g1+wEvIxiMi0VdYEZgWKYyF0G4b8r0i
UcD9XdGJQZQGpQCmiNLGHBHIhX7SE4ROoZBosBp9MqkTQ/bcel3CcYtAIBZk6Gnl7RbR19ToQbWh
vrhpTeXB9gF0UG2qH+3vEVsJGUpguK4iYOk1owlhh7RxDrtpt1Pb4eQFGUEwikDvYqmh8a6NmPwB
tSRFsdp1L+UyDsg1Opd60Z5xbwVmhXlOSbxSxwlVW8h8NOVZ+5UYKnSi0Bvs7g6pZ2+308Rc9fQw
9GQHWi2Qb9XOBlStIF5T4cJetL0eSYXPNvk6eSApmRHGXiSOQRHhQDm+L4QJCD21F4Q1104V8O+R
lTAkDZKhBybbl7QTzhQN7VonemAEHhXDtp4xgNlloxu3hFkbcEYvZKGWIQgXIIMFQkwgdVBIxj13
dMCOeA4Q6TuCz9J0mYQjldS9fiD4M14E1yGvNijLMzfoQAVve941YEeue5trP7bWvhtrMAfia6Gi
EbmYMQOYcWJCHFJOrLa4PGOFtaEI4ZfXazR4m/3Ta/N+9owxpE+mLQQzGuVEkADJhTlHDGujhFod
+HSNv/SXGMtRoJQrEZNZ0B4kmSBMKjUsF2WOtKCqSTFpjcTa464lw/PhDhTcuIjR1hyAhQklOFou
Od96GEkXznh2wQQhKbly6JnNk0xiFG2r5fAtou+r0xvkEMZXT6IQr17bDwTrAyxBbGJF+kTBKwXC
oP2JlU8R/RAiCMzwBvdrV6XpMUw2FcKipRrhZL7aFAPa0bEdG5H0Ryq4ozSRilVsgGxNBSszll2H
XOLmIpGP8TroKZkHBF5faHl6hK6h+0uEDRBAAZDsisOiIi5yCdQjnjTt2y/eeha6x2qwUWpD0Niq
9nv1mAWXVcMnLICTvAWUg6bU1ohIG1orbtIHHg7LXbs4DBVShd7AdwwMeEjt0XNy0DKj+AQ9w5Eo
ADKSni7hBqS6MMfaSZOoibQETG5WShd4MkfsegogSin6g4LxUbqowzLAM20MklPIDjmkv9KA6RCt
Ww+ZULiCLlF7hTFxmYdhTpna7HkXov36Z5dko8sSnZQU4Lzc249L2hcj8yHhvjt10PHKMLP6F1tF
JY1b5ES1L0PYJVlgfwEv9SgwyU4CeiXCx2zmoLlZEyr5G7lkISA3cI0DyVcSXqvqwXI82j5cHevF
0HDCcfFzg/a5wbnZf5LjRTiraDcJpQ5+XAnbKmltAZaDtLQvzJRh4XqyQ/MjphdMEOhPCI9TMiYK
UlqhoOHkG9aZC2Ic4ueqgDGklqmdMXqLsu1OiWzT7O2HopuGfR9rIyO28OnAKJ7qvjM+FzhXQ+kW
/QkIOj17MBzhFNUi78IKAbHAdY4grh2xHTdhOWBBkbhC0JAuRsowKqoNEHkPnz/Pnh1uqb9nzyZO
iO9vu5h/o6Uhh3l9lriUUMWfEGumHPToSeUDp+Waljwzyp8uRvg0aWP4DxOtfZ7+oMxrF7Rx9HRt
rBDEePy+mSYscpGogrC6KNoXR07lid5jLsnYTZ4MRwjbANYgNoZ/P+WLO1Al3nDQkA9HTY9C6KaF
JXKiRvUvnQmOSYo3VO5ssnAIX/N7Gab6bXiRRZUot8K5b9wcglxP6yZuwh3ml9HKAVwMxN3C9Nr5
lbHw4MBFrvyVZ+Xuf/lU/nevgD+Skt6n89KRTkK+J8mRHrPHosqgeqGnnK8eYFQ9KhyU70FkAs+d
gKgotFXvVeHEES4EyahD/emDEJ56VkMaNYWieC0FvLzdJSoYzOslIs6ITD/eIRV7OE8hYiHbU7GJ
im0Br5UyOgO5hv5a6J7c0F3aWkxALzZjE9/PrtfGZv3LP6mAmCeXX8QthRlQHDZQxfCIdgSingr3
hMpBjtQyAP+L0A9SfdzsH3x0+P93IJKAm+aE/wbz0CcoxMDAUQAAAYRpQ0NQSUNDIHByb2ZpbGUA
ACiRfZE9SMNAHMVfU8UPWhwsKOKQoTpZEBVx1CoUoUKoFVp1MLn0C5o0JCkujoJrwcGPxaqDi7Ou
Dq6CIPgB4ubmpOgiJf4vKbSI8eC4H+/uPe7eAUK9zDSrYxzQdNtMJeJiJrsqdr2iB2EMIIiwzCxj
TpKS8B1f9wjw9S7Gs/zP/TnCas5iQEAknmWGaRNvEE9v2gbnfeIIK8oq8TnxmEkXJH7kuuLxG+eC
ywLPjJjp1DxxhFgstLHSxqxoasRTxFFV0ylfyHisct7irJWrrHlP/sJQTl9Z5jrNYSSwiCVIEKGg
ihLKsBGjVSfFQor24z7+IdcvkUshVwmMHAuoQIPs+sH/4He3Vn5ywksKxYHOF8f5GAG6doFGzXG+
jx2ncQIEn4ErveWv1IGZT9JrLS16BPRtAxfXLU3ZAy53gMEnQzZlVwrSFPJ54P2MvikL9N8CvWte
b819nD4AaeoqeQMcHAKjBcpe93l3d3tv/55p9vcDBWdye6QLKbcAAAAGYktHRAAAAAAAAPlDu38A
AAAJcEhZcwAAFxEAABcRAcom8z8AAAAHdElNRQflCQ0XAy/sskFSAAAgAElEQVR42u3d23biPLYG
UJzB+7+y98Wu0PmTQMBI1jrMedU9uqsq6LAkfZHN5QIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD8tGkC
4NO+7/vbRWXb1BUAAAAgJKEFNDciAL1bYASjAAAAQCCCCmhsZhB6KzICUQAAACAIIQU0dUYQeis0
AlEAAAAgAAEFNHRmEHorNgJRAAAAYDHhBDSzIgi9FRyBKAAAALCQYAIaWRmE3oqOQBQAAABY5EMT
AAAAAAAduKEFTUS4FXorPG6HAgAAAAu4GQoAAAAAtCAMBQAAAABaEIYCAAAAAC0IQwEAAACAFoSh
AAAAAEALwlAAAAAAoAVhKAAAAADQgjAUAAAAAGhBGAoAAAAAtCAMBQAAAABaEIYCAAAAAC0IQwEA
AACAFoShAAAAAEALwlAAAAAAoAVhKAAAAADQgjAUAAAAAGhBGAoAAAAAtCAMBQAAAABaEIYCAAAA
AC0IQwEAAACAFoShAAAAAEALwlAAAAAAoAVhKAAAAADQgjAUAAAAAGhBGAoAAAAAtCAMBQAAAABa
EIYCAAAAAC0IQwEAAACAFoShAAAAAEALV00AwFf7vu+f/3nbtk2LAAAAUIUwFKC5r+HnX/+bcBQA
AIDMhKEATT0KQf/6M0JRAAAAMvLOUICGjgShI/88AAAArCAMBWhmVJC5/6NFAQAAyEIYCtDIjPBS
IAoAAEAWwlCAJmaGlgJRAAAAMhCGAjCEx+YBAACIThgK0MCZIaVAFAAAgKiEoQDFrQgnBaIAAABE
JAwFKGxlKCkQBQAAIBphKADTCEQBAACIRBgKUFSUIFIgCgAAQBTCUICCBJAAAADwkzAUgOmEswAA
AEQgDAUoRvAIAAAAvxOGAhQiCAUAAID7hKEARQhCAQAA4DFhKAAAAADQgjAUoAC3QgEAAOBvwlCA
5AShAAAA8BxhKEBiglAAAAB4njAUAAAAAGhBGAqQlFuhAAAA8BphKEBC2YLQbds2vQYAAMBqwlAA
AAAAoAVhKEAyboUCAADAMcJQgEQEoQAAAHCcMBQgCV+YBAAAAO8RhgIwhVuhAAAARCMMBUjA4/EA
AADwPmEoQHAejwcAAIAxhKEAgWUMQt0KBQAAICphKADDCEIBAACITBgKEJT3hAIAAMBYwlCAgLwn
FAAAAMYThgLwNrdCAQAAyEAYChCMx+MBAABgDmEoQCAejwcAAIB5hKEAQWQMQt0KBQAAIBNhKACH
CEIBAADIRhgKEID3hAIAAMB8wlCAxbwnFAAAAM4hDAVYyHtCAQAA4DzCUACeJggFAAAgM2EowCLe
EwoAAADnEoYCLOA9oQAAAHA+YSgAf3IrFAAAgAqEoQAn83g8AAAArCEMBTiRx+MBAABgHWEowEky
BqFuhQIAAFCJMBSAXwlCAQAAqEYYCnAC7wkFAACA9YShAJN5TygAAADEIAwFmMh7QgEAACAOYSgA
N4JQAAAAKhOGAkziPaEAAAAQizAUYALvCQUAAIB4hKEAuBUKAABAC8JQgME8Hg8AAAAxCUOhiSiB
V/XgzePxAAAAEJcwFGCQjEGoW6EAAAB0IgyFRlYHX4I34wEAAABWEoYCDOA9oQAAABCfMBSaWRWC
VQ7fvCcUAAAAcnAzCJo6K8DzhUkBC79boQAAADTlQAzNzQzzOoRuHo8HAACAROdiTQBcLmNDvS6B
myAUAAAAcnEwBjjA4/EAAACQjy9QAmhAEAoAAABuhgK8zOPxAAAAkJOboQAvyPh4PAAAAPD/hKEA
hbkVCgAAAF/OyZoA4DkejwcAAIDc3AwFKEgQCgAAAD8JQwEAAACAFoShAMW4FQoAAAB3zsyaAOBv
Wd4XKggFAACA+9wMBShCEAoAAACPCUMBAAAAgBbcIgJ4UuRH5d0KBQAAgL+5GQqQnCAUAAAAniMM
BQAAAABaEIYCJOZWKAAAALxwjtYEAM+L9N5QQSgAAAC8xs1QgIQEoQAAAPA6YSjAC4SQAAAAkPhc
rwkAXrfycXmBLAAAABw8U2sCgGNWBKKCUAAAAHjjXK0JAN5zVigqCAUAAID3eGcowJuElAAAAJDk
DK8JAMaYeUNU4AoAAAADzteaAGCs0aGoIBQAAAAGnbE1AcB4owJRQSgAAACM45ANMMm7gaggFAAA
AMZy0AaY7NVQVAgKAAAAczhwA5zoUTAqBAUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA3rNpAgB4377v+5+L7rZZdwEAABZyKAOAg54JQH9dfIWi
AAAASziMAcALjgagvy7CQlEAAIBTOYQBwAMjw89fF2KBKAAAwGkcwADgm9kB6I/FWCAKAABwiqsm
AIDzA1AAAADO5yYKAG1FCkDdDgUAAJjPzVAAWnEDFAAAoC+3UAAoL0sA6nYoAADAXG6GAlCSG6AA
AAB8JwwFoAwBKAAAAI94HA+A1KoFoB6VBwAAmMfNUADScQMUAACAI4ShAKQgAAUAAOBdHsUDIKyu
AahH5QEAAOZwMxSAMNz+BAAAYCZhKABLCUABAAA4i8fwADidAPSJBdqj8gAAAMO5GQrAKQSgAAAA
rCYMBWAaASgAAACRCEMBGEoACgAAQFTCUADeJgAFAAAgA2EoAIcIQAEAAMhGGArA0wSgAAAAZCYM
BeAhASgAAABVCEMB+EEACgAAQEXCUABuhKAAAABUtmkCAC4XQWioxXnbrM8AAAATfGgCAAShAAAA
dODmCUBzgtBgC7NboQAAAPPOXJoAoC9BaLBFWRAKAAAw99ylCYBPI4IxYU6v/mbggmzuAAAAzD97
aQLobWYgJtzp2/eYKwAAACHPX5oA+jojDBPy9O5/zA8AAIBQ5zBNAP2cHYIJfIwDzAkAAIAQ5zFN
AP2sCMGEP8ZA+wXXHAAAAFh/NtME0MvKEEwYZBy0W2SNeQAAgFjnNE0AfUQIwIRDxkL5hdUYBwAA
COuqCQDgPQJQAACAJOc3TQA9RLoJKDgyHtIvnsYwAABASm6GAjS0bdsmEH29zbQCAABAbsJQALhD
AAoAAFCLMBQAvhCAAgAA1CUMBaA9ASgAAEAPwlCAprq/N1QACgAA0I8wFIA2BKAAAAC9CUMBKE0A
CgAAwCdhKEBjVR+VF4ACAADwG2EoACUIQAEAAPiLMBSAtASgAAAAvEIYCtBctkflBaAAAAAcJQwF
IDwBKAAAACMIQwEIdztU+AkAAMAMwlAAQhCAAgAAMJswFIDL5bLmdqgAFAAAgDMJQwE4lQAUAACA
VYShANzMuh0qAAUAACACYSgA//EZXL4bigpAAQAAiEYYCsCvjoSiAlAAAAAiE4YC8ND3gPMzHBV8
AgAAkI0wFICXCEEBAADI6kMTAAAAAAAdCEMBAAAAgBaEoQAAAABAC8JQAAAAAKAFYSgAAAAA0IIw
FAAAAABoQRgKAAAAALQgDAUAAAAAWrhqAt6x7/v+9b9v27ZpFQAAAAAiEobyku/h56P/XTAKAAAA
QCTCUJ7yVwj66M8IRQEAAACIwDtD+dORIHTknwcAAACAEYSh3LX/M+rv0qIAAAAArCQM5VczwkuB
KAAAAAArCUP5YWZoKRAFAAAAYBVfoMSNoBIAAACAytwM5XK5nBuECl0BAAAAWEEYypJwUiAKAAAA
wNmEoc2tDCUFogAAAACcSRjamDASAAAAgE6EoU1FCUIFsgAAAACcRRjKcgJRAAAAAM4gDG1I+AgA
AABAR8JQQhDQAgAAADCbMJQwBKIAAAAAzCQMJRSBKAAAAACzCEMBAAAAgBaEoYTjdigAAAAAMwhD
CUkgCgAAAMBowlDCEogCAAAAMJIwFAAAAABoQRhKaG6HAgAAADCKMLShbdu2TD+vQBQAAACAEYSh
AAAAAEALwtCm3A4FAAAAoBthaGMCUQAAAAA6EYaSikAUAAAAgKOEoc1lux0KAAAAAEcJQ/G4PAAA
AAAtCENJSSAKAAAAwKuEoVwul5yPywtEAQAAAHiFMJQb7w8FAAAAoDLhFz9ku3EpxM3Xr/oMwLpj
HcG40w/6A3MfdYwVNDxLJr0i0rtf9ReANcbaQoV9TedxF/XMoBYYe8YZ6hh/trEmIFNRUCxq9Kn+
ArCmWGuoNu4qj7ms7+pXB4w/Y43sdcz4mtSmmoAqxUKByNOf+grAOmLdoeKYqzDWKn9JqVpgHBpn
xpA6xuUiDKVYAVEYcvSlfgKwdliDqDzuMo2zyuGnOmDuG2vGjlrGr22nCahWVBSE+H2pjwCsF9Yi
qo+7qGOsa2igFpj/xplxY3xxazNNQLUioxDE70d9VHfs6FuwTjhAYNzFG19CA7XA3DfOjBvjiy9t
pQmoWHQUgdh9qH96jBn9DNYHewe6j7vV48r8VgvMfePMuDG++KWNNAFVi48CELcP9U2/8aLPwdpg
/0DXcbdqTJnf6oC5b6wZM8aXVrjTNpqAyoXI5I/Zf/ql91jR/2BNsIcw7owlba3vzH2MNePG2FrW
LpqAygXJxI/Zd/rFODEWwFpgH2HcGUvaW/+Z/xhnxo3xtaQ9NAHVC5NJH6/f9IkxYlyA9dtewrgz
hrS3PjT3MdaMH2NrhQ9NgEIKRJmrn7QGjJ9bWsJeQruB8ayttJ/Pb0+oJYShHOC3CWDTcdZCbbEG
Bwpt6bCFMWpsG4faEYyvkYShHJItEDXZIf/BwzwGa1+UeqQljDuMc62gPhpnPrO2zksYiskO5sae
6We12QZrnvbVLmC8a4subat9MXfHE4ZyWMbH5S0kUG9zaJMI1jrtrD20v/bX7tpAO4Ox9SxhKG/x
/lAg0kIuGMU8MP61t3YA499n195gbD0iDOVt3h8KFr6In0swivmMdvf57ZUxD3xm7Q7G1nfCUEx0
oPx8F4xiXUP7+9xgPvisgLl9uQhDGcT7Q4Es814wio0r+sHnBcx9fQB9x5cwlGE8AgQWumyfXzCK
eYz+8DnB3MBYg17jSxjKUN4fCmRd8AWj2KiCcdd9b4w5Yv7rD+hAGIpFxCICfKsJglGsXegbMhG6
qgM+k7EGxtbzhKHYjAE82AQIRgEHCIduUNMAc78OYShTeFwejPmKbSYYxfylaz8Zd2DOYKzN5mIV
ZxGGQpMNS5SFxQJHlXohGMVaBUD1mm3d0U8YWxU/lzCUaTKGXhYR4EjdEIxijaJynxl7OfbCfuGM
uQ+oBc8RhmJTpj+MBxi4URCMApUOEOoZmEMYa1CNMJTpvD9Uf2QcBzCilghHsTYBYN0B50Y1IRZh
KFj8gZPqimAU7Cv83A7zFf9dzCWMNchEGIqNWcOFZPun2r+Fw1Km+mKzivUIAOsO+g9j63zCUE4j
fOnXJ/oc/t5Q2LCCA4Sf1/4KNQBQRzmPMBRFrfmmZfsi4t8HDkgYG4C9bt39NtYdQI04fa3UlZg8
NpWYj6g1mKOY58Zdnb7Vh8aJsUOFfaXxaGzN4mYoJg6Yj9jwYTwAhdZT6zvWHYAH66QmwGJrUwk2
v+oN5mWkcd75sxt769v+2c+vH+eO+6rjMPK46Tr3u9c8+yG1rOPYcthCUWu2mEDHjaBag/mYe1xX
aw9hiLau2J+z27rS2Iw6LrWxduhey9SxPmPLYQsLrg01lD0E22BgLtYay9rF2FND1/fr6ratMEYj
jk9z31jrtjda2TbGVoCf39YAC6/NNVTdENtgYA7WG8MO7Mad2nle/wrtzf/u7apd1DLzrubYsllA
EbDJhvKbQJsLzLl649cBQpuqm+P7OlsbZh6z0dpaW6qNlfZLmT6zubfoZ7cVQAGwmECXzaDNBeZZ
rbGrrbSnmknWcRtpzJr72ghzsNvY+jDsMImAV+bqJ60BrF7P1SKMA/S/vjfOHnOZwVjmJ2EoCgBw
eM4KRsGBZfU6rgb1Hnv6H1BvML7sHV4lDEUBAIbMXcEoWL/BuMNYyMUvQow16EgYCsDwTaJgFHrN
eT/DMR5dhL7M/34133jD2I5DGIoCAEydz4JRcFCxf0B/Y0xgrAFRCEOxwACnzWvBKFivwbgDzH8w
xlcShgKwZJEXjNoEgbkTg1vJqAFgrEGnPYQwFAsMsHyuC0bBOu0QAaD+WHeAMwhDseBZgCHUHBOM
AtZq9C+gHo3ll2/GFv8jDEUhUJAg7NzvGIyqNT25nQMAAOe4agIAovsevPjNNgBwb89gn1B/Lwjw
DjdDsfhZfCFlTfA4PWDdBjIT2gKsIQzFwcaBCtLXhirBqHoDoEYC6hIwlzAUi82Df8viBvlqhLkL
Dn7ZuB0GAHAe7wwFBz0oP48FDQAA/5Npb+RsBozmZijpzF4MLbZQs25EvzGq9oA5BQDAfG6GkvaA
M/q3mQ5N0Kd+fP5nN0YBAAB6cTOUtEaGl4JQ6FtHItwYVYMAAJxLgXO4GUr6heedm10WLuC3euDG
KFg3gbGsrRBjHlrL1TGEoRQ6mL1amCwCwDP1YfamRy0CQEAAoJZxHmEoZTwTigodgKO1ZcYGSU3C
xhtQswDUMc4lDKUc4QJwRn3xig4AOhAaAGoZ1QhDAeCAI8GoEBSADIQGgDpGZcJQAHiTkBOA7AQH
gFpGF8JQAACApgQHgDpGN8JQAACAZoQHgDpGV8JQAACAJoQHgDpGd8JQAACA4oQHgFoG/08YCgAA
UJjwAFDH4H+EoQAAAAUJDwB1DH760AQAAAC1CBAAdQx+dzWZftq2bTM0AACAqmceAHWMrq4m0t//
X+EoAABQ6cwDoJbR1dUkeu3vEIwCs2qS+gIArDz3AKhldHA1iY7/nYILYGQt+vz/qS0AwOpzD4Ba
RlVXk2jMvyG8AEbVIqEoABDp3AOgllHJVROMn7gCDGDUKzrUEwAAwNkJxvkwkeb8+58MMbCY2xgA
ABXPPQDqGBm5GXrixHbDCyzkR/9e9QMAOGPfAaCOUd2HyXTuz+bGKFjIbRQAAPsCAFjDzdAAGxg3
vsCBBAAAwBkK5ruaTLF+bsEoqDmP/j01AgDsP7QCoI7BcR+aIF5R8Cg9WMBtHAAA+wAAGE8YGnyz
IxgFBxEHIQAAwFkKxvDO0KQFw6OyAADQ9zwAABxT5mZot82BW6PQu9aY+wAAgLMUvM5j8kUKimAU
+i3e5jwA2IMAAK/xmHzhjZJH6QEAAIDV/FKHSK4mVY9iIxiFuvPc/AYAMhu9l3E+BNQxHnEztAnB
KNSe3+Y1ANTfx2dnvwKoZUQgDG2+oTKJocZBRCAKAERljwLOUuoYkQhDFSXBKAAAMJzzBaCWEZFv
k+fGt9JD/jmsFQDA2h6B8ACoQC2rSRjK3Y2XYBTyLYLmLABg3wSglnGfMJQ/CUYh35zVCgAAgLPJ
MYLQ2rwzlMOFTHEAAAC+ckYA1DGiczOUw9wYhdjzUysAAGcSIACQgTCUIYSi2ODHnJd6DQDy7q+1
AoAzH+N9GKzYtAEAAADQgZuhDCcQpTK3QwEA8u+RAOirTBhq8Y1FAIPNvvkIAADgnEc0HwYuswhg
wHwEAACcQSCSa7UPtG3bZsLFKn5C6l6LVZf+VmsAHMAAAMin5DtDhW/w2mHxU8S/T60RDAAA9kQA
MErZL1Da/tHFcN/scEz41q/PATi2b9UKAADnKP9t8kJR+OnMm5vVb4lmrC8CUQAAALr66PJBty90
+7kEL/qjQ33RCgDWOgAA4vvo+KEFo+BgOqOu6A8AAACI7aN7A2zfGBJUtjoAE8AZDwCAPQUArPSh
Cf5LMAq8Uz+0AsBaQhlQA7QCAI8IQx8QjGJjaIN6pG4YFwAAVNs3As5oVQhDX1ioBKMWeag45i22
ANYMAHAuci7qQhh6cEILRoFKLPyAWgZkn39qAADPEIa+STAK3KsNWgHgPEIQACD7fsJ+5hzC0IEE
o8D3mmDhBwA67CNW7yXsYwCsVc8Shk4cEIJRt+PAIQZA7bJPAgD7CmexOIShJ210BaPQd/5b/AHU
LDAn1QEAdSwGYejJBKPQc95rBQAHBzA31QH7Q1DH7GfWE4YuXgArB6MWeMg7HyzIwL3a8EmdAlbP
UXUAnIPUMY4QhgYqEG6MAt0WfyB3jfBOrZ6HQ4yT1XM1yi9lAGeizvuZzGvU1dCOPaAs8lBrbpvT
QPVDwsyNsRoKeWvEqNqgDgDqGO8Shgb3fbJlmTRuO8D9uZFp8dv3fTefgaMbfIcGYFSYUKkO2Fvh
DKSOsZYwNPHCaSKBzcBZi75NOzBy0/9XTam+x1FTURf+ng/OOoA6Zi8zizC0yOCLNMls8KHmgm9u
A7MOEcDjvXXlOaMeAOoYZ/MFSoU2Sb6ACXLNWa0AoP4DagAY+3AuYWjRIrMqGFXgoO588RtPALBn
AMCalP0zCEMbDNKzglGbNKhPIApgrwSoAQCZCUObLb6zglELO/SZOwJRAACgw9mHmn0oDG08gEcF
owoamEMAqPUYQ+g/gAyEoRwORn1hE/TdILsdCgAAVD/3ULPvrroThQk4Yt/3Xc0AsL/i3LHkF5Jq
AADvcTMUwEYZALBfAFDHaNFnwlAAi8xhbqcAOPABagCYG/oqE2EogMXmLQJRAAc9jC30E0AWwlAA
3iYQBQAAXuEXBvpoFWEogEUHAHUcYwz9A+YKLfpGGApg8RnC7VAABzuMNfQLQHTCUACGEYgCwLkE
b/oDzBv0yWuEoQAWoaEEooC6DcadfgDMH30RlTAUwGIEgHoNANZRfdCiD4ShABal4dwOBdRpMAa1
P2AuafuIhKEATCEQBRwewFjU7oA5pc2jEYYCWKAAUJsxJtHeYG5p6xaEoQAWqmncDgXUZDA2q7ax
dga1TBvnJAwFYCqBKODwAMaotkVba09jQNtGIQwFsGgBoA5TdKwar+Y/mHdYG/5LGApgQzCd26GA
+ot2137a0WcBY1Y7RiAMBbCQAfBEzVV3sW8w/8H8MheN0/yEoQAA0Pjw4HDUayzrb/ND22tPn0Xt
704YCmAjAIDDg7VOm9Js/hsH2tMcNS67EoYCWOAAaF5brSU9x7h+Nx+iflZjU3v6bGr81PbRBNBD
pC+wUZTr97F+hzpz14HReNQf+t781/f6QXtqe3WsVDtpArAwKND6WL+DuevwoEZFG5P6RE0y1sx7
7ak91TF9PaW9NAFYEBRq/azfwbx9ZR5XOmSoTfHGpT4xBow1c197ak91TD9PbTdNABYCBVtf63cw
Z4/M48yHDDUp3vjUJ8aAcWbua0/tqZbp51PaTxOA4q9w62v9DubsO/M4y2dQh6BPvTL/AXWMu22p
CUDRV8T1tz4H83XUXFZ/QO0y/wG1TB2L7KoJAOrZtm3zpSxAhE372bXIoQF6zHXzH6hYy9Sxk9pZ
E0APbobqd/0N5mmE+Tzy86ov0LPGmftAhb2aWraOm6EATGOBB9QFMOe1AqCOEcmHJgCwcNs0AAAA
0IEwFKC4FaGkIBQAAICIhKEADZwZTgpCAQAAiMo7QwEYQggKAABAdG6GAjQxM6wUhAIAAJCBMBSg
kRmhpSAUAACALIShAM2MCi+3f7QoAAAAWQhDARp6N8QUggIAAJCRL1ACaOoz0Nz3fX/1zwAAAEBG
wlCA5r4GnN+DUeEnAAAAlQhDAbgRfgIAAFCZd4YCAAAAAC0IQwEAAACAFoShAAAAAEALwlAAAAAA
oAVhKAAAAADQgjAUAAAAAGhBGAoAAAAAtCAMBQAAAABaEIbyp/0fLQEAAABAZldNwFePQs/v/9u2
bZsWAwAAACALYSiXo7c+P/+cUBQAAACADIShTY187H3f910gCgAAAEB0wtBGZr73UyAKAAAAQHTC
0OLO/OIjgSgAAAAAkQlDC1r5ze8CUQAAAACiEoYWsTIABQAAAIAMhKFJRQ4/3Q4FAAAAICJhaCJu
fwIAAADAccLQ4LIGoG6HAgAAABCNMDQgN0ABAAAAYDxhaBACUAAAAACYSxi6UPUA1KPyAAAAAEQi
DD2ZG6AAAAAAsIYw9AQCUAAAAABYTxg6iQD0f+3gUXkAAAAAIhCGDiQABQAAAIC4hKFvEoACAAAA
QA7C0AMEoAAAAACQjzD0SQLQ99rOe0MBAAAAWE0Yeofwk2q2bdsijGvBOAAAALCKMPQLASgAAAAA
1NU+DBWA0snq26FuhQIAAAArtQxDBaB0tioQFYQCAAAAq7UJQwWg6wjBMAYAAACACMoHFELQAINM
ENZ6fuh/gDr7FTUdAIDsPiofKgShsPZQ69AMAAAARFLyMXkhaBzCsFx9NGLu6HMAAAAgqnJhqCAU
jhNkAgAAAJWVekxeEBqLYA0ArOsAABBJmTBUEOrABADWTQAA4JEPTYADHQBgbQcAoIMSYahboQ5L
AGANBQAA/tx3V/gQwlAHOACoItq+xhoPAEAlwlAcjgDA3sZaDwBAC8JQHIoAwB7Heg8AQAvCUByI
AMBex5oPAEALwlAchgAAAABo4aoJ+CQABQAAAKAyYWhzAlAAAAAAuhCGNiQABQAAAKAjYWgTAlAA
AAAAuisTkPkSpV86VwAKAAAAADduhhYi/AQAAACA+4ShyQlAAQAAAOA5pYK0Lo/KC0ABAAAA4HVu
hiYhAAUAAACA9whDAxOAAgAAAMA4wtBgBKAAAAAAMEe54C3je0MFoAAAAAAwn5uhiwhAAQAAAOBc
JQO5qLdDBaAAAAAAsI6boZMJQAEAAAAghrJB3crboQJQAAAAAIjHzdBBBKAAAAAAEFvpAG/27VAB
KAAAAADkUT7MGxmICj8BAAAAIK824d7RUFQACgAAAAA1tAv6nglFBaAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIAUC/gAAAb/SURBVAAAAAAAAAAAAEA+myag
mn3f97sDftuMeQAAAICmBEOU8SgE/XXwC0YBAAAAWhEGkd6rIeiPSSAUBQAAAGhBCERq7waht4kg
EAUAAAAoTwBEWqOC0NtkEIgCAAAAlCb8IaXRQeh/JoVQFAAAAKAkoQ/pzAxCbxNDIAoAAABQzocm
gJ/OCFwBAAAAOJfbb6SyIqR0SxQAAACgBiEPaay8rSkQBQAAAMhPwEMKER5bF4gCAAAA5OadoQAA
AABAC266EV6kLzNyOxQAAAAgL8EOoUX8VneBKAAAAEBOHpMnrIhBKAAAAAB5CUMBAAAAgBaEoYTk
VigAAAAAowlDCUcQCgAAAMAMwlAAAAAAoAVhKKG4FQoAAADALMJQwhCEAgAAADCTMJQQBKEAAAAA
zCYMBQAAAABaEIaynFuhAAAAAJxh0wSslC0I3bbNnAEAAABIys1QlnEjFAAAAIAzCUPhSW6FAgAA
AOQm3GEJj8cDAAAAcDY3Qzmdx+MBAAAAWOGqCeCxLrdCR4bUbtICAAAAEQksOJXH43v1iVAUAAAA
iERQwWkEoT37QyAKAAAARCGk4BQZ3xNaOcRb0R9CUQAAAGA1X6AEvxCE1vl3AQAAAD4JQ5nO4/EA
AAAARCAMZSq3AfWH8QAAAABEIQxlGu8JBQAAACASYSj849vje/0cAAAAQD/CUKbwnlAAAAAAohGG
MpybfwAAAABEJAylPbdCAQAAAHoQhjKUx+MBAAAAiEoYyjCCUAAAAAAiE4YyhPeEAgAAABCdMJSW
3AoFAAAA6EcYyts8Hg8AAABABsJQ3uLxeAAAAACyEIZyWMYg1K1QAAAAgL6EobQhCAUAAADoTRjK
Id4TCgAAAEA2wlBe5j2hAAAAAGQkDKU8t0IBAAAAuFyEobzI4/EAAAAAZCUM5WmCUAAAAAAyE4by
FO8JBQAAACA7YSgluRUKAAAAwHfCUP7k8XgAAAAAKhCG8pDH4wEAAACoQhjKXRmDULdCAQAAALhH
GEoZglAAAAAAHhGG8ivvCQUAAACgGmEoP3hPKAAAAAAVCUNJz61QAAAAAJ4hDOU/PB4PAAAAQFXC
UG48Hg8AAABAZcJQLpdLziDUrVAAAAAAXiEMJSVBKAAAAACvEobiPaEAAAAAtCAMbc57QgEAAADo
QhjamPeEAgAAANCJMJQ0BKEAAAAAvEMY2pT3hAIAAADQjTAUAAAAAGhBGNqQW6EAAAAAdCQMJTRB
KAAAAACjCEMBAAAAgBaEoYTlVigAAAAAIwlDCUkQCgAAAMBowlDCEYQCAAAAMIMwFAAAAABoQRhK
KG6FAgAAADCLMJQwBKEAAAAAzCQMJQRBKAAAAACzCUMbEjwCAAAA0JEwlOWEswAAAACcQRjaVJQA
UhAKAAAAwFmEoY0JIgEAAADoRBja3MpAVBgLAAAAwJmEoSwJJQWhAAAAAJxNGMrlcjk3nBSEAgAA
ALCCMJSb7R8tAQAAAEBFwlB+mBmIClsBAAAAWEUYyq9mhJaCUAAAAABWEoZy18jH5gWhAAAAAKwm
DOVP7waZglAAAAAAIrhqAp7xGWju+76/+mcAAAAAIAJhKC/5HnB+DUeFnwAAAABEJgzlLQJQAAAA
ALLwzlAAAAAAoAVhKAAAAADQgjAUAAAAAGhBGAoAAAAAtCAMBQAAAABaEIYCAAAAAC0IQwEAAACA
FoShAAAAAEALwlAAAAAAoAVhKAAAAADQgjAUAAAAAGhBGAoAAAAAtCAMBQAAAABaEIYCAAAAAC0I
QwEAAACAFoShAAAAAEALwlAAAAAAoAVhKAAAAADQgjAUAAAAAGhBGAoAAAAAtCAMBQAAAABaEIYC
AAAAAC0IQwEAAACAFoShAAAAAEALwlAAAAAAoAVhKAAAAADQgjAUAAAAAGhBGAoAAAAAtCAMBQAA
AABa2DQB9LHv+7686GybugMAAAAs4WYoAAAAANCCG1rQzMrboW6FAgAAACsJJqChFYGoIBQAAABY
TTgBTZ0ZiApCAQAAgAgEFNDYGYGoIBQAAACIQkgBzc0MRAWhAAAAQCSCCuBmRDAqAAUAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAwvg/TcgSTG3oqPIAAAAASUVORK5CYII=
"
     id="image10"
     x="0"
     y="0" />
</svg>
`;

export const pranaTheme = {
   logo: logo,
   colors: tenantColors,
   default: defaultColors,
   chart: {
      colors: chartColors
   }
};

import { HttpParams } from "@angular/common/http";
import { format } from "date-fns";
import { ConsFilter, Filter } from "./filter-general.model";

const FilterMap: { [key: string]: (f: Filter) => { [key: string]: string } | undefined } = {
    'reference': mapReference,
    'powerplant': mapPowerplant,
    'distribuitor': mapDistributor,
    'meter': mapMeter,
    'company': mapCompany,
    'project': mapProject
}

export function toHttpParams(filter: Filter) {
    return new HttpParams({ fromObject: toJson(filter) })
}

export function toJson(filter: Filter): { [key: string]: string } {
    const mappers = Object.keys(filter ?? {}).map(key => FilterMap[key]).filter(m => !!m)
    return mappers.reduce((acc, map) => ({ ...acc, ...map(filter) }), {})
}

export function toPlainObject(filter: Filter): Partial<ConsFilter> {
    const mappers = Object.keys(filter).filter(m => m != 'lastReference')
    return mappers.reduce((acc, key) => {
        const value = (filter[key] instanceof Set) ? Array.from(filter[key]) : [...filter[key] as any[]]
        return {
            ...acc,
            [key]: value
        }
    }, {})
}

export function mapReference(filter: Filter) {
    if (!filter.reference || !(filter.reference?.[0] && filter.reference?.[1])) {
        return undefined
    }
    const [start, end] = filter.reference.sort((a, b) => a.valueOf() - b.valueOf())

    return {
        'dt_referencia_start': format(start, 'yyyy-MM-01'),
        'dt_referencia_end': format(end, 'yyyy-MM-01')
    }
}

export function mapMeter(filter: Filter) {
    if (filter.meter.size > 0) {
        return {
            'instalacao': Array.from(filter.meter).reduce((acc, value, index, arr) => {
                if (arr.length - 1 == index) {
                    return `${acc}${String(value)}`
                } else {
                    return `${acc}${String(value)},`
                }
            }, '')
        }
    } else {
        return undefined
    }
}

export function mapCompany(filter: Filter) {
    if (filter.company.size > 0) {
        return {
            'empresa': String(Array.from(filter.company).shift())
        }
    } else {
        return undefined
    }
}

export function mapDistributor(filter: Filter) {
    if (filter.distribuitor.size > 0) {
        return {
            'distribuidora': Array.from(filter.distribuitor).reduce((acc, value, index, arr) => {
                if (arr.length - 1 == index) {
                    return `${acc}${String(value)}`
                } else {
                    return `${acc}${String(value)},`
                }
            }, '')
        }
    } else {
        return undefined
    }
}

export function mapProject(filter: Filter) {
    if (filter.project.size > 1) {
        return {
            'projeto_gd': Array.from(filter.project).reduce((acc, value, index, arr) => {
                if (arr.length - 1 == index) {
                    return `${acc}${String(value)}`
                } else {
                    return `${acc}${String(value)},`
                }
            }, '')
        }
    } else if (filter.project.size == 1) {
        return {
            'projeto_gd': `${Array.from(filter.project).shift()}`
        }
    } else {
        return undefined
    }
}

export function mapPowerplant(filter: Filter) {
    //Has the same meaning of Project
    if (filter.powerplant.size > 0) {
        return {
            'usina': Array.from(filter.powerplant).reduce((acc, value, index, arr) => {
                if (arr.length - 1 == index) {
                    return `${acc}${String(value)}`
                } else {
                    return `${acc}${String(value)},`
                }
            }, '')
        }
    } else {
        return undefined
    }
}
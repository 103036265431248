/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { AccessKeysService } from "dg/app/shared/api/access-keys.service";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

interface H { service: AccessKeysService }

function validate(this: H, control: AbstractControl): Observable<ValidationErrors> | null {
  const distribuitorId: number = control.value;
  const accessId = control.parent?.get('credential').value

  if (!!accessId) {
    return this.service.get(accessId)
      .pipe(
        map(res => {
          if (res.distribuidora !== distribuitorId) {
            return {
              acesso: 'Necessário desassociar acesso para escolher outra distribuidora'
            }
          } else {
            return null
          }
        })
      )
  }
  return of(null)
}

export function distribuitorAcesss(service: AccessKeysService): AsyncValidatorFn {
  return validate.bind({ service: service })
}
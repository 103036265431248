import { FormControl, Validators } from "@angular/forms";
import { GaValidators } from "ui-components/forms/validators";
import Usuario from "ui-components/models/usuario.model";

export const formGroupDef = {
    id: new FormControl(),
    email: new FormControl(undefined, [Validators.required, Validators.email]),
    password: new FormControl(undefined, [Validators.required, GaValidators.password]),
    newPassword: new FormControl(undefined, [GaValidators.password]),
    name: new FormControl(undefined, [Validators.required]),
    group: new FormControl(undefined, []),
    companiesRole: new FormControl([], [Validators.required])
}

export interface UserDetailsForm {
    id?: number;
    name: string;
    email: string;
    password: string;
    newPassword?: string;
    group?: string;
    companiesRole: { perfil: number, empresa: number }[];
}

export namespace UserDetailsAdapter {
    export function beforeAssign(value: Usuario): UserDetailsForm {
        return {
            id: value.id,
            name: value.first_name + ' ' + value.last_name,
            email: value.username,
            password: value.password,
            group: value.group,
            companiesRole: value.empresas
        }
    }

    export function beforeSubmit(value: UserDetailsForm): Partial<Usuario> {
        const [first, ...last] = value.name.split(' ')
        return {
            id: value.id ? value.id : undefined,
            first_name: first,
            last_name: last.join(' '),
            email: value.email,
            username: value.email,
            password: value.password ? value.password : undefined,
            new_password: value.newPassword ? value.newPassword : undefined,
            group: value.group ? value.group : undefined,
            empresas: value.companiesRole
        }
    }
}

export function formatBackendError(error: { [key: string]: string[] }) {
    return Object.entries(error).map(([key, msg]) => {
        if (Array.isArray(msg)) {
            return msg.join('\n')
        } else {
            return msg;
        }
    })
}
import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable({ providedIn: 'root' })
export class CanLoadGuard implements CanLoad {
    constructor(private auth: AuthService, private router: Router) { }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const { groups } = route.data
        const canLoad = this.auth.checkPermissions(groups)
        return true;
        /* if (canLoad) {
            return true
        } else {
            return this.router.createUrlTree(['auth','denied'])
        } */
    }
}
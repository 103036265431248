import { DadosUnidades } from "ui-components/models/api/webapp-gd/v2/dados-unidades";

export interface InvoiceView {
    installationCode: string;

    consumption: any;
    creditConsumed: any;
    contracted: number;

    remaining: any;
    injected: any;
    generated: any;
    disponilibityCost: any;
    generationBalance: any;
    totalCostDg: number;
    totalCostWithoutDg: number;
    totalContracts: number;
    //not aggreagatable
    optimalApport: any;
    expectedApport: any;
    realApport: any;
    economyAmount: number;
    amount: number;
    referenceDate: string;
    previousMeasureDate: string;
    actualMeasureDate: string;
    economyPerc: number;
    powerplants: any;
}

export function mapInvoiceView(invoice: DadosUnidades): InvoiceView {
    const referenceDate = invoice.dt_referencia
    const installationCode: string = invoice.instalacao
    const consumption: number = invoice.energia_consumida;
    const creditConsumed: number = invoice.energia_injetada;
    const remaining: number = invoice.saldo;
    const contracted: number = invoice.energia_fixa_contratada;
    const injected: number = invoice.energia_compensada;
    const generated: number = invoice.energia_gerada
    const disponilibityCost: number = invoice.disponibilidade_kwh;
    const generationBalance: number = invoice.saldo;
    const expectedApport: number = invoice.rateio_atual
    const realApport: number = invoice.rateio_aplicado
    const optimalApport: number = invoice.rateio_otimo;
    const previousMeasureDate: string = invoice.data_leitura_anterior;
    const actualMeasureDate: string = invoice.data_leitura_atual;
    const amount: number = invoice.valor_total
    const totalCostDg: number = invoice.custo_total_gd
    const totalCostWithoutDg: number = invoice.custo_total_sem_gd
    const totalContracts: number = invoice.soma_contratos
    const economyPerc: number = invoice.percentual_economia
    const economyAmount: number = totalCostWithoutDg - totalCostDg
    const powerplants: any = invoice.usinas

    return {
        installationCode,
        referenceDate,
        consumption,
        creditConsumed,
        remaining,
        injected,
        contracted,
        generated,
        disponilibityCost,
        generationBalance,
        optimalApport,
        expectedApport,
        realApport,
        previousMeasureDate,
        actualMeasureDate,
        economyPerc,
        economyAmount,
        amount,
        totalContracts,
        totalCostDg,
        totalCostWithoutDg,
        powerplants
    }
}


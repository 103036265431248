import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConsumerFormAdapter } from "dg/app/registry/details/consumer-details/util";
import { UserDetailsAdapter } from "dg/app/registry/details/user-details/util";
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { toHttpParams, toJson } from "ui-components/filter";
import { Filter } from "ui-components/filter/filter-general.model";
import { DadosUnidades } from "ui-components/models/api/webapp-gd/v2/dados-unidades";
import Classe from "ui-components/models/classe.model";
import { Conta } from "ui-components/models/conta.model";
import { DisponibilidadeFatura } from "ui-components/models/disponibilidade-fatura.model";
import { Distribuidora } from "ui-components/models/distribuidora.model";
import { EcoInfo } from "ui-components/models/eco-info.model";
import Empresa from "ui-components/models/empresa.model";
import { Estado } from "ui-components/models/estado.model";
import { FatorCarbono } from "ui-components/models/fator-carbono.model";
import { IdentifiableEntity } from "ui-components/models/identifiable-entity.model";
import { Powerplant } from "ui-components/models/powerplant.model";
import { Projeto } from "ui-components/models/project.model";
import { WhiteLabelTheme } from "ui-components/models/theme.model";
import { UnidadeConsumidora } from "ui-components/models/unidade-consumidora";
import Usuario from "ui-components/models/usuario.model";
import { InvoiceView, mapInvoiceView } from "./adapter";
import { Api, GeracaoDistribuidaApi, GeracaoDistribuidaV2Api } from "./api";

type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>
@Injectable({
  providedIn: "root",
})
export class ApiService {
  clients: Empresa[];
  units: Conta[];
  powerhouses: Conta[];

  constructor(
    private http: HttpClient
  ) { }

  getEstados() {
    return this.http.get<Estado[]>(Api.estados).pipe(
      map((value) => {
        return value.map((v) => {
          return { label: v.uf, value: v.id };
        });
      })
    );
  }

  getCountries() {
    return this.http.get<Estado[]>(Api.pais).pipe(
      map((value) => {
        return value.map((v) => {
          return { label: v.nome, value: v.id };
        });
      })
    );
  }

  getClasses() {
    return this.http.get<Classe[]>(Api.medidor).pipe(
      map((response) => {
        return response.map((c) => {
          return { label: c.nome, value: c.id };
        });
      })
    );
  }

  getClients() {
    return this.http.get<Empresa[]>(Api.empresas)
  }

  getClient(id: number) {
    return this.http.get<Empresa[]>(`${Api.empresas}${id}/`);
  }

  getGroups() {
    return this.http.get<any[]>(`${Api.groups}`);
  }

  getProjects(clientId) {
    return this.http
      .get<Projeto[]>(Api.empresas + clientId + "/projetos/")
      .pipe(
        map((proj) => {
          return proj.map((p) => {
            return { ...p };
          });
        })
      );
  }

  getProject(projectId: number) {
    return this.http.get<Projeto>(`${GeracaoDistribuidaV2Api.projetos}${projectId}/`)
  }

  getAllProjects() {
    return this.http.get<any[]>(GeracaoDistribuidaV2Api.projetos);
  }

  getPowerplantsByProject(filter: Filter) {
    const params = toHttpParams(filter)
    return this.http.get<Powerplant[]>(GeracaoDistribuidaV2Api.usinas, { params })
  }

  getPowerplant(id: number) {
    return this.http.get<Powerplant>(`${GeracaoDistribuidaV2Api.usinas}${id}/`)
  }

  getPowerplantByCode(id: string) {
    return this.http.get<Powerplant>(`${GeracaoDistribuidaV2Api.usinas}`, {
      params: {
        'instalacao_in': id
      }
    })
  }

  getAvaliablePowerplants({ projectId }: { projectId: number }) {
    return this.http.get<Powerplant[]>(GeracaoDistribuidaV2Api.usinasDisponiveis(projectId))
  }

  getPowerplants(filter?: Filter) {
    const params = toHttpParams(filter)
    return this.http.get<Powerplant[]>(GeracaoDistribuidaV2Api.usinas, { params });
  }

  getCustomers(filter?: Filter) {
    const params = toHttpParams(filter)
    return this.http
      .get<UnidadeConsumidora[]>(GeracaoDistribuidaV2Api.unidadesConsumidoras, { params })
  }

  getContas() {
    return this.http.get<Conta[]>(GeracaoDistribuidaV2Api.contas).pipe(
      map((units) => {
        return units.map((u) => {
          return { ...u };
        });
      })
    );
  }

  getConta(id) {
    return this.http.get<Conta>(GeracaoDistribuidaV2Api.contas + id + "/").pipe(
      map((unit) => {
        return unit;
      })
    );
  }

  getUsers() {
    return this.http.get<Usuario[]>(Api.usuarios)
  }

  getUser(id) {
    return this.http.get<Usuario>(Api.usuarios + id + "/")
  }

  deleteUser(id: number) {
    return this.http.delete(Api.usuarios + id + "/")
  }

  activateUser(id: number) {
    return this.http.patch(Api.usuarios + id + "/", { is_active: true })
  }

  deactivateUser(id: number) {
    return this.http.patch(Api.usuarios + id + "/", { is_active: false })
  }

  getDistributors() {
    return this.http.get<Distribuidora[]>(Api.distribuidoraAll).pipe(
      map((dist) => {
        return dist.map((d) => {
          return { ...d };
        });
      })
    );
  }

  getLocalDistributors() {
    return this.http.get<Distribuidora[]>(Api.distribuidoraLocal).pipe(
      map((dist) => {
        return dist.map((d) => {
          return { ...d };
        });
      })
    );
  }

  createClient(body: any) {
    return this.http.post(Api.empresas, body);
  }

  updateClient(body: any, id: number) {
    return this.http.patch(Api.empresas + id + "/", body);
  }

  createPowerplant(body: any) {
    return this.http.post(GeracaoDistribuidaV2Api.usinas, body);
  }

  updatePowerplant(body: Partial<Powerplant>) {
    return this.http.patch<Powerplant>(`${GeracaoDistribuidaV2Api.usinas}${body.id}/`, body);
  }

  createConsumer(body: any) {
    return this.http.post(GeracaoDistribuidaV2Api.contas, body);
  }


  updateConsumer(body: AtLeast<Conta, 'id'>) {
    return this.http.patch<Conta>(`${GeracaoDistribuidaV2Api.contas}${body.id}/`, body);
  }

  /**
   * If body has ID it will do a PATCH operation
   * If body hasnt an ID it iwl do a POST operation
   * @param body
   * @returns
   */
  saveConsumer(body: any) {
    const value = ConsumerFormAdapter.beforeSubmit(body)
    if (value.id) {
      return this.updateConsumer(value).pipe(
        map(res => ['UPDATE', res]),
        catchError(err => throwError(['UPDATE', err]))
      )
    } else {
      return this.createConsumer(value).pipe(
        map(res => ['CREATE', res]),
        catchError((err) => throwError(['CREATE', err]))
      )
    }
  }

  deleteCustomer(id: number) {
    return this.http.delete(GeracaoDistribuidaV2Api.contas + id + "/");
  }

  toogleActiveCustomer(uc: any) {
    return this.http.patch(GeracaoDistribuidaV2Api.contas + uc.id + "/", { is_active: !uc.isActive });
  }

  createUser(body: any) {
    return this.http.post<Usuario>(Api.usuarios, body);
  }

  updateUser(body: any, id: number) {
    return this.http.patch<Usuario>(Api.usuarios + id + "/", body);
  }
  /**
   * If body has ID it will do a PATCH operation
   * If body hasnt an ID it iwl do a POST operation
   * @param body
   * @returns
   */
  saveUser(body: any) {
    const value = UserDetailsAdapter.beforeSubmit(body)
    if (value.id) {
      return this.updateUser(value, value.id).pipe(
        map(res => ['UPDATE', res]),
        catchError(err => throwError(['UPDATE', err]))
      )
    } else {
      return this.createUser(value).pipe(
        map(res => ['CREATE', res]),
        catchError((err) => throwError(['CREATE', err]))
      )
    }
  }

  createProject(body: IdentifiableEntity) {
    return this.http.post<Projeto>(GeracaoDistribuidaV2Api.projetos, body);
  }

  updateProject(body: IdentifiableEntity) {
    return this.http.patch<Projeto>(GeracaoDistribuidaV2Api.projetos + body.id + "/", body);
  }

  createContract(body: IdentifiableEntity) {
    return this.http.post(GeracaoDistribuidaV2Api.contratos, body);
  }

  updateContract(body: IdentifiableEntity) {
    return this.http.patch(GeracaoDistribuidaV2Api.contratos + body.id + "/", body);
  }

  deleteContract(body: IdentifiableEntity) {
    return this.http.delete(GeracaoDistribuidaV2Api.contratos + body.id + "/")
  }

  deletePowerplant(id: number) {
    return this.http.delete(`${GeracaoDistribuidaV2Api.usinas}${id}/`)
  }

  getPowerplantInvoice(filter) {
    const params = toHttpParams(filter);
    return this.http.get<any>(GeracaoDistribuidaV2Api.dadosUnidades, { params })
  }

  getUserProfiles() {
    return this.http.get<any>(Api.userProfiles);
  }

  getGenerationCredits(filter: Filter) {
    const params = toHttpParams(filter)
    return this.http
      .get<any>(GeracaoDistribuidaApi.creditos, { params })
      .pipe(
        map((res) => {
          return { ...res };
        })
      );
  }

  getCustomerDataCredits(filter: Filter) {
    const params = toHttpParams(filter)
    return this.http.get<any>(GeracaoDistribuidaV2Api.unidadesResumos, { params })
  }

  getinvoicesDisponibility(filter) {
    const params = toHttpParams(filter)
    return this.http.get<DisponibilidadeFatura[]>(GeracaoDistribuidaV2Api.disponibilidadeFatura, { params: params })
  }

  getCustomersDataInvoice(filter: Filter): Observable<InvoiceView[]> {
    const params = toJson(filter);
    return this.http
      .get<DadosUnidades[]>(GeracaoDistribuidaV2Api.dadosUnidades, { params: params })
      .pipe(
        map(c => c.map(t => mapInvoiceView(t)))
      );
  }

  getPowerplantDataInvoice(filter) {
    const params = toHttpParams(filter)
    return this.http
      .get<any>(GeracaoDistribuidaV2Api.usinaResumo, { params: params })
      .pipe(
        map((res) => {
          return { ...res };
        })
      );
  }

  getPowerplantDataBrief(filter) {
    const params = toHttpParams(filter)
    return this.http.get<any>(GeracaoDistribuidaV2Api.usinaResumo, { params: params }).pipe(
      catchError(err => {
        return of({
          fator_capacidade: null,
          total_faturas_baixadas: null,
          total_unidades_consumidoras: null
        })
      })
    )
  }

  getContactInfo() {
    return this.http.get<{ contact: string }>(Api.contatos).pipe(
      map(res => res?.contact ?? ''),
      map(res => res.replace('<h2>Informações de Contato</h2>', ''))
    )
  }

  saveContactInfo(value: any) {
    return this.http.patch<any>(Api.contatos, { contact: value })
  }

  getCarbonFactorByYear(year: number) {
    return this.http.get<FatorCarbono>(`${Api.fatorCarbono}${year - 1}/`).pipe(
      map(res => res.fator)
    )
  }

  getEcoInfo(filter: Filter) {
    const params = toHttpParams(filter)
    return this.http.get<EcoInfo>(GeracaoDistribuidaV2Api.co2Poupado, { params: params }).pipe(
      map((res) => {
        return { ...res };
      })
    );
  }

  getTheme() {
    return this.http.get<WhiteLabelTheme[]>(Api.temas);
  }

  updateTheme(id, tenant, theme) {
    return this.http.put(`${Api.temas}${id}/`, { 'esquema': theme, 'tenant': tenant });
  }

  createTheme(tenant, theme) {
    return this.http.post(Api.temas, { 'esquema': theme, 'tenant': tenant });

  }

  getFeatures() {
    return this.http.get<any>(Api.funcionalides);

  }
}

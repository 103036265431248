import { Injectable } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { Group } from "../auth/models/groups";

export interface NavigateLinks {
  url: string;
  label: string;
  groups: Group[];
  icon: string;
}

@Injectable()
export class NavigateService {

  readonly links: NavigateLinks[]
  session: any = { mc: true, gd: false }

  constructor(private auth: AuthService) {
    this.links = this.initLinks();
    this.session = this.auth.getModules();
  }

  private initLinks() {
    const rawLinks = [
      {
        url: '/dashboard',
        label: 'Geração Distribuída',
        groups: [],
        icon: 'business_centers'
      },
      {
        url: '/contracts-management',
        label: 'Projetos',
        groups: [Group["admin"], Group['gestor']],
        icon: 'work'
      },
      {
        url: '/registry',
        label: 'Cadastros',
        groups: [Group["admin"], Group['gestor']],
        icon: 'add_circle'
      },
      {
        url: '/contact',
        label: 'Fale conosco',
        groups: [],
        icon: ''
      }
    ]
    return rawLinks.filter(link => this.auth.checkPermissions(link.groups))
  }
}
/**
 * @license
 * Copyright GreenAnt. All Rights Reserved.
 *
 */

/**
 * @module
 * @description
 * Entry point for filter module of GreenAnt UI
 */

export * from './filter-general.model';
export * from './filter.map';


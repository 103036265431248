import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthWrapperComponent } from "./auth-wrapper.component";

export const AUTH_ROUTES: Routes = [{
    path: 'auth',
    component: AuthWrapperComponent,
    loadChildren: () => import('./pages/auth-pages.module').then(m => m.AuthPagesModule),
}];

@NgModule({
    imports: [RouterModule.forChild(AUTH_ROUTES)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }
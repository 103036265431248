export interface PrimitiveFilter {
    reference: any;
    powerplant: any;
    project: any;
    distribuitor: any;
    meter: any;
    company: any;
}
export interface ConsFilter extends PrimitiveFilter {
    reference: [Date, Date];
    powerplant: string[];
    project: any[];
    distribuitor: any[];
    meter: string[];
    company: any[];
}
export class Filter implements PrimitiveFilter {
    lastReference: Date = null
    reference: [start: Date, end: Date] = [] as unknown as [Date, Date]
    powerplant = new Set<string>();
    distribuitor = new Set<number>();
    meter = new Set<string>();
    company = new Set<number>();
    project = new Set<number>();

    constructor(value?: Partial<ConsFilter>) {
        if (!!value) this.init(value)
    }

    init(obj: Partial<ConsFilter>) {
        Object.keys(obj).filter(key => !!obj[key]).forEach(key => {
            if (key == 'reference') {
                this[key] = [...obj[key]]
            } else {
                if (!Array.isArray(obj[key])) throw `Key ${key} is not an array`;
                this[key] = new Set(obj[key])
            }
        })
    }
}

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { endOfMonth, startOfMonth } from "date-fns";
import { BehaviorSubject, forkJoin, from, Observable } from "rxjs";
import { concatAll, map } from "rxjs/operators";
import { Powerplant } from "ui-components/models/powerplant.model";
import { Projeto } from "ui-components/models/project.model";
import { GeracaoDistribuidaV2Api } from "../api/api";
import { GreenAntDashboardService } from "../api/greenant-dashboard.service";

@Injectable()
export class PowerplantService {
    powerplantsList = new BehaviorSubject<Powerplant[]>([]);
    projectsList = new BehaviorSubject<any[]>([]);

    constructor(
        private greenant: GreenAntDashboardService,
        private http: HttpClient

    ) { }

    getPowerplants() {
        return this.powerplantsList.value;
    }

    setPowerplants(unidades: Powerplant[]) {
        this.powerplantsList.next(unidades);
    }

    getProjectPowerplant(powId): Projeto | undefined {
        return this.projectsList.value.find(p => (p.usina_id == powId));
    }

    getPowerplant(id: number | string): Powerplant | undefined {
        return this.powerplantsList.value.find(e => e.id == id || e.instalacao == id)
    }

    getProjects() {
        return this.projectsList.value;
    }

    setProjects(projects: any[]) {
        this.projectsList.next(projects);
    }

    loadPowerplantGeneration(powerplants: Powerplant[]) {
        const todayDate = new Date()
        const startMonth = startOfMonth(todayDate)
        const endMonth = endOfMonth(todayDate)
        const obs$ = powerplants.map(pp => {
            const today$ = this.greenant.generation(pp.meter_id, pp.api_token, [todayDate, todayDate], 'day').pipe(
                map(today => Math.abs(today[0]?.injectedEnergy / 1000))
            )
            const month$ = this.greenant.generation(pp.meter_id, pp.api_token, [startMonth, endMonth], 'day').pipe(
                map(month => month.reduce((acc, m) => { return acc + Math.abs(m?.injectedEnergy / 1000) }, 0))
            )
            return forkJoin([today$, month$]).pipe(
                map(([today, month]) => ({ pp: powerplants, generationOnMonth: month, generationOnToday: today }))
            )
        })

        return from(obs$).pipe(concatAll())
    }

    getPowerplantsByProject(projectId: number): Observable<any[]> {
        const obj = { projeto_gd: projectId }
        const params = new HttpParams({ fromObject: JSON.parse(JSON.stringify(obj)) })
        return this.http.get<any[]>(GeracaoDistribuidaV2Api.usinas, { params })
    }

}

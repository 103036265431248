import { AfterViewInit, ContentChildren, Directive, Host, OnDestroy, QueryList } from '@angular/core';
import { GoogleMap, MapMarker } from '@angular/google-maps';

@Directive({ selector: 'google-map[autocenter]' })
export class AutoCenterDirective implements AfterViewInit, OnDestroy {
    @ContentChildren(MapMarker, { emitDistinctChangesOnly: true }) markersQuery: QueryList<MapMarker>

    constructor(@Host() private map: GoogleMap) { }

    ngAfterViewInit() {
        //Autocenter to markers
        const bounds = new google.maps.LatLngBounds();
        this.markersQuery.toArray().reduce((acc, m) => {
            return bounds.extend(m.getPosition())
        }, bounds)
        this.map.fitBounds(bounds)
    }

    ngOnDestroy() {
    }
}
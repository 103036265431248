import { ChartColors, defaultColors, TenantColors } from "../models/tenant-theme.model";


export const logo = `<svg viewBox="0 0 120 120" version="1.1"
xmlns="http://www.w3.org/2000/svg">
<circle cx="60" cy="60" r="50"/>
</svg>`;

const tenantColors: TenantColors = {
   'primary': '#828282',
   'secondary': '#828282',
   'btn-primary': '#828282',
   'btn-secondary': '#828282',
};

const chartColors: ChartColors = {
   'chart-color-1': '#1e194f',
   'chart-color-2': '#322a7e',
   'chart-color-3': '#4f266f',
   'chart-color-4': '#ed6429',
};

export default {
   logo: logo,
   colors: tenantColors,
   default: defaultColors,
   chart: {
      colors: chartColors
   }
};

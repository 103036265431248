import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { Api } from "../shared/api/api";
import { ApiService } from "../shared/api/api.service";
import { NotificationService } from "../shared/notification/notification.service";

@Injectable({ providedIn: null })
export class CurrentUserService {
    user = new BehaviorSubject<any>(null);

    constructor(
        private api: ApiService,
        private auth: AuthService,
        private router: Router,
        private http: HttpClient,
        private notification: NotificationService
    ) { }

    fetchUser() {
        if (!this.getUserValues()) {
            this.api.getUser(this.auth.getUserFromToken().user_id).subscribe(user => {
                this.setUserValues(user);
            })
        }
    }

    setUserValues(user) {
        this.user.next(user);
    }

    getUserValues() {
        return this.user.value;
    }

    acceptTerms() {
        let currentRoute: string;
        let accept_terms: string;

        this.user.subscribe(user => {
            if (!user) return;

            accept_terms = user.aceite_termos;
            if (accept_terms === null) {

                const acceptSnack = this.notification.acceptTerms();

                const routeSubscription = this.router.events.subscribe((event: Event) => {
                    if (event instanceof NavigationEnd) {
                        if (event.url === '/auth/login') {
                            currentRoute = event.url;
                            acceptSnack.dismiss();
                        }
                    }
                });

                acceptSnack.afterDismissed().subscribe((response) => {
                    if (response.dismissedByAction) {
                        this.http.put(Api.usuarios + 'aceite-termos/', {}).subscribe(() =>
                            this.api.getUser(this.auth.getUserFromToken().user_id).subscribe(user => {
                                this.setUserValues(user);
                            })
                        );
                    }
                    routeSubscription.unsubscribe();
                });
            }
        })

    }
}


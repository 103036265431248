import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { UnidadeConsumidora } from "ui-components/models/unidade-consumidora";

@Injectable()
export class CustomerService {
    updatedCustomers = new BehaviorSubject<UnidadeConsumidora[]>([]);

    constructor() { }

    getCustomers() {
        return this.updatedCustomers.value.slice();
    }

    setCustomers(unidades: UnidadeConsumidora[]) {
        this.updatedCustomers.next(unidades);
    }

    getCustomer(id: string | number): UnidadeConsumidora {
        const p = this.updatedCustomers.value.find(e => { return e.instalacao == id || e.id == id }) || null
        return p;
    }
}
import { NgForOf } from '@angular/common';
import { Directive, Host, Input, TrackByFunction } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[ngForTrackByProperty]' })
export class TrackByPropertyDirective {

    private _propertyName: string;
    @Input('ngForTrackByProperty')
    set propertyName(value: string | null) {
        this._propertyName = value ?? ''
    }

    constructor(@Host() private readonly _ngFor: NgForOf<any>) {
        this._ngFor.ngForTrackBy = this.trackByProperty.bind(this)
    }

    trackByProperty: TrackByFunction<any> = (_: number, item: any) => this._propertyName ? item[this._propertyName] : _

}
export interface TenantTheme {
    logo: string;
    favicon: string;
    colors: TenantColors;
    chart?: {
        colors: ChartColors
    }
}

export const defaultColors = {
    'danger': '#D73A3A',
    'warn': '#FFC72C',
    'success': '#33CC99',
    'title': '#333333',
    'subtext': '#666666',
    'placeholder': '#9E9E9E',
    'disabled': '#BDBDBD',
    'border': '#E0E0E0',
    'background': '#F2F2F2'
}
export interface TenantColors {
    'primary': string;
    'secondary': string;
    'btn-primary': string;
    'btn-secondary': string;
}

export type ChartColors = {
    'chart-color-1': string;
    'chart-color-2': string;
    'chart-color-3': string;
    'chart-color-4': string;
}

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TenantModule } from 'dg/app/tenant/tenant.module';
import { LoadingIndicatorService } from '../shared/loading-indicator/loading-indicator.service';
import { NotificationService } from '../shared/notification/notification.service';
import { AuthWrapperComponent } from './auth-wrapper.component';
import { AuthRoutingModule } from './auth.routing';
import { AuthService } from './auth.service';
import { AuthorizationInterceptor } from './middleware/authorization.interceptor';

@NgModule({
  declarations: [
    AuthWrapperComponent,
  ],
  imports: [
    AuthRoutingModule,
    HttpClientModule,
    TenantModule
  ]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        LoadingIndicatorService,
        NotificationService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
        AuthService
      ]
    }
  }
}

import {
  AfterViewInit, ChangeDetectorRef,
  Component, ContentChildren,
  Input, OnChanges,
  OnInit, QueryList,
  Renderer2, SimpleChanges
} from "@angular/core";
import { ProjectionDirective } from "ui-components/shared/projection.directive";

@Component({
  selector: "base-breadcrumb",
  template: `
    <ng-template ngFor let-item [ngForOf]="_templateRefs" let-last="last">
      <ng-template
        [ngTemplateOutlet]="item.template"
        [ngTemplateOutletContext]="{ $implicit: item.ctx, params: state }"
      ></ng-template>
      <i *ngIf="!last" class="material-icons breadcrumb-next"
        >arrow_forward_ios</i
      >
    </ng-template>
  `,
  styleUrls: ["base-breadcrumb.component.scss"],
})
export class BaseBreadcrumbComponent implements OnInit, OnChanges, AfterViewInit {
  @ContentChildren(ProjectionDirective)
  _queryList: QueryList<ProjectionDirective>;

  @Input() state: { [key: string]: any } = {};

  templateInited = false

  _templateRefs: any[] = [];

  constructor(private renderer: Renderer2, private cd: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const { state: stateChanges } = changes
    if (stateChanges && stateChanges.currentValue && this.templateInited) {
      this._templateRefs = this.mapTemplateRef(this._queryList, this.state);
    }
  }

  ngAfterViewInit() {
    this.templateInited = true
    this._templateRefs = this.mapTemplateRef(this._queryList, this.state);
    this.cd.detectChanges()
  }

  mapTemplateRef(steps: QueryList<ProjectionDirective>, state: { [key: string]: any }) {
    //if (!steps || (!state || Object.keys(state).length < 1)) return []

    const r = steps
      .filter((t) => {
        return (!t.name || !!state[t.name]) && (t.value === undefined || t.value);
      })
      .map((t, index, arr) => {
        const key = t.name
        const map = { template: t.template, ctx: key && state[key] };
        return map;
      });
    return r;
  }
}

<div>
  <header class="ga-dialog-title">Mapa</header>
  <small class="ga-dialog-subtitle">{{ subtitle }}</small>
</div>
<ng-template [ngIf]="apiLoaded$ | async" let-value>
  <google-map [options]="options" autocenter>
    <map-marker
      *ngFor="let m of markers"
      [position]="m.pos"
      [options]="m.options"
    ></map-marker>
  </google-map>
</ng-template>
<mat-dialog-actions>
  <button
    mat-flat-button
    mat-dialog-close
    color="accent"
    class="button save-btn"
  >
    Fechar
  </button>
</mat-dialog-actions>

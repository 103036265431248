import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'dg/app/auth/auth.service';
import { environment } from 'dg/environments/environment';
import { CurrentUserService } from '../currentUser.service';
import { NavigateLinks, NavigateService } from '../navigate.service';

@Component({
  selector: 'dg-sidebar',
  templateUrl: './sidebar.component.html',
  host: { 'class': 'dg-sidebar' },
  encapsulation: ViewEncapsulation.None,
  providers: [NavigateService]
})
export class SideBarComponent implements OnInit {
  //session: any = { mc: true, gd: false }
  @Output() closeSideNav = new EventEmitter<void>();

  readonly version = environment.version;
  links: NavigateLinks[] = []

  constructor(
    private router: Router,
    private auth: AuthService,
    private navigateService: NavigateService,
    private user: CurrentUserService) {

  }

  ngOnInit() {
    this.links = this.navigateService.links

    //this.session = this.auth.getModules()
  }

  /* private initLinks() {
    const rawLinks = [
      {
        url: 'dg',
        label: 'Geração Distribuída',
        groups: []
      },
      {
        url: 'contracts-management',
        label: 'Contratos',
        groups: [Groups["admin-portal"]]
      },
      {
        url: 'registry',
        label: 'Cadastros',
        groups: [Groups["admin-portal"]]
      }
    ]
    return rawLinks.filter(link => this.auth.checkPermissions(link.groups))
  } */

  onCloseSideNav() {
    this.closeSideNav.emit();
  }

  logout() {
    this.auth.logout()
    this.user.setUserValues(null)
    this.router.navigate(['auth', 'login'])
  }


}

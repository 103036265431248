import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleMapsModule } from "@angular/google-maps";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AkitaNgRouterStoreModule } from "@datorama/akita-ng-router-store";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { CustomerService } from "dg/app/shared/services/customer.service";
import { environment } from "dg/environments/environment";
import * as moment from 'moment';
import { NgxHotjarModule, NgxHotjarRouterModule } from "ngx-hotjar";
import { NgxMaskModule } from "ngx-mask";
import { BehaviorSubject } from "rxjs";
import { GlobalErrorModule } from "ui-components/global-error/global-error.module";
import { AppShellModule } from "./app-shell/app-shell.module";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { AuthModule } from "./auth/auth.module";
import { MapsModule } from "./dashboard/map/maps.module";
import { ChipsSelectorModule } from "./shared/chips-selector/chips-selector.module";
import { MatDateFnsDateModule } from "./shared/custom-adapter/date-fns-adapter.module";
import { IconRegistryService } from './shared/icons/icon-registry.service';
import { httpInterceptorProviders } from "./shared/interceptors";
import { RequestCache } from "./shared/interceptors/request-cache.service";
import { LoadingIndicatorService } from "./shared/loading-indicator/loading-indicator.service";
import { MatPaginatorIntlPtBr } from "./shared/paginator-br";
import { ClientService } from "./shared/services/client.service";
import { PowerplantService } from "./shared/services/powerplant.service";
import { ProjectService } from "./shared/services/project.service";
import { TawkService } from "./shared/services/tawk.service";
import { SharedModule } from "./shared/shared.module";
import { initProviders } from './tenant/initProviders';
import { TENANT_THEME } from "./tenant/initUtil";
import { tenantTable } from "./tenant/tenantTable";


// export let options: Partial<IConfig> | (() => Partial<IConfig>);

moment.locale("pt-Br");
registerLocaleData(localePt, "pt");

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    GlobalErrorModule.forRoot(),
    MatDateFnsDateModule,
    !environment.production ? [] : [
      NgxHotjarModule.forRoot(...environment.keys.HOTJAR_KEYS),
      NgxHotjarRouterModule
    ],
    AppRoutingModule,
    AuthModule.forRoot(),
    SharedModule,
    AppShellModule,
    NgxMaskModule.forRoot({ validation: false }),
    MatSnackBarModule,
    FlexLayoutModule,
    AkitaNgRouterStoreModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    GoogleMapsModule,
    MapsModule,
    ChipsSelectorModule
  ],
  providers: [
    ClientService,
    PowerplantService,
    ProjectService,
    CustomerService,
    LoadingIndicatorService,
    TawkService,
    httpInterceptorProviders,
    initProviders,
    IconRegistryService,
    RequestCache,
    { provide: TENANT_THEME, useValue: new BehaviorSubject(tenantTable['demo']) },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlPtBr }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

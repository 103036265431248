import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { REPLACE_TOKEN } from 'dg/environments/constants';
import { Observable } from 'rxjs';


@Injectable()
export class TenantUrlInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const client: string = this.getClientLocation();
        const newUrl: string = req.url.replace(REPLACE_TOKEN, client);
        const newRequest = req.clone({
            url: newUrl
        })
        return next.handle(newRequest);
    }

    getClientLocation(): string {
        //const client: string = 'demo';
        const client: string = window.location.hostname.split('.').shift();
        return client;
    }
}
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Injectable, SecurityContext } from '@angular/core';
import GreenAntIcons from './icon-urls';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({ providedIn: 'root' })
export class IconRegistryService {

    constructor(private registry: MatIconRegistry, private sanitizer: DomSanitizer) { }

    register() {
        this.sanitize(GreenAntIcons).forEach(([name, url]) => {
            this.registry.addSvgIconInNamespace('greenant', name, url)
        })
    }

    private sanitize(icons: [string, string][]): [string, SafeResourceUrl][] {
        return icons.map(([name, url]) => {
            return [name, this.sanitizer.bypassSecurityTrustResourceUrl(url)]
        })
    }

}